import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

import { useQuery, useMutation, gql } from '@apollo/client';
import {
  Box,
  Container,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
  Button,
  useToast,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
} from '@chakra-ui/react';

import { NavbarWithCallToAction } from '../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';

import { FooterWithFourColumns } from '../../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { LoginModal } from '../../components/LoginModal';






const GET_SESSION_TYPES = gql`
  query GetSessionTypes {
    sessionTypes {
      id
      name
      duration
      format
      price
    }
  }
`;

const CREATE_SESSION = gql`
  mutation CreateSession($input: SessionInput!) {
    createSession(input: $input) {
      id
      name
      startTime
      endTime
      maxParticipants
      meetingLink
      sendReminders
    }
  }
`;

// Add these interfaces for better type safety
interface SessionType {
  id: string;
  name: string;
  duration: number;
  format: 'INDIVIDUAL' | 'GROUP';
  maxParticipants: number;
  price: number;
}

interface SessionFormData {
  name: string;
  description: string;
  startTime: string;
  typeId: string;
  maxParticipants: number;
  meetingLink: string;
  sendReminders: boolean;
}

// First, let's define the interface to match the input type
interface SessionInput {
  name: string;
  description?: string;
  startTime: Date;
  endTime: Date;
  typeId: string;
  maxParticipants?: number;
  meetingLink?: string;
  availabilityId?: string;
  sendReminders?: boolean;
}

// Add auth check
export const ScheduleSession = () => {
  const { isAuthenticated, user, loading } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  // Add formData state
  const [formData, setFormData] = useState<SessionFormData>({
    name: '',
    description: '',
    startTime: '',
    typeId: '',
    maxParticipants: 1,
    meetingLink: '',
    sendReminders: true,
  });

  // Add effect to handle auth state changes
  useEffect(() => {
    if (!loading && !isAuthenticated) {
      setIsLoginModalOpen(true);
    } else {
      setIsLoginModalOpen(false);
    }
  }, [isAuthenticated, loading]);

  // Add loading and error states
  const { data: sessionTypesData, loading: loadingTypes, error: typesError } = useQuery(GET_SESSION_TYPES);
  const [createSession, { loading: creating }] = useMutation(CREATE_SESSION, {
    onCompleted: () => {
      toast({
        title: 'Session created successfully',
        status: 'success',
        duration: 3000,
      });
      navigate('/sessions/client-booking-calendar'); // Redirect to calendar view
    },
  });

  // Show loading state while checking auth
  if (loading) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Spinner />
        </Container>
        <FooterWithFourColumns />
      </>
    );
  }

  // Check for both authentication and practitioner permission
  if (!isAuthenticated || !user?.permissions?.includes('PRACTITIONER')) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Text>This page is only accessible to practitioners.</Text>
        </Container>
        <LoginModal 
          isOpen={isLoginModalOpen} 
          onClose={() => setIsLoginModalOpen(false)}
          onLoginSuccess={() => {
            setIsLoginModalOpen(false);
            if (user?.permissions?.includes('PRACTITIONER')) {
              window.location.reload();
            } else {
              navigate('/');
              toast({
                title: "Access Denied",
                description: "This area is only for practitioners",
                status: "error",
                duration: 5000,
              });
            }
          }}
        />
        <FooterWithFourColumns />
      </>
    );
  }

  // Add error state
  if (typesError) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Text color="red.500">Error loading session types: {typesError.message}</Text>
        </Container>
        <FooterWithFourColumns />
      </>
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const selectedType = sessionTypesData?.sessionTypes.find(
        (type: SessionType) => type.id === formData.typeId
      );

      if (!selectedType) {
        throw new Error('Please select a session type');
      }

      // Calculate start and end times
      const startTime = new Date(formData.startTime);
      const endTime = new Date(startTime.getTime() + selectedType.duration * 60000);

      // Construct input object matching SessionInput type
      const input: SessionInput = {
        name: formData.name,
        typeId: selectedType.id,
        startTime,
        endTime,
      };

      // Add optional fields only if they have values
      if (formData.description) {
        input.description = formData.description;
      }

      if (formData.maxParticipants) {
        input.maxParticipants = parseInt(formData.maxParticipants.toString());
      }

      if (formData.meetingLink) {
        input.meetingLink = formData.meetingLink;
      }

      if (formData.sendReminders !== undefined) {
        input.sendReminders = formData.sendReminders;
      }

      console.log('Submitting session with data:', { input });

      await createSession({
        variables: { input }
      });

      toast({
        title: 'Session created successfully',
        status: 'success',
        duration: 3000,
      });

      navigate('/sessions/client-booking-calendar');
    } catch (error) {
      console.error('Error creating session:', error);
      toast({
        title: 'Error creating session',
        description: error instanceof Error ? error.message : 'An error occurred',
        status: 'error',
        duration: 5000,
      });
    }
  };

  // Rest of your component remains the same...
  return (
     <>
      <NavbarWithCallToAction />
      <Container maxW="container.md" py={8}>
        <VStack spacing={8} align="stretch">
          <Heading size="lg">Schedule a New Session</Heading>
          <Text color="gray.600">
            <Link to="/sessions/create" color="blue.500">
              Create a specific session occurrence
            </Link> based on your predefined session types.
          </Text>

          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Session Type</FormLabel>
                <Select
                  value={formData.typeId}
                  onChange={(e) => {
                    const selectedType = sessionTypesData?.sessionTypes.find(
                      (type: any) => type.id === e.target.value
                    );
                    setFormData({
                      ...formData,
                      typeId: e.target.value,
                      maxParticipants: selectedType?.format === 'GROUP' ? 
                        (selectedType?.maxParticipants || 10) : 1
                    });
                  }}
                >
                  <option value="">Select a session type</option>
                  {sessionTypesData?.sessionTypes.map((type: any) => (
                    <option key={type.id} value={type.id}>
                      {type.name} - {type.duration}min (${type.price})
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Session Title</FormLabel>
                <Input
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  placeholder="e.g., Morning Meditation Session"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Input
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  placeholder="Describe what participants can expect"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Start Time</FormLabel>
                <Input
                  type="datetime-local"
                  value={formData.startTime}
                  onChange={(e) => setFormData({ ...formData, startTime: e.target.value })}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Maximum Participants</FormLabel>
                <NumberInput
                  value={formData.maxParticipants}
                  onChange={(value) => setFormData({ ...formData, maxParticipants: parseInt(value) })}
                  min={1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Meeting Link (for virtual sessions)</FormLabel>
                <Input
                  value={formData.meetingLink}
                  onChange={(e) => setFormData({ ...formData, meetingLink: e.target.value })}
                  placeholder="https://zoom.us/..."
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">Send Reminders</FormLabel>
                <Switch
                  isChecked={formData.sendReminders}
                  onChange={(e) => setFormData({ ...formData, sendReminders: e.target.checked })}
                />
              </FormControl>

              <Button
                type="submit"
                colorScheme="blue"
                isLoading={creating}
                loadingText="Scheduling..."
                w="full"
              >
                Schedule Session
              </Button>
            </VStack>
          </form>
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </>
  );
}; 