import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Container,
  Grid,
  Image,
  Text,
  Button,
  Heading,
  Stack,
  HStack,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  Badge,
  Card,
  CardBody,
  IconButton,
  Skeleton,
  useToast,
  SimpleGrid,
  Tag,
  TagLabel,
  Flex,
  Spacer,
  Center,
  Spinner,
  VStack,
  Divider,
  Icon,
} from '@chakra-ui/react';
import { SearchIcon, EditIcon, ViewIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { JWTDebugger } from '../components/JWTDebugger';

import { motion } from 'framer-motion';


const GET_ALL_PUBLIC_PRODUCTS = gql`
  query GetAllPublicProducts {
    allPublicProducts {
      id
      name
      description
      price
      memberPrice
      images
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id)
  }
`;

interface Product {
  id: string;
  name: string;
  description: string;
  price: number;
  images?: string[];
  categories?: string[];
  memberPrice?: number;
}

interface PriceRange {
  min: number;
  max: number;
}

interface ProductFilterInput {
  categories?: string[];
  priceRange?: PriceRange;
}

const ProductCard = ({ product }: { product: Product }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [deleteProduct] = useMutation(DELETE_PRODUCT);
  const mainImage = product.images?.[0];
  const { isAuthenticated } = useAuth();
  const hasMemberDiscount = product.memberPrice && product.memberPrice < product.price;
  const discountPercentage = hasMemberDiscount 
    ? Math.round(((product.price - product.memberPrice!) / product.price) * 100) 
    : 0;

  const handleDelete = async (productId: string) => {
    try {
      const { data } = await deleteProduct({
        variables: { id: productId },
        update(cache) {
          const existingProducts = cache.readQuery<{ products: Product[] }>({
            query: GET_ALL_PUBLIC_PRODUCTS
          });

          if (existingProducts) {
            cache.writeQuery({
              query: GET_ALL_PUBLIC_PRODUCTS,
              data: {
                products: existingProducts.products.filter(
                  product => product.id !== productId
                )
              }
            });
          }
        }
      });

      if (data.deleteProduct) {
        toast({
          title: "Product deleted successfully",
          status: "success",
          duration: 3000,
        });
      }
    } catch (error) {
      toast({
        title: "Error deleting product",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        status: "error",
        duration: 5000,
      });
    }
  };

  return (
    <Card
      overflow="hidden"
      variant="outline"
      transition="all 0.3s ease"
      position="relative"
      bg="white"
      borderRadius="2xl"
      height={{ base: "auto", md: "500px" }}
      display="flex"
      flexDirection="column"
      border="1px solid"
      borderColor="gray.200"
      _hover={{
        transform: "translateY(-4px)",
        boxShadow: "xl",
        borderColor: "blue.200"
      }}
    >
      {/* Floating Price Tag */}
      <Box
        position="absolute"
        top={4}
        right={4}
        px={4}
        py={3}
        borderRadius="xl"
        fontSize="sm"
        fontWeight="bold"
        transform="rotate(5deg)"
        zIndex={2}
        bg="linear-gradient(135deg, #335786 0%, #39a169 100%)"
        color="white"
        boxShadow="0 4px 20px rgba(51, 87, 134, 0.3)"
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 'xl',
          background: 'linear-gradient(135deg, #335786 0%, #39a169 100%)',
          filter: 'blur(15px)',
          opacity: 0.5,
          transform: 'scale(0.95)',
          zIndex: -1,
          animation: 'pulseGlow 2s infinite'
        }}
        _hover={{
          transform: "rotate(5deg) scale(1.05)",
          boxShadow: "0 8px 30px rgba(51, 87, 134, 0.4)",
        }}
        transition="all 0.3s ease"
        sx={{
          '@keyframes pulseGlow': {
            '0%': { opacity: 0.5, transform: 'scale(0.95)' },
            '50%': { opacity: 0.3, transform: 'scale(1.05)' },
            '100%': { opacity: 0.5, transform: 'scale(0.95)' }
          }
        }}
      >
        <Text fontSize="lg" fontWeight="bold">
          ${product.price.toFixed(2)}
        </Text>
      </Box>
      
      {/* Member Price Badge - only show if there's a member discount */}
      {hasMemberDiscount && (
        <Box
          position="absolute"
          top={4}
          left={4}
          px={4}
          py={2}
          borderRadius="xl"
          fontSize="sm"
          fontWeight="bold"
          zIndex={2}
          bg="linear-gradient(135deg, #7928CA 0%, #FF0080 100%)"
          color="white"
          boxShadow="0 4px 20px rgba(121, 40, 202, 0.3)"
          transform="rotate(-5deg)"
          _before={{
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 'xl',
            background: 'linear-gradient(135deg, #7928CA 0%, #FF0080 100%)',
            filter: 'blur(15px)',
            opacity: 0.5,
            transform: 'scale(0.95)',
            zIndex: -1,
            animation: 'pulseMemberGlow 2s infinite'
          }}
          _hover={{
            transform: "rotate(-5deg) scale(1.05)",
            boxShadow: "0 8px 30px rgba(121, 40, 202, 0.4)",
          }}
          transition="all 0.3s ease"
          sx={{
            '@keyframes pulseMemberGlow': {
              '0%': { opacity: 0.5, transform: 'scale(0.95)' },
              '50%': { opacity: 0.3, transform: 'scale(1.05)' },
              '100%': { opacity: 0.5, transform: 'scale(0.95)' }
            }
          }}
          onClick={() => !isAuthenticated && navigate('/')}
          cursor={!isAuthenticated ? "pointer" : "default"}
        >
          <VStack spacing={0}>
            <Text fontSize="xs" fontWeight="normal">
              {isAuthenticated ? "Member Price" : "Members Save"}
            </Text>
            <Text fontSize="md" fontWeight="bold">
              {isAuthenticated 
                ? `$${product.memberPrice!.toFixed(2)}` 
                : `${discountPercentage}% Off`}
            </Text>
            {!isAuthenticated && (
              <Text fontSize="2xs" fontWeight="normal" mt={1}>
                Join Now →
              </Text>
            )}
          </VStack>
        </Box>
      )}

      {/* Image Container */}
      {mainImage && (
        <Box 
          w="full" 
          h="250px" 
          borderRadius="md" 
          overflow="hidden"
          position="relative"
          zIndex={1}
          boxShadow="0 4px 20px rgba(51, 87, 134, 0.15)"
          _before={{
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(180deg, rgba(51, 87, 134, 0.02) 0%, rgba(57, 161, 105, 0.05) 100%)',
            zIndex: 2,
            pointerEvents: 'none'
          }}
        >
          <Image
            src={mainImage}
            alt={product.name}
            objectFit="cover"
            w="full"
            h="full"
            transition="all 0.5s ease"
            _hover={{
              transform: 'scale(1.05)'
            }}
            sx={{
              imageRendering: 'crisp-edges',
              WebkitBackfaceVisibility: 'hidden',
              MozBackfaceVisibility: 'hidden',
              backfaceVisibility: 'hidden'
            }}
            fallback={
              <Box 
                w="full" 
                h="full" 
                bg="rgba(234, 245, 242, 0.5)"
                display="flex" 
                alignItems="center" 
                justifyContent="center"
              >
                <Text color="#335786">{product.name}</Text>
              </Box>
            }
          />
        </Box>
      )}

      <CardBody flex="1" display="flex" flexDirection="column">
        <VStack spacing={4} align="stretch" height="100%">
          <VStack align="stretch" spacing={3}>
            <Heading
              size="md"
              noOfLines={2}
              color="#335786"
              _hover={{ color: "#39a169" }}
              cursor="pointer"
              onClick={() => navigate(`/products/${product.id}`)}
            >
              {product.name}
            </Heading>

            <Text 
              noOfLines={3} 
              color="gray.600"
              fontSize="sm"
              lineHeight="1.6"
            >
              {product.description}
            </Text>
            
            {/* Add member price info in the card body for authenticated users */}
            {isAuthenticated && hasMemberDiscount && (
              <HStack spacing={2}>
                <Text 
                  fontSize="sm" 
                  fontWeight="bold" 
                  color="gray.500" 
                  textDecoration="line-through"
                >
                  ${product.price.toFixed(2)}
                </Text>
                <Text 
                  fontSize="md" 
                  fontWeight="bold" 
                  color="purple.600"
                >
                  ${product.memberPrice!.toFixed(2)}
                </Text>
                <Badge colorScheme="purple" variant="solid">
                  Save {discountPercentage}%
                </Badge>
              </HStack>
            )}
          </VStack>

          <Spacer />

          <VStack spacing={4} width="100%">
            <Divider />
            
            <Button
              width="full"
              colorScheme="blue"
              variant="outline"
              size="md"
              onClick={() => navigate(`/products/${product.id}`)}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={2}
              _hover={{
                bg: "blue.50",
                transform: "translateY(-2px)",
                boxShadow: "sm"
              }}
              transition="all 0.3s ease"
            >
              View Details
              <Icon as={ViewIcon} />
            </Button>
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

const AllProducts = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isAuthenticated, loading: authLoading } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [sortBy, setSortBy] = useState('name');
  const [expiryCountdown, setExpiryCountdown] = useState<string>('');
  const [loginTime, setLoginTime] = useState<string>('');
  const [totalDuration, setTotalDuration] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [priceRange, setPriceRange] = useState<PriceRange>({ min: 0, max: 100 });

  const { loading, error, data } = useQuery(GET_ALL_PUBLIC_PRODUCTS, {
    onCompleted: (data) => {
      console.log('Products Query Response:', {
        totalProducts: data?.allPublicProducts?.length,
        products: data?.allPublicProducts
      });
    },
    onError: (error) => {
      console.error('Products Query Error:', error);
    }
  });

  const allCategories = useMemo(() => {
    if (!data?.allPublicProducts) return [];
    const categoriesSet = new Set<string>();
    data.allPublicProducts.forEach((product: Product) => {
      product.categories?.forEach(category => categoriesSet.add(category));
    });
    return ['all', ...Array.from(categoriesSet)];
  }, [data]);

  const filteredAndSortedProducts = useMemo(() => {
    if (!data?.allPublicProducts) return [];

    let filtered = [...data.allPublicProducts];

    if (searchTerm) {
      filtered = filtered.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    filtered.sort((a, b) => {
      switch (sortBy) {
        case 'price-low':
          return (a.price || 0) - (b.price || 0);
        case 'price-high':
          return (b.price || 0) - (a.price || 0);
        case 'stock':
          return (b.stockLevel || 0) - (a.stockLevel || 0);
        default:
          return a.name.localeCompare(b.name);
      }
    });

    return filtered;
  }, [data, searchTerm, sortBy]);



  useEffect(() => {
    const updateCountdown = () => {
      const token = localStorage.getItem('auth_token');
      if (token) {
        const decoded = jwtDecode<{ exp: number; iat: number }>(token);
        const expiryTime = decoded.exp * 1000; // Convert to milliseconds
        const issuedTime = decoded.iat * 1000; // Convert to milliseconds
        const now = Date.now();
        const diff = expiryTime - now;

        // Calculate total duration
        const totalDurationMs = expiryTime - issuedTime;
        const totalDays = Math.floor(totalDurationMs / (1000 * 60 * 60 * 24));
        setTotalDuration(`${totalDays} days`);

        // Format login time
        setLoginTime(new Date(issuedTime).toLocaleString());

        // Calculate countdown
        if (diff > 0) {
          const hours = Math.floor(diff / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          setExpiryCountdown(`${hours} hours, ${minutes} minutes`);
        } else {
          setExpiryCountdown('Expired');
        }
      }
    };

    // Update immediately
    updateCountdown();

    // Then update every minute
    const interval = setInterval(updateCountdown, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const storedPassword = localStorage.getItem("admin_password");
    setIsAdmin(storedPassword === "123456");
  }, []);

  if (authLoading) {
    return (
      <Box>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Center h="50vh">
            <Spinner size="xl" />
          </Center>
        </Container>
        <FooterWithFourColumns />
      </Box>
    );
  }



  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      <NavbarWithCallToAction />

      <Box
        position="relative"
        minH="100vh"
        py={8}
      >
        {/* Base gradient */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          sx={{
            background: `
              radial-gradient(circle at 0% 0%, rgba(51, 87, 134, 0.15) 0%, transparent 50%),
              radial-gradient(circle at 100% 0%, rgba(57, 161, 105, 0.15) 0%, transparent 50%),
              radial-gradient(circle at 0% 100%, rgba(234, 245, 242, 0.2) 0%, transparent 50%),
              radial-gradient(circle at 100% 100%, rgba(51, 87, 134, 0.15) 0%, transparent 50%)
            `,
            filter: 'blur(60px)',
          }}
        />

        {/* Animated overlay */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          sx={{
            background: `
              repeating-linear-gradient(
                45deg,
                rgba(51, 87, 134, 0.03) 0%,
                rgba(57, 161, 105, 0.03) 25%,
                rgba(234, 245, 242, 0.03) 50%,
                rgba(51, 87, 134, 0.03) 75%,
                rgba(57, 161, 105, 0.03) 100%
              )
            `,
            backgroundSize: '400% 400%',
            animation: 'gradient 15s ease infinite',
          }}
        />

        {/* Content container */}
        <Box
          position="relative"
          zIndex="1"
          flex="1"
        >
          <Container maxW="container.xl">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Stack spacing={8}>
                {/* Header Section */}
                <Card
                  bg="rgba(255, 255, 255, 0.9)"
                  backdropFilter="blur(10px)"
                  borderRadius="2xl"
                  boxShadow="0 8px 32px rgba(0, 0, 0, 0.12)"
                  border="1px solid"
                  borderColor="gray.100"
                  overflow="hidden"
                  transition="all 0.2s"
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "xl"
                  }}
                >
                  <CardBody>
                    <Stack spacing={6}>
                      <HStack justify="space-between" wrap="wrap" spacing={4}>
                        <VStack align="start" spacing={2}>
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            textTransform="uppercase"
                            letterSpacing="wider"
                          >
                            Product Catalog
                          </Text>
                          <Heading size="lg">All Products</Heading>
                        </VStack>
                        <Button
                          leftIcon={<AddIcon />}
                          colorScheme="blue"
                          onClick={() => navigate('/products/new')}
                          size="lg"
                        >
                          Add New Physical Product
                        </Button>
                      </HStack>

                      {/* Search and Filter Section */}
                      <Grid
                        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
                        gap={4}
                      >
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="Search products..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            bg="white"
                            _hover={{ borderColor: "blue.400" }}
                          />
                        </InputGroup>

                        <Select
                          value={categoryFilter}
                          onChange={(e) => setCategoryFilter(e.target.value)}
                          bg="white"
                          _hover={{ borderColor: "blue.400" }}
                        >
                          {allCategories.map(category => (
                            <option key={category} value={category}>
                              {category.charAt(0).toUpperCase() + category.slice(1)}
                            </option>
                          ))}
                        </Select>

                        <Select
                          value={sortBy}
                          onChange={(e) => setSortBy(e.target.value)}
                          bg="white"
                          _hover={{ borderColor: "blue.400" }}
                        >
                          <option value="name">Name</option>
                          <option value="price-low">Price: Low to High</option>
                          <option value="price-high">Price: High to Low</option>
                          <option value="stock">Stock Level</option>
                        </Select>
                      </Grid>

                      <Grid templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }} gap={4}>
                        <HStack>
                          <Input
                            type="number"
                            placeholder="Min Price"
                            value={priceRange.min}
                            onChange={(e) => setPriceRange(prev => ({ ...prev, min: Number(e.target.value) }))}
                          />
                          <Input
                            type="number"
                            placeholder="Max Price"
                            value={priceRange.max}
                            onChange={(e) => setPriceRange(prev => ({ ...prev, max: Number(e.target.value) }))}
                          />
                        </HStack>
                      </Grid>
                    </Stack>
                  </CardBody>
                </Card>

                {/* Products Grid */}
                <Card
                  bg="rgba(255, 255, 255, 0.9)"
                  backdropFilter="blur(10px)"
                  borderRadius="2xl"
                  boxShadow="0 8px 32px rgba(0, 0, 0, 0.12)"
                  border="1px solid"
                  borderColor="gray.100"
                >
                  <CardBody>
                    {loading ? (
                      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                        {[...Array(6)].map((_, i) => (
                          <Skeleton key={i} height="400px" />
                        ))}
                      </SimpleGrid>
                    ) : (
                      <>
                        {filteredAndSortedProducts.length > 0 ? (
                          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                            {filteredAndSortedProducts.map((product: Product) => (
                              <motion.div
                                key={product.id}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.4 }}
                              >
                                <ProductCard product={product} />
                              </motion.div>
                            ))}
                          </SimpleGrid>
                        ) : (
                          <Box textAlign="center" py={10}>
                            <Text fontSize="lg" color="gray.500">
                              No products found matching your criteria
                            </Text>
                          </Box>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>

               
              </Stack>
            </motion.div>
          </Container>
        </Box>

        {/* Animation keyframes */}
        <style>{`
          @keyframes gradient {
            0% { background-position: 0% 50% }
            50% { background-position: 100% 50% }
            100% { background-position: 0% 50% }
          }
        `}</style>
      </Box>

      <FooterWithFourColumns />
    </Box>
  );
};

export default AllProducts; 