import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  Image,
  Center,
  Link
} from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { Logo } from '../../Logo'
// import { links } from './_data'
import InviteLink from '../../../components/InviteLink';
import { useAuth } from '../../../contexts/AuthContext';
import { CartPreview } from '../../CartPreview';






export const FooterWithFourColumns = () => {
  const { user, isAuthenticated } = useAuth();

  // Detailed console logging of user data
  // console.log('🦶 Footer Auth State:', {
  //   isAuthenticated,
  //   userData: {
  //     id: user?.id,
  //     name: `${user?.fName} ${user?.lName}`,
  //     email: user?.email,
  //     role: user?.role,
  //     permissions: user?.permissions,
  //     isVerifiedSeller: user?.isVerifiedSeller,
  //     businessDetails: {
  //       name: user?.businessName,
  //       registrationNumber: user?.businessRegistrationNumber
  //     },
  //     addresses: {
  //       shipping: user?.shippingAddresses,
  //       billing: user?.billingAddresses
  //     },
  //     paymentDetails: user?.paymentReceivingDetails,
  //     documents: {
  //       signature: user?.encryptedSignatureIpfsUrl
  //     },
  //     dates: {
  //       created: user?.createdAt,
  //       updated: user?.updatedAt
  //     },
  //     tenantId: user?.tenantId
  //   }
  // });

  const links = [
    {
      // title: 'Company',
      links: [

        // { label: 'New Website', href: '/newwebsite' },
        // { label: 'Websites', href: '/websites' },
        // { label: 'Services', href: '/services' },
        // { label: 'New Service', href: '/services/new' },
        ...(isAuthenticated ? [{ label: 'Marketplace', href: '/products' }] : []),
        { label: 'Governance', href: '/governance' },
  
  
          // ...(isAuthenticated && user?.permissions?.includes('SELLER') ? [{ label: 'Add Product', href: '/products/new' }] : []),
        // Add membership agreement link if signature exists
        ...(user?.encryptedSignatureIpfsUrl ? [
          { 
            label: 'My Signed Membership Agreement', 
            href: `/membership-certificate/${user.id}` 
          }
        ] : []),
        // { label: 'Careers', href: '#' },
        // { label: 'Press', href: '#' },
        // { label: 'FAQs', href: '/earn' },
      ],
    },
    {
      // title: 'Product',
      links: [
        { label: 'Admin', href: '/admin' },
        // { label: 'New Client', href: '/newclient' },
        // { label: 'Our Clients', href: '/clients' },
        // ...(user ? [{ label: 'How it Works', href: '/earn' }] : []),
        // ...(user ? [{ label: 'Our Locations', href: '/our-location-hubs' }] : []),
        // ...(isAuthenticated ? [{ label: 'Our Producers', href: '/producers' }] : []),
        // ...(isAuthenticated ? [{ label: 'Our Impact', href: '/farm-sales-dashboard' }] : []),
        // ...(isAuthenticated ? [{ label: 'Earn With Us', href: '/earn' }] : []),
        // { label: 'Cultivating Resiliance', href: '/cultivating-resilience-infographic' },
        // ...(user ? [{ label: 'Our Impact', href: '/farm-sales-dashboard' }] : []),
        // { label: 'Get Started', href: 'start' },
        // { label: 'Use Cases', href: '/earn' },
        // { label: 'Integrations', href: '#' },
        // { label: 'SAML SSO', href: '#' },
      ],
    },
    {
      title: 'Resources',
      links: [
        { label: 'Blog', href: '/blog' },
        ...(isAuthenticated ? [{ label: 'News', href: '/news' }] : []),
        // ...(isAuthenticated ? [{ label: 'SATS=>AUD', href: 'https://coincodex.com/convert/satoshi-sats/aud/1000/' }] : []),
        // { label: 'Case studies', href: '#' },
        // { label: 'Media Assets', href: '#' },
      ],
    },
    {
      title: 'Admin',
      links: [
        ...(user?.permissions?.includes('admin') ? [{ label: 'All Users (admin)', href: '/allusers' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: 'All Purchases (admin)', href: '/alluserspurchases' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: 'Node Info (admin)', href: '/nodeinfo' }] : []),
        // ...(user?.permissions?.includes('admin') ? [{ label: 'BTCPay Invoices', href: '/btcpayinvoices' }] : []),
        // ...(user?.permissions?.includes('admin') ? [{ label: 'All Orders', href: '/allorders' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: '3 Day Challenge', href: '/3daychallenge' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: '1 Lead Magnet', href: '/leadmagnet' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: '2 Webinar Invite', href: '/relationships/free-event' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: '3 Sales Page', href: '/courses/relationships/start' }] : []),
        // ...(user?.permissions?.includes('admin') ? [{ label: '2 Webinar Sales Page', href: '/relationships/free-event' }] : []),
        // ...(user?.permissions?.includes('admin') ? [{ label: 'Relationship Registrations', href: '/admin/relationshipcourseregistrations' }] : []),
        // { label: 'Privacy Policy', href: '#' },
        // { label: 'Offer terms', href: '#' },
        // { label: 'License', href: '#' },
        { label: 'About', href: '/founders' },
      ],
    },
  ]

  // Debug log for links visibility
  // console.log('🔗 Footer Links State:', {
  //   isAuthenticated,
  //   userPermissions: user?.permissions,
  //   visibleLinks: links.map(group => ({
  //     title: group.title,
  //     links: group.links.map(link => link.label)
  //   }))
  // });

  // Debug log to verify signature URL condition
  // console.log('📜 Membership Agreement State:', {
  //   hasSignature: !!user?.encryptedSignatureIpfsUrl,
  //   signatureUrl: user?.encryptedSignatureIpfsUrl,
  //   userId: user?.id,
  //   membershipLink: user?.encryptedSignatureIpfsUrl ? `/membership-certificate/${user.id}` : null
  // });

  return (<>
    <Box 
      bg="#eaf5f2"
      boxShadow="0 -2px 10px rgba(51, 87, 134, 0.1)"
      pb={8}
    >
      <Container as="footer" role="contentinfo" maxW="6xl">
        <Stack
          justify="space-between"
          align="start"
          direction={{ base: 'column', lg: 'row' }}
          py={{ base: '12', md: '16' }}
          spacing="8"
        >
          <Stack spacing={{ base: '3', md: '8' }} align="start">
            <Logo />
          </Stack>

          <SimpleGrid 
            columns={{ base: 2, md: 4 }} 
            gap="8" 
            width={{ base: 'full', lg: 'auto' }}
          >
            {links.map((group, idx) => (
              <Stack key={idx} spacing="3" minW={{ lg: '40' }}>
                <Stack spacing="3" shouldWrapChildren>
                  {group.links.map((link, idx) => (
                    <Button 
                      key={idx} 
                      as="a" 
                      variant="text" 
                      color="#335786"
                      href={link.href} 
                      size="xs"
                      transition="all 0.3s ease"
                      _hover={{
                        color: "#39a169",
                        transform: "translateX(5px)"
                      }}
                    >
                      {link.label}
                    </Button>
                  ))}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>

        <Divider borderColor="rgba(51, 87, 134, 0.1)" my={6} />

        <Box 
          bg="white" 
          p={4} 
          borderRadius="lg"
          boxShadow="0 2px 10px rgba(51, 87, 134, 0.1)"
          transition="all 0.3s ease"
          _hover={{
            boxShadow: "0 4px 20px rgba(51, 87, 134, 0.15)",
            transform: "translateY(-2px)"
          }}
          mb={6}
        >
          <Stack spacing={2} textAlign="center" w="full">
            <Text fontSize="sm" color="#335786">
              &copy; {new Date().getFullYear()} Life Essential Services
            </Text>
            <Text fontSize="sm" color="#335786">
              Community • Sustainability • Natural Living
            </Text>
            <Text fontSize="xs" color="gray.500">
              Based in Australia • Growing Globally Through Nature
            </Text>
            <Stack direction="row" spacing={4} justify="center" mt={2}>
              <Link
                href="/"
                fontSize="xs"
                color="#335786"
                _hover={{ 
                  textDecoration: 'underline',
                  color: "#39a169"
                }}
              >
                Home
              </Link>
              <Link
                href="/governance"
                fontSize="xs"
                color="#335786"
                _hover={{ 
                  textDecoration: 'underline',
                  color: "#39a169"
                }}
              >
                Governance
              </Link>
              <Link
                href="/products"
                fontSize="xs"
                color="#335786"
                _hover={{ 
                  textDecoration: 'underline',
                  color: "#39a169"
                }}
              >
                Products
              </Link>
              <Link
                href="/"
                fontSize="xs"
                color="#335786"
                _hover={{ 
                  textDecoration: 'underline',
                  color: "#39a169"
                }}
              >
                Sign Up
              </Link>
              <Link
                href="/governing-rules"
                fontSize="xs"
                color="#335786"
                _hover={{ 
                  textDecoration: 'underline',
                  color: "#39a169"
                }}
              >
                Governing Rules
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>

    <Box
      position="fixed"
      bottom={4}
      right={4}
      zIndex={999}
    >
      <CartPreview />
    </Box>
  </>
  )
}