import React, { useState, useRef, createRef } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Badge,
  Input,
  FormControl,
  FormLabel,
  HStack,
  Tooltip,
  keyframes,
  Icon,
} from '@chakra-ui/react';
import { InfoIcon, TimeIcon, CheckIcon, CloseIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import FullCalendar from '@fullcalendar/react';
import { EventInput, DateSelectArg, EventContentArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';

import { NavbarWithCallToAction } from '../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const GET_ALL_SESSIONS = gql`
  query GetAllSessions($startDate: String!, $endDate: String!) {
    sessions(
      startDate: $startDate, 
      endDate: $endDate
    ) {
      id
      name
      description
      startTime
      endTime
      maxParticipants
      meetingLink
      isCancelled
    }
  }
`;

const GET_CLIENT_BOOKINGS = gql`
  query GetClientBookings($clientId: String!) {
    bookingsByClientId(clientId: $clientId) {
      id
      bookingTime
      status
      payment {
        status
        method
        amount
        currency
      }
      session {
        id
        name
        startTime
        endTime
        type {
          name
          format
        }
      }
      sendReminders
    }
  }
`;

const CREATE_BOOKING = gql`
  mutation CreateBooking($input: BookingInput!) {
    createBooking(input: $input) {
      id
      bookingTime
      status
      payment {
        status
        method
        amount
      }
      session {
        id
        name
        startTime
        endTime
      }
      client {
        id
        fName
        lName
      }
    }
  }
`;

const CANCEL_BOOKING = gql`
  mutation CancelBooking($bookingId: String!, $reason: String!) {
    cancelBooking(bookingId: $bookingId, reason: $reason) {
      id
      status
      cancellationReason
      session {
        name
        startTime
      }
    }
  }
`;

// Define BookingPaymentMethod enum if not already defined elsewhere
enum BookingPaymentMethod {
  STRIPE = "STRIPE",
  BANK_TRANSFER = "BANK_TRANSFER",
  CRYPTO = "CRYPTO",
  OTHER = "OTHER"
}

export const ClientBookingCalendar = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: isBookingDetailOpen, 
    onOpen: onBookingDetailOpen, 
    onClose: onBookingDetailClose 
  } = useDisclosure();
  const [selectedSession, setSelectedSession] = useState<any>(null);
  const [selectedBooking, setSelectedBooking] = useState<any>(null);
  const [cancellationReason, setCancellationReason] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Get authenticated user from context
  const { user, isAuthenticated, loading: authLoading } = useAuth();

  // Calculate date range for query based on calendar view
  const [queryDateRange, setQueryDateRange] = useState({
    startDate: "2025-03-15T00:00:00.000Z",
    endDate: "2025-04-15T23:59:59.999Z"
  });

  // Update view state to always default to week view
  const [calendarView, setCalendarView] = useState('timeGridWeek');
  // Add state for 24-hour view toggle
  const [is24HourView, setIs24HourView] = useState(false);

  // Query for available sessions with dynamic date range
  const { data: sessionsData, loading: sessionsLoading, error: sessionsError, refetch: refetchSessions } = useQuery(GET_ALL_SESSIONS, {
    variables: {
      startDate: queryDateRange.startDate,
      endDate: queryDateRange.endDate
    },
    onCompleted: (data) => {
      console.log("Sessions data received:", data);
      console.log("Number of sessions:", data?.sessions?.length || 0);
    },
    onError: (error) => {
      console.error("Error fetching sessions:", error);
    }
  });

  // Query for client's existing bookings with updated query
  const { data: bookingsData, loading: bookingsLoading, refetch: refetchBookings } = useQuery(GET_CLIENT_BOOKINGS, {
    variables: {
      clientId: user?.id || ""
    },
    context: {
      headers: {
        "x-tenant-id": user?.tenantId || localStorage.getItem("tenantId") || "6793318fb89f8ab1cf5f3bcf"
      }
    },
    skip: !user?.id, // Skip if no user ID is available
  });

  const [createBooking, { loading: bookingLoading, data: bookingData }] = useMutation(CREATE_BOOKING);
  const [cancelBooking, { loading: cancelLoading }] = useMutation(CANCEL_BOOKING, {
    onCompleted: () => {
      toast({
        title: "Booking cancelled",
        description: "Your booking has been successfully cancelled.",
        status: "success",
        duration: 3000
      });
      refetchBookings();
      onBookingDetailClose();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000
      });
    }
  });

  // Remove the resize effect that changes views, but keep other necessary functionality
  React.useEffect(() => {
    const handleResize = () => {
      // Only handle other responsive adjustments if needed
      // Don't change the view type based on screen size
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Improve date parameter handling
  const handleDatesSet = (dateInfo: any) => {
    // Get the start of the week being displayed
    const weekStart = dateInfo.start;
    
    // Update URL with the week's start date
    setSearchParams({ 
      week: weekStart.toISOString(),
      view: calendarView // Add view to URL params
    });
    
    // Update query date range based on calendar view
    setQueryDateRange({
      startDate: dateInfo.start.toISOString(),
      endDate: dateInfo.end.toISOString()
    });
    
    console.log('Calendar dates changed:', {
      weekStart: weekStart.toISOString(),
      weekEnd: dateInfo.end.toISOString(),
      displayRange: `${weekStart.toLocaleDateString()} - ${dateInfo.end.toLocaleDateString()}`
    });
  };

  // Improved initialDate handling to ensure consistent format
  const initialDate = React.useMemo(() => {
    const weekParam = searchParams.get('week');
    
    try {
      if (weekParam) {
        // Ensure date is properly parsed by creating a new Date object
        const date = new Date(weekParam);
        
        // Check if date is valid
        if (!isNaN(date.getTime())) {
          console.log("Using date from URL:", date.toISOString());
          return date;
        }
      }
    } catch (error) {
      console.error("Error parsing date from URL:", error);
    }

    // Default fallback - current week
    const today = new Date();
    const currentWeekStart = new Date(today);
    currentWeekStart.setHours(0, 0, 0, 0);
    currentWeekStart.setDate(today.getDate() - today.getDay()); // Set to Sunday
    
    // Update URL with current week if no valid param
    setSearchParams({ week: currentWeekStart.toISOString(), view: calendarView });
    
    return currentWeekStart;
  }, [searchParams, setSearchParams, calendarView]);

  const handleEventClick = (clickInfo: any) => {
    // Check if this is a booking event
    if (clickInfo.event.extendedProps.isBooking) {
      // For viewing bookings, need to be logged in
      if (!isAuthenticated) {
        toast({
          title: "Login Required",
          description: "Please log in to view your bookings",
          status: "info",
          duration: 3000,
        });
        navigate('/');
        return;
      }
      
      const bookingId = clickInfo.event.extendedProps.bookingId;
      const booking = bookingsData?.bookingsByClientId.find((b: any) => b.id === bookingId);
      
      if (booking) {
        setSelectedSession(null);
        setSelectedBooking(booking);
        onBookingDetailOpen();
      }
      return;
    }
    
    // Handle session event (for booking)
    const sessionId = clickInfo.event.id;
    const session = sessionsData?.sessions.find((s: any) => s.id === sessionId);
    
    if (session && !session.isCancelled) {
      setSelectedBooking(null);
      setSelectedSession(session);
      onOpen();
    }
  };

  const handleBookSession = async () => {
    if (!selectedSession) return;

    // Check if user is authenticated
    if (!isAuthenticated) {
      toast({
        title: "Login Required",
        description: "Please log in to book this session",
        status: "info",
        duration: 3000,
      });
      onClose(); // Close the modal
      navigate('/'); // Navigate to home page
      return;
    }

    if (!user?.id) {
      toast({
        title: "Error",
        description: "User information is missing. Please log in again.",
        status: "error",
        duration: 3000,
      });
      return;
    }

    try {
      await createBooking({
        variables: {
          input: {
            sessionId: selectedSession.id,
            clientId: user.id,
            payment: {
              method: BookingPaymentMethod.STRIPE,
              amount: selectedSession.type?.price || 0,
              currency: "USD"
            },
            sendReminders: true
          }
        },
        context: {
          headers: {
            "x-tenant-id": user.tenantId || "6793318fb89f8ab1cf5f3bcf"
          }
        }
      });
      
      // Refetch bookings to update the calendar
      await Promise.all([refetchSessions(), refetchBookings()]);
      
      toast({
        title: "Booking successful!",
        description: "Your session has been booked.",
        status: "success",
        duration: 3000
      });
      
      // Don't close the modal yet - we'll show the confirmation
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to book session",
        status: "error",
        duration: 5000
      });
    }
  };

  const handleCancelBooking = async () => {
    if (!selectedBooking || !cancellationReason.trim()) {
      toast({
        title: "Error",
        description: "Please provide a reason for cancellation",
        status: "warning",
        duration: 3000
      });
      return;
    }

    try {
      await cancelBooking({
        variables: {
          bookingId: selectedBooking.id,
          reason: cancellationReason
        },
        context: {
          headers: {
            "x-tenant-id": user?.tenantId || localStorage.getItem("tenantId") || "6793318fb89f8ab1cf5f3bcf"
          }
        }
      });
    } catch (error) {
      // Error is handled in the onError callback
    }
  };

  // Helper function to get booking color based on status
  const getBookingColor = (status: string) => {
    switch (status) {
      case 'CONFIRMED':
        return '#2196F3'; // Blue
      case 'COMPLETED':
        return '#4CAF50'; // Green
      case 'CANCELLED':
        return '#F44336'; // Red
      case 'PENDING':
        return '#FFC107'; // Amber
      default:
        return '#9E9E9E'; // Grey
    }
  };

  // Helper function to get booking border color
  const getBookingBorderColor = (status: string) => {
    switch (status) {
      case 'CONFIRMED':
        return '#1976D2'; // Darker blue
      case 'COMPLETED':
        return '#388E3C'; // Darker green
      case 'CANCELLED':
        return '#D32F2F'; // Darker red
      case 'PENDING':
        return '#FFA000'; // Darker amber
      default:
        return '#757575'; // Darker grey
    }
  };

  // Add a pulsing animation for pending status
  const pulseAnimation = keyframes`
    0% { opacity: 0.7; transform: scale(0.95); }
    50% { opacity: 1; transform: scale(1.05); }
    100% { opacity: 0.7; transform: scale(0.95); }
  `;
  
  // Get status icon based on booking status
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'CONFIRMED':
        return CheckIcon;
      case 'COMPLETED':
        return CheckIcon;
      case 'CANCELLED':
        return CloseIcon;
      case 'PENDING':
        return TimeIcon;
      default:
        return InfoIcon;
    }
  };

  // Helper to get human-readable status message
  const getStatusMessage = (status: string, paymentStatus?: string) => {
    switch (status) {
      case 'PENDING':
        return `Booking pending${paymentStatus ? ` - Payment: ${paymentStatus}` : ''}`;
      case 'CONFIRMED':
        return 'Booking confirmed';
      case 'COMPLETED':
        return 'Session completed';
      case 'CANCELLED':
        return 'Booking cancelled';
      default:
        return status;
    }
  };

  // Add a shimmer animation for sessions
  const shimmerAnimation = keyframes`
    0% { background-position: -500px 0; }
    100% { background-position: 500px 0; }
  `;

  // Add a subtle bounce animation for sessions
  const bounceAnimation = keyframes`
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-4px); }
  `;

  // Add glow animation
  const glowAnimation = keyframes`
    0%, 100% { box-shadow: 0 0 5px rgba(57, 161, 105, 0.5); }
    50% { box-shadow: 0 0 20px rgba(57, 161, 105, 0.8); }
  `;

  // Convert sessions and bookings to calendar events
  const events: EventInput[] = React.useMemo(() => {
    // Session events (available sessions)
    const sessionEvents = sessionsData?.sessions?.map((session: any) => {
      // Skip cancelled sessions
      if (session.isCancelled) return null;
      
      return {
        id: session.id,
        start: session.startTime,
        end: session.endTime,
        title: session.name,
        backgroundColor: 'rgba(57, 161, 105, 0.85)',
        borderColor: '#2F855A',
        textColor: '#ffffff',
        classNames: ['exciting-session-event'],
        extendedProps: {
          description: session.description,
          maxParticipants: session.maxParticipants,
          meetingLink: session.meetingLink,
          isCancelled: session.isCancelled,
          isBooking: false
        }
      };
    }).filter(Boolean) || [];

    // Booking events (user's existing bookings) - only show if authenticated
    const bookingEvents = (isAuthenticated && bookingsData?.bookingsByClientId) ? 
      bookingsData?.bookingsByClientId?.map((booking: any) => ({
        id: `booking-${booking.id}`,
        start: booking.session.startTime,
        end: booking.session.endTime,
        title: `Booked: ${booking.session.name}`,
        backgroundColor: getBookingColor(booking.status),
        borderColor: getBookingBorderColor(booking.status),
        textColor: '#ffffff',
        extendedProps: {
          bookingId: booking.id,
          status: booking.status,
          paymentStatus: booking.payment?.status,
          isBooking: true,
          sessionType: booking.session.type?.name,
          sessionFormat: booking.session.type?.format
        }
      })) : [];

    return [...sessionEvents, ...(bookingEvents || [])];
  }, [sessionsData, bookingsData, isAuthenticated]);

  // Update the renderEventContent function to make events more attractive
  const renderEventContent = (eventInfo: EventContentArg) => {
    const isMobile = window.innerWidth < 768;
    const isPending = eventInfo.event.extendedProps.status === 'PENDING';
    const StatusIcon = getStatusIcon(eventInfo.event.extendedProps.status);
    const isSession = !eventInfo.event.extendedProps.isBooking;
    
    // Session event with fancy styling
    if (isSession) {
      return (
        <Box 
          fontSize={isMobile ? "xs" : "sm"} 
          overflow="hidden" 
          width="100%"
          height="100%"
          position="relative"
          borderRadius="md"
          cursor="pointer"
          transition="all 0.3s ease"
          _before={{
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(120deg, rgba(255,255,255,0) 30%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0) 70%)',
            backgroundSize: '1000px 100%',
            animation: `${shimmerAnimation} 3s infinite linear`,
            zIndex: 0
          }}
          _hover={{
            transform: "scale(1.03)",
            boxShadow: "0 0 15px rgba(57, 161, 105, 0.7)"
          }}
          sx={{
            '&:hover': {
              '.session-title': {
                color: 'white !important',
                fontWeight: 'bold',
              }
            }
          }}
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            width="4px"
            height="100%"
            bg="yellow.400"
            borderTopLeftRadius="md"
            borderBottomLeftRadius="md"
          />
          
          <Tooltip
            label={
              <VStack spacing={2} p={2} align="start">
                <Heading size="xs">{eventInfo.event.title}</Heading>
                {eventInfo.event.extendedProps.description && (
                  <Text fontSize="xs">{eventInfo.event.extendedProps.description}</Text>
                )}
                <Text fontSize="xs" fontWeight="bold">
                  {new Date(eventInfo.event.start!).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} - {new Date(eventInfo.event.end!).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </Text>
                <Text fontSize="xs">Click to book this session</Text>
              </VStack>
            }
            placement="top"
            hasArrow
            gutter={10}
            bg="green.600"
            borderRadius="md"
            width="220px"
          >
            <Box p={1} position="relative" zIndex={1}>
              <HStack spacing={1} mb={1}>
                <Icon 
                  as={InfoIcon} 
                  color="yellow.400"
                  animation={`${bounceAnimation} 2s infinite ease-in-out`}
                  boxSize={isMobile ? 2 : 3}
                />
                <Badge 
                  colorScheme="green" 
                  fontSize={isMobile ? "2xs" : "xs"}
                  variant="solid"
                >
                  Available
                </Badge>
              </HStack>
              
              <Text 
                fontWeight="bold" 
                fontSize={isMobile ? "2xs" : "xs"} 
                color="white"
                className="session-title"
              >
                {eventInfo.timeText}
              </Text>
              
              <Text 
                isTruncated 
                className="session-title"
                fontSize={isMobile ? "2xs" : "xs"}
                color="white"
              >
                {eventInfo.event.title}
              </Text>
            </Box>
          </Tooltip>
        </Box>
      );
    }
    
    // Booking event styling (unchanged)
    const eventContent = (
      <Box 
        fontSize={isMobile ? "xs" : "sm"} 
        overflow="hidden" 
        width="100%"
        position="relative"
        animation={isPending ? `${pulseAnimation} 2s infinite` : undefined}
      >
        <Text fontWeight="bold" isTruncated>{eventInfo.timeText}</Text>
        <Text isTruncated>{eventInfo.event.title}</Text>
        
        {eventInfo.event.extendedProps.isBooking && (
          <HStack spacing={1} mt={1}>
            <Icon 
              as={StatusIcon} 
              color={
                eventInfo.event.extendedProps.status === 'CONFIRMED' ? 'blue.500' : 
                eventInfo.event.extendedProps.status === 'COMPLETED' ? 'green.500' :
                eventInfo.event.extendedProps.status === 'CANCELLED' ? 'red.500' : 'yellow.500'
              }
              boxSize={isMobile ? 3 : 4}
            />
            
            <Badge 
              variant="solid"
              colorScheme={
                eventInfo.event.extendedProps.status === 'CONFIRMED' ? 'blue' : 
                eventInfo.event.extendedProps.status === 'COMPLETED' ? 'green' :
                eventInfo.event.extendedProps.status === 'CANCELLED' ? 'red' : 'yellow'
              }
              fontSize={isMobile ? "2xs" : "xs"}
              px={1}
            >
              {eventInfo.event.extendedProps.status}
            </Badge>
            
            {isPending && eventInfo.event.extendedProps.paymentStatus && !isMobile && (
              <Badge colorScheme="gray" fontSize="xs">
                Pay: {eventInfo.event.extendedProps.paymentStatus}
              </Badge>
            )}
          </HStack>
        )}
      </Box>
    );
    
    // Only add tooltip on desktop for bookings
    if (!isMobile && eventInfo.event.extendedProps.isBooking) {
      return (
        <Tooltip 
          label={getStatusMessage(
            eventInfo.event.extendedProps.status,
            eventInfo.event.extendedProps.paymentStatus
          )}
          placement="top"
          hasArrow
          bg={
            eventInfo.event.extendedProps.status === 'CONFIRMED' ? 'blue.600' : 
            eventInfo.event.extendedProps.status === 'COMPLETED' ? 'green.600' :
            eventInfo.event.extendedProps.status === 'CANCELLED' ? 'red.600' : 'yellow.600'
          }
        >
          {eventContent}
        </Tooltip>
      );
    }
    
    return eventContent;
  };

  // Add a ref to access the calendar API
  const calendarRef = createRef<any>();
  
  // Add these navigation handlers
  const handlePrevClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.prev();
    }
  };

  const handleNextClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.next();
    }
  };

  const navigate = useNavigate();

  // Handle the toggle for 24-hour view
  const toggle24HourView = () => {
    setIs24HourView(prev => !prev);
  };

  if (authLoading) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <VStack spacing={4} align="center" justify="center" height="400px">
            <Text>Loading...</Text>
          </VStack>
        </Container>
        <FooterWithFourColumns />
      </>
    );
  }

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={{ base: 4, md: 8 }} px={{ base: 2, md: 4 }}>
        <VStack spacing={{ base: 3, md: 4 }} align="stretch">
          <Box bg="blue.50" p={{ base: 3, md: 4 }} borderRadius="md">
            {isAuthenticated ? (
              <Text fontSize={{ base: "sm", md: "md" }}>
                Welcome, {user?.fName} {user?.lName}! You can book available sessions below.
              </Text>
            ) : (
              <Text fontSize={{ base: "sm", md: "md" }}>
                Welcome! Browse available sessions below. You'll need to log in to book a session.
              </Text>
            )}
          </Box>
          
          <Heading size={{ base: "md", md: "lg" }}>
            Available Sessions {(sessionsLoading) ? '(Loading...)' : ''}
          </Heading>
          
          {/* Add session type explanation section */}
          <HStack spacing={4} flexWrap="wrap" justify="space-between" pt={2} pb={4}>
            <Box 
              p={3} 
              bg="green.50" 
              borderRadius="md"
              borderLeft="4px solid" 
              borderColor="yellow.400"
              boxShadow="sm"
              _hover={{ transform: "translateY(-2px)", boxShadow: "md" }}
              transition="all 0.3s"
              animation={`${glowAnimation} 4s infinite ease-in-out`}
            >
              <Heading size="xs" mb={1} color="green.700">Session Guide</Heading>
              <Text fontSize="xs" color="gray.600">Click on any green session to view details and book your spot!</Text>
            </Box>

            <Button
              onClick={toggle24HourView}
              colorScheme={is24HourView ? "blue" : "gray"}
              size="sm"
              variant={is24HourView ? "solid" : "outline"}
              leftIcon={<Icon as={TimeIcon} />}
              transition="all 0.3s ease"
              boxShadow={is24HourView ? "md" : "none"}
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "md"
              }}
            >
              {is24HourView ? "Show Business Hours" : "Show Full 24 Hours"}
            </Button>
          </HStack>
        
          <Box 
            p={{ base: 2, md: 4 }} 
            borderWidth="1px" 
            borderRadius="lg"
            overflowX="auto"
            position="relative"
            boxShadow="lg"
            _before={{
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: 'lg',
              padding: '2px',
              background: 'linear-gradient(45deg, #3182CE, #38A169, #ECC94B)',
              mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              maskComposite: 'xor',
              pointerEvents: 'none'
            }}
          >
            {/* Add floating navigation buttons */}
            <Box
              position="absolute"
              left={{ base: 1, md: 2 }}
              top="50%"
              transform="translateY(-50%)"
              zIndex={10}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Button
                onClick={handlePrevClick}
                size="sm"
                borderRadius="full"
                width={{ base: "36px", md: "40px" }}
                height={{ base: "36px", md: "40px" }}
                p={0}
                bg="green.400"
                _hover={{ 
                  bg: "green.500", 
                  transform: "scale(1.1) translateX(-2px)", 
                  boxShadow: "0 0 15px rgba(57, 161, 105, 0.6)" 
                }}
                _active={{ bg: "green.600", transform: "scale(0.95)" }}
                transition="all 0.3s ease"
                boxShadow="0 2px 10px rgba(0,0,0,0.15)"
                aria-label="Previous"
              >
                <ChevronLeftIcon boxSize={{ base: 4, md: 6 }} color="white" />
              </Button>
            </Box>

            <Box
              position="absolute"
              right={{ base: 1, md: 2 }}
              top="50%"
              transform="translateY(-50%)"
              zIndex={10}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Button
                onClick={handleNextClick}
                size="sm"
                borderRadius="full"
                width={{ base: "36px", md: "40px" }}
                height={{ base: "36px", md: "40px" }}
                p={0}
                bg="green.400"
                _hover={{ 
                  bg: "green.500", 
                  transform: "scale(1.1) translateX(2px)", 
                  boxShadow: "0 0 15px rgba(57, 161, 105, 0.6)" 
                }}
                _active={{ bg: "green.600", transform: "scale(0.95)" }}
                transition="all 0.3s ease"
                boxShadow="0 2px 10px rgba(0,0,0,0.15)"
                aria-label="Next"
              >
                <ChevronRightIcon boxSize={{ base: 4, md: 6 }} color="white" />
              </Button>
            </Box>
            
            {/* Add CSS for fullcalendar custom styling */}
            <style>
              {`
                .fc-event {
                  cursor: pointer;
                  transition: all 0.2s ease;
                  overflow: hidden;
                }
                .exciting-session-event {
                  border-radius: 6px !important;
                  border-left: 4px solid #F6E05E !important;
                  box-shadow: 0 3px 10px rgba(0,0,0,0.15) !important;
                }
                .exciting-session-event:hover {
                  transform: scale(1.02);
                  box-shadow: 0 5px 15px rgba(0,0,0,0.2) !important;
                  z-index: 100 !important;
                }
                .fc-event-title {
                  font-weight: bold;
                }
                .fc-timegrid-event-harness {
                  z-index: 5;
                }
                ${is24HourView ? `
                /* 24-hour specific styles */
                .fc-timegrid-slot {
                  height: 25px !important;
                  min-height: 25px !important;
                }
                .fc-timegrid-slot-lane {
                  height: 25px !important;
                }
                .fc-timegrid-col.fc-day {
                  min-height: 600px !important;
                }
                /* Night hours styling (midnight to 8am and 10pm to midnight) */
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="00:"],
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="01:"],
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="02:"],
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="03:"],
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="04:"],
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="05:"],
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="06:"],
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="07:"],
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="22:"],
                .fc-timegrid-slot.fc-timegrid-slot-lane[data-time^="23:"] {
                  background-color: rgba(0, 0, 0, 0.03);
                }
                /* Current time indicator more visible */
                .fc-timegrid-now-indicator-line {
                  border-color: #E53E3E !important;
                  border-width: 2px !important;
                }
                .fc-timegrid-now-indicator-arrow {
                  border-color: #E53E3E !important;
                  border-width: 5px !important;
                }
                ` : ''}
                /* Business hours highlight */
                .fc-non-business {
                  background: rgba(0, 0, 0, 0.025);
                }
                @media (max-width: 768px) {
                  .fc-event {
                    font-size: 0.7em;
                  }
                  ${is24HourView ? `
                  /* Mobile 24-hour view */
                  .fc-timegrid-slot {
                    height: 20px !important;
                    min-height: 20px !important;
                  }
                  ` : ''}
                }
              `}
            </style>
            
            <FullCalendar
              ref={calendarRef}
              plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
              initialView={calendarView}
              initialDate={initialDate}
              datesSet={handleDatesSet}
              events={events}
              
              headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }}

              // Add business hours highlighting
              businessHours={{
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All days
                startTime: '08:00',
                endTime: '22:00'
              }}
              
              // Set specific height when in 24-hour view to show all hours
              height={is24HourView ? "1400px" : "auto"}
              expandRows={true}
              stickyHeaderDates={true}
              
              // Better mobile settings for week view
              views={{
                timeGridDay: {
                  titleFormat: { month: 'short', day: 'numeric' },
                  dayHeaderFormat: { weekday: 'long' },
                  // Keep 30min slots even in 24hr view for better detail
                  slotDuration: '00:30:00',
                  slotLabelInterval: '01:00:00'
                },
                timeGridWeek: {
                  titleFormat: { month: 'short', year: 'numeric' },
                  dayHeaderFormat: { weekday: 'short', day: 'numeric' },
                  // Use 1hr slots for consistency in both views
                  slotDuration: '01:00:00',
                  slotLabelInterval: '01:00:00'
                },
                dayGridMonth: {
                  dayHeaderFormat: { weekday: 'narrow' }
                }
              }}
              
              // Customize slot label format
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: 'short'
              }}
              
              slotMinTime={is24HourView ? "00:00:00" : "08:00:00"} // Toggle between full day and business hours
              slotMaxTime={is24HourView ? "24:00:00" : "22:00:00"} // Toggle between full day and business hours
              allDaySlot={false}
              nowIndicator={true}
              eventContent={renderEventContent}
              eventClick={handleEventClick}
              selectable={false}
              
              // Improve display on small screens
              eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: 'short'
              }}
            />
          </Box>
          
          {/* Only show "Your Bookings" section when authenticated */}
          {isAuthenticated && (
            <Box mt={{ base: 4, md: 6 }}>
              <Heading size={{ base: "sm", md: "md" }} mb={{ base: 2, md: 4 }}>
                Your Bookings
              </Heading>
              
              {bookingsLoading ? (
                <Text fontSize={{ base: "sm", md: "md" }}>Loading your bookings...</Text>
              ) : !bookingsData?.bookingsByClientId?.length ? (
                <Text fontSize={{ base: "sm", md: "md" }}>You don't have any bookings yet.</Text>
              ) : (
                <VStack spacing={{ base: 2, md: 4 }} align="stretch">
                  {bookingsData.bookingsByClientId.map((booking: any) => {
                    const isPending = booking.status === 'PENDING';
                    const StatusIcon = getStatusIcon(booking.status);
                    
                    return (
                      <Box 
                        key={booking.id} 
                        p={{ base: 3, md: 4 }} 
                        borderWidth="1px" 
                        borderRadius="md"
                        borderLeftWidth="4px"
                        borderLeftColor={getBookingColor(booking.status)}
                        fontSize={{ base: "sm", md: "md" }}
                        position="relative"
                        transition="all 0.2s"
                        _hover={{
                          boxShadow: "md",
                          transform: "translateY(-2px)"
                        }}
                        onClick={() => {
                          setSelectedBooking(booking);
                          onBookingDetailOpen();
                        }}
                        cursor="pointer"
                        // Add animation for pending bookings
                        animation={isPending ? `${pulseAnimation} 3s infinite` : undefined}
                      >
                        {/* Add a more prominent status indicator for mobile and desktop */}
                        {isPending && (
                          <Badge 
                            position="absolute" 
                            top={2} 
                            right={2}
                            colorScheme="yellow"
                            variant="solid"
                            px={2}
                            py={1}
                            borderRadius="full"
                            display="flex"
                            alignItems="center"
                            gap={1}
                          >
                            <Icon as={TimeIcon} boxSize={3} />
                            Pending
                          </Badge>
                        )}
                        
                        <Heading size={{ base: "xs", md: "sm" }}>{booking.session.name}</Heading>
                        <Text>
                          Date: {new Date(booking.session.startTime).toLocaleDateString()}
                        </Text>
                        <Text>
                          Time: {new Date(booking.session.startTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} - {new Date(booking.session.endTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                        </Text>
                        <HStack mt={2}>
                          <Icon 
                            as={StatusIcon} 
                            color={
                              booking.status === 'CONFIRMED' ? 'blue.500' : 
                              booking.status === 'COMPLETED' ? 'green.500' :
                              booking.status === 'CANCELLED' ? 'red.500' : 'yellow.500'
                            }
                          />
                          <Text>Status:</Text> 
                          <Badge colorScheme={
                            booking.status === 'CONFIRMED' ? 'blue' : 
                            booking.status === 'COMPLETED' ? 'green' :
                            booking.status === 'CANCELLED' ? 'red' : 'yellow'
                          }>{booking.status}</Badge>
                        </HStack>
                        <HStack mt={1}>
                          <Text>Payment:</Text>
                          <Badge colorScheme={
                            booking.payment?.status === 'PAID' ? 'green' : 
                            booking.payment?.status === 'PENDING' ? 'yellow' : 'red'
                          }>{booking.payment?.status}</Badge>
                        </HStack>
                      </Box>
                    );
                  })}
                </VStack>
              )}
            </Box>
          )}
        </VStack>
      </Container>

      {/* Update the Session modal to be more attractive */}
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: "sm", md: "md" }}>
        <ModalOverlay 
          bg="rgba(0,0,0,0.7)"
          backdropFilter="blur(6px)"
        />
        <ModalContent 
          mx={{ base: 3, md: 0 }}
          borderRadius="xl"
          overflow="hidden"
          boxShadow="0 10px 30px -5px rgba(0, 0, 0, 0.3)"
          bg="white"
          position="relative"
          _before={{
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '5px',
            background: 'linear-gradient(90deg, #38A169, #48BB78)',
            zIndex: 0
          }}
        >
          <ModalHeader 
            fontSize={{ base: "lg", md: "xl" }}
            py={4}
            bg="green.50"
            color="green.700"
          >
            {bookingData?.createBooking ? "Booking Confirmed" : "Session Details"}
          </ModalHeader>
          <ModalBody fontSize={{ base: "sm", md: "md" }} py={6}>
            {selectedSession && (
              <>
                {bookingData?.createBooking ? (
                  // Show booking confirmation with enhanced styling
                  <VStack spacing={5} align="stretch">
                    <Box 
                      p={4} 
                      bg="green.50" 
                      borderRadius="md" 
                      textAlign="center"
                      boxShadow="inner"
                    >
                      <Icon as={CheckIcon} w={10} h={10} color="green.500" mb={2} />
                      <Heading size="md" color="green.700">Successfully Booked!</Heading>
                    </Box>
                    
                    <Box p={4} borderWidth="1px" borderRadius="md">
                      <Text fontWeight="bold" mb={2}>Booking Details:</Text>
                      <HStack mb={1}>
                        <Text color="gray.500">Booking ID:</Text>
                        <Text fontWeight="medium">{bookingData.createBooking.id.substring(0, 8)}</Text>
                      </HStack>
                      <HStack mb={1}>
                        <Text color="gray.500">Status:</Text>
                        <Badge colorScheme="green">{bookingData.createBooking.status}</Badge>
                      </HStack>
                      <HStack mb={1}>
                        <Text color="gray.500">Session:</Text>
                        <Text fontWeight="medium">{bookingData.createBooking.session.name}</Text>
                      </HStack>
                      <HStack mb={1}>
                        <Text color="gray.500">Time:</Text>
                        <Text>
                          {new Date(bookingData.createBooking.session.startTime).toLocaleString(undefined, {
                            weekday: 'short',
                            day: 'numeric',
                            month: 'short',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </Text>
                      </HStack>
                    </Box>
                    
                    <Box p={4} bg="blue.50" borderRadius="md">
                      <Text fontSize="sm">
                        We've sent confirmation details to your email address. Please arrive a few minutes before the session starts.
                      </Text>
                    </Box>
                  </VStack>
                ) : (
                  // Show session details with enhanced styling
                  <>
                    <Box mb={5}>
                      <Heading size="md" mb={3} color="green.600">
                        {selectedSession.name}
                      </Heading>
                      
                      {selectedSession.description && (
                        <Box 
                          p={4} 
                          bg="gray.50" 
                          borderRadius="md" 
                          mb={4}
                          boxShadow="sm"
                        >
                          <Text>{selectedSession.description}</Text>
                        </Box>
                      )}
                      
                      <HStack 
                        spacing={4}
                        bg="green.50"
                        p={3}
                        borderRadius="md"
                        boxShadow="sm"
                        mb={2}
                      >
                        <Icon as={TimeIcon} color="green.500" boxSize={5} />
                        <Box>
                          <Text fontWeight="bold" fontSize="sm" color="gray.700">
                            {new Date(selectedSession.startTime).toLocaleDateString(undefined, {
                              weekday: 'long',
                              day: 'numeric',
                              month: 'long'
                            })}
                          </Text>
                          <Text fontSize="sm">
                            {new Date(selectedSession.startTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} - {new Date(selectedSession.endTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                          </Text>
                        </Box>
                      </HStack>
                      
                      {selectedSession.maxParticipants && (
                        <HStack 
                          spacing={4}
                          bg="blue.50"
                          p={3}
                          borderRadius="md"
                          boxShadow="sm"
                        >
                          <Icon as={InfoIcon} color="blue.500" boxSize={5} />
                          <Text>Limited to {selectedSession.maxParticipants} participants</Text>
                        </HStack>
                      )}
                    </Box>
                    
                    {isAuthenticated ? (
                      <Box 
                        p={4} 
                        borderWidth="1px" 
                        borderRadius="md"
                        borderColor="green.200"
                        bg="green.50"
                        boxShadow="sm"
                      >
                        <Heading size="sm" mb={3} color="green.700">Ready to Book?</Heading>
                        <Text mb={2}>Session Price: <Badge colorScheme="green" fontSize="md">${selectedSession.type?.price || 0}</Badge></Text>
                        <Text fontSize="sm">
                          You'll be booking as: <Text as="span" fontWeight="bold">{user?.fName} {user?.lName}</Text> ({user?.email})
                        </Text>
                      </Box>
                    ) : (
                      <Box 
                        p={4} 
                        borderWidth="1px" 
                        borderRadius="md"
                        borderColor="blue.200"
                        bg="blue.50"
                        boxShadow="sm"
                      >
                        <Heading size="sm" mb={2} color="blue.700">Interested in this session?</Heading>
                        <Text fontSize="sm">
                          Log in to book your spot and join this exciting session.
                        </Text>
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {bookingData?.createBooking ? (
              <Button 
                colorScheme="green" 
                onClick={onClose} 
                size={{ base: "sm", md: "md" }} 
                width={{ base: "full", md: "auto" }}
                boxShadow="md"
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg"
                }}
                transition="all 0.2s"
              >
                Close
              </Button>
            ) : (
              <>
                <Button 
                  colorScheme="green" 
                  mr={3} 
                  onClick={handleBookSession}
                  isLoading={bookingLoading}
                  loadingText="Processing..."
                  size={{ base: "sm", md: "md" }}
                  flex={{ base: 1, md: "unset" }}
                  boxShadow="md"
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg",
                    bg: "green.500"
                  }}
                  transition="all 0.2s"
                >
                  {isAuthenticated ? "Confirm Booking" : "Book This Session"}
                </Button>
                <Button 
                  variant="outline" 
                  colorScheme="gray"
                  onClick={onClose}
                  size={{ base: "sm", md: "md" }}
                  flex={{ base: 1, md: "unset" }}
                >
                  Close
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Booking details modal remains unchanged */}
      <Modal isOpen={isBookingDetailOpen} onClose={onBookingDetailClose} size={{ base: "sm", md: "md" }}>
        <ModalOverlay />
        <ModalContent mx={{ base: 3, md: 0 }}>
          <ModalHeader fontSize={{ base: "lg", md: "xl" }}>Booking Details</ModalHeader>
          <ModalBody fontSize={{ base: "sm", md: "md" }}>
            {selectedBooking && (
              <VStack spacing={4} align="stretch">
                <Heading size="md">{selectedBooking.session.name}</Heading>
                
                <Box p={3} borderWidth="1px" borderRadius="md" bg="gray.50">
                  <Text fontWeight="bold">Date & Time:</Text>
                  <Text>
                    {new Date(selectedBooking.session.startTime).toLocaleDateString()} at{' '}
                    {new Date(selectedBooking.session.startTime).toLocaleTimeString()} - {' '}
                    {new Date(selectedBooking.session.endTime).toLocaleTimeString()}
                  </Text>
                </Box>
                
                <Box p={3} borderWidth="1px" borderRadius="md" bg="gray.50">
                  <Text fontWeight="bold">Status:</Text>
                  <Badge colorScheme={
                    selectedBooking.status === 'CONFIRMED' ? 'blue' : 
                    selectedBooking.status === 'COMPLETED' ? 'green' :
                    selectedBooking.status === 'CANCELLED' ? 'red' : 'yellow'
                  } fontSize="0.9em" px={2} py={1}>
                    {selectedBooking.status}
                  </Badge>
                </Box>
                
                {selectedBooking.payment && (
                  <Box p={3} borderWidth="1px" borderRadius="md" bg="gray.50">
                    <Text fontWeight="bold">Payment:</Text>
                    <Text>Method: {selectedBooking.payment.method}</Text>
                    <Text>Amount: {selectedBooking.payment.amount} {selectedBooking.payment.currency}</Text>
                    <Text>Status: 
                      <Badge ml={2} colorScheme={
                        selectedBooking.payment.status === 'PAID' ? 'green' : 
                        selectedBooking.payment.status === 'PENDING' ? 'yellow' : 'red'
                      }>
                        {selectedBooking.payment.status}
                      </Badge>
                    </Text>
                  </Box>
                )}
                
                {selectedBooking.session.type && (
                  <Box p={3} borderWidth="1px" borderRadius="md" bg="gray.50">
                    <Text fontWeight="bold">Session Type:</Text>
                    <Text>{selectedBooking.session.type.name} ({selectedBooking.session.type.format})</Text>
                  </Box>
                )}
                
                {selectedBooking.status !== 'CANCELLED' && selectedBooking.status !== 'COMPLETED' && (
                  <Box mt={4}>
                    <Heading size="sm" mb={2}>Cancel Booking</Heading>
                    <FormControl isRequired>
                      <FormLabel>Reason for cancellation</FormLabel>
                      <Input 
                        value={cancellationReason}
                        onChange={(e) => setCancellationReason(e.target.value)}
                        placeholder="Please provide a reason"
                      />
                    </FormControl>
                  </Box>
                )}
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            {selectedBooking && selectedBooking.status !== 'CANCELLED' && selectedBooking.status !== 'COMPLETED' && (
              <Button 
                colorScheme="red" 
                mr={3} 
                onClick={handleCancelBooking}
                isLoading={cancelLoading}
                isDisabled={!cancellationReason.trim()}
                size={{ base: "sm", md: "md" }}
                flex={{ base: 1, md: "unset" }}
              >
                Cancel Booking
              </Button>
            )}
            <Button 
              variant="ghost" 
              onClick={onBookingDetailClose}
              size={{ base: "sm", md: "md" }}
              flex={{ base: 1, md: "unset" }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <FooterWithFourColumns />
    </>
  );
}; 