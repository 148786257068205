import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Heading,
  VStack,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  HStack,
  Badge,
} from "@chakra-ui/react";
import { FiEdit2, FiTrash2, FiPlus } from "react-icons/fi";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { NavbarWithCallToAction } from '../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const GET_SESSION_TYPES = gql`
  query GetSessionTypes {
    sessionTypes {
      id
      name
      description
      format
      duration
      price
      color
    }
  }
`;

const UPDATE_SESSION_TYPE = gql`
  mutation UpdateSessionType($id: ID!, $input: SessionTypeInput!) {
    updateSessionType(id: $id, input: $input) {
      id
      name
      description
      format
      duration
      price
      color
    }
  }
`;

const DELETE_SESSION_TYPE = gql`
  mutation DeleteSessionType($id: ID!) {
    deleteSessionType(id: $id)
  }
`;

interface SessionTypeFormData {
  id?: string;
  name: string;
  description: string;
  format: "INDIVIDUAL" | "GROUP";
  duration: number;
  price: number;
  color: string;
}

export const SessionTypesPage = () => {
  const { isAuthenticated } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const { data } = useQuery(GET_SESSION_TYPES);
  const [deleteSessionType] = useMutation(DELETE_SESSION_TYPE);

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this session type?")) {
      try {
        await deleteSessionType({
          variables: { id },
          refetchQueries: ["GetSessionTypes"],
        });
        toast({
          title: "Session type deleted",
          status: "success",
          duration: 3000,
        });
      } catch (error) {
        toast({
          title: "Error deleting session type",
          status: "error",
          duration: 5000,
        });
      }
    }
  };

  if (!isAuthenticated) {
    return <Box p={8}>Please log in to access this page.</Box>;
  }

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          <HStack justify="space-between">
            <Heading size="lg">Session Types</Heading>
            <Button 
              leftIcon={<FiPlus />} 
              colorScheme="green" 
              onClick={() => navigate('/sessions/types/create')}
            >
              New Session Type
            </Button>
          </HStack>

          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Format</Th>
                <Th>Duration</Th>
                <Th>Price</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.sessionTypes.map((type: SessionTypeFormData) => (
                <Tr key={type.id}>
                  <Td>
                    <HStack spacing={3} align="center">
                      {type.color && (
                        <Box 
                          w="3" 
                          h="3" 
                          borderRadius="full" 
                          bg={type.color} 
                          flexShrink={0}
                        />
                      )}
                      <Box>{type.name}</Box>
                    </HStack>
                  </Td>
                  <Td>
                    <Badge
                      colorScheme={
                        type.format === "INDIVIDUAL" ? "green" : "blue"
                      }
                    >
                      {type.format}
                    </Badge>
                  </Td>
                  <Td>{type.duration} min</Td>
                  <Td>${type.price}</Td>
                  <Td>
                    <HStack spacing={2}>
                      <IconButton
                        icon={<FiEdit2 />}
                        aria-label="Edit"
                        size="sm"
                        onClick={() => navigate(`/sessions/edit/${type.id}`)}
                      />
                      <IconButton
                        icon={<FiTrash2 />}
                        aria-label="Delete"
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(type.id!)}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </>
  );
};
