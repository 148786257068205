import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  OrderedList,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Textarea,
  Select,
  Checkbox,
  useDisclosure,
  Link,
  Image,
  Code,
  Center,
  Spinner,
  HStack,
  Badge,
} from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { LoginModal } from '../components/LoginModal';
import SignaturePad from 'react-signature-canvas';
import type SignatureCanvas from 'react-signature-canvas';

// Add this with the other GraphQL definitions at the top
const GET_FILE = gql`
  query GetFile($hash: String!) {
    getFile(hash: $hash)
  }
`;

// Add the mutation todo chagne to use update client
const CREATE_CLIENT = gql`
  mutation CreateClient($input: ClientInput!) {
    createClient(input: $input) {
      id
      fName
      lName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;

// Update the mutation to use encrypted upload
const UPLOAD_ENCRYPTED_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`;

// Add the new mutation at the top with other GraphQL definitions
const UPDATE_CLIENT = gql`
  mutation UpdateClient($id: ID!, $input: UpdateClientInput!) {
    updateClient(id: $id, input: $input) {
      id
      fName
      lName
      email
      phoneNumber
      encryptedSignatureIpfsUrl
      createdAt
      updatedAt
    }
  }
`;

// Add phone input styles
const phoneInputStyle = {
  width: '100%',
  padding: '8px',
  borderRadius: '6px',
  border: '1px solid #E2E8F0',
  fontSize: '16px'
};


const Agreement = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { user, isAuthenticated, refreshAuth } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const signaturePadRef = useRef<SignatureCanvas>(null);

  const [formData, setFormData] = useState({
    firstName: '',
    familyName: '',
    email: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postcode: '',
    skills: '',
    interests: '',
    contribution: '',
    needs: '',
    date: new Date().toISOString().split('T')[0],
    agreeToTerms: false,
    signature: '',
    encryptedSignatureIpfsUrl: '',
  });

  // Add this useEffect at the top of other useEffects
  useEffect(() => {
    const checkUser = setInterval(() => {
      if (user?.encryptedSignatureIpfsUrl) {
        console.log('User already signed agreement, redirecting to products...');
        navigate('/products');
      }
    }, 1000);

    return () => clearInterval(checkUser);
  }, [user, navigate]);

  // Update this useEffect to populate form with user data
  useEffect(() => {
    if (user) {
      setFormData(prev => ({
        ...prev,
        firstName: user.fName || '',
        familyName: user.lName || '',
        email: user.email || '',
        phoneNumber: user.phoneNumber || '',
        // If user has a default shipping address, use it
        address: user.shippingAddresses?.[0]?.address?.street || '',
        city: user.shippingAddresses?.[0]?.address?.city || '',
        state: user.shippingAddresses?.[0]?.address?.state || '',
        country: user.shippingAddresses?.[0]?.address?.country || '',
        postcode: user.shippingAddresses?.[0]?.address?.postcode || '',
        // If user already has a signature, use it
        encryptedSignatureIpfsUrl: user.encryptedSignatureIpfsUrl || ''
      }));

      // Log the population of form data
      console.log('Populating form with user data:', user);
    }
  }, [user]);

  // Add debug logging
  useEffect(() => {
    console.log('Current auth state:', { isAuthenticated, user });
    console.log('Current form data:', formData);
  }, [isAuthenticated, user, formData]);

  // Update the mutation hook - rename this one to uploadLoading
  const [createClient, { loading: submitLoading }] = useMutation(CREATE_CLIENT);
  const [uploadFile, { loading: uploadLoading }] = useMutation(UPLOAD_ENCRYPTED_FILE);
  const [getFile, { loading: fileLoading, data: fileContent }] = useLazyQuery(GET_FILE);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [uploadResult, setUploadResult] = useState<string | null>(null);

  // Add loading states - keep these names
  const [isEncrypting, setIsEncrypting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDecrypting, setIsDecrypting] = useState(false);

  // Add the new mutation hook with the existing ones
  const [updateClient] = useMutation(UPDATE_CLIENT);

  // Auto-populate email when available
  useEffect(() => {
    if (user?.email) {
      setFormData(prev => ({
        ...prev,
        email: user?.email
      }));
    }
  }, [user?.email]);

  // Add this useEffect to refresh auth when component mounts
  useEffect(() => {
    refreshAuth();
  }, [refreshAuth]);

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
      setFormData(prev => ({ ...prev, signature: '' }));
    }
  };

  const saveSignature = async () => {
    if (signaturePadRef.current) {
      if (signaturePadRef.current.isEmpty()) {
        toast({
          title: "Empty Signature",
          description: "Please sign before confirming",
          status: "warning",
          duration: 3000,
        });
        return;
      }

      try {
        // Show encrypting state
        setIsEncrypting(true);
        toast({
          title: "Processing Signature",
          description: "Encrypting your signature...",
          status: "info",
          duration: null,
        });

        // Convert signature to File
        const dataURL = signaturePadRef.current.toDataURL();
        const base64Data = dataURL.split(',')[1];
        const blob = atob(base64Data);
        const array = new Uint8Array(blob.length);
        for (let i = 0; i < blob.length; i++) {
          array[i] = blob.charCodeAt(i);
        }
        const file = new File([array], 'signature.png', { type: 'image/png' });

        // Show uploading state
        setIsEncrypting(false);
        setIsUploading(true);
        toast.closeAll();
        toast({
          title: "Processing Signature",
          description: "Uploading encrypted signature...",
          status: "info",
          duration: null,
        });

        // Upload using the encrypted mutation
        const { data } = await uploadFile({
          variables: { file },
          context: {
            headers: {
              'apollo-require-preflight': 'true',
              'x-apollo-operation-name': 'UploadFile'
            }
          }
        });

        console.log('Upload response:', data);
        const hash = data.uploadFile;
        setUploadResult(hash);
        setFormData(prev => ({ ...prev, encryptedSignatureIpfsUrl: hash }));

        // Add quick save of signature to client profile
        try {
          await updateClient({
            variables: {
              id: user.id,
              input: {
                encryptedSignatureIpfsUrl: hash
              }
            }
          });

          toast({
            title: "Signature Saved",
            description: "Your signature has been saved to your profile",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } catch (updateError) {
          console.error('Error updating client:', updateError);
          // Continue with the flow even if update fails
        }

        // Show decrypting state
        setIsUploading(false);
        setIsDecrypting(true);
        toast.closeAll();
        toast({
          title: "Processing Signature",
          description: "Decrypting signature for preview...",
          status: "info",
          duration: null,
        });

        // Load the encrypted file for preview
        await getFile({
          variables: { hash }
        });

        // All done!
        setIsDecrypting(false);
        toast.closeAll();
        toast({
          title: "Success!",
          description: "Signature encrypted and stored securely",
          status: "success",
          duration: 5000,
        });

      } catch (error) {
        // Reset all loading states
        setIsEncrypting(false);
        setIsUploading(false);
        setIsDecrypting(false);
        
        console.error('Upload error:', error);
        toast.closeAll();
        toast({
          title: "Upload failed",
          description: error instanceof Error ? error.message : "Unknown error occurred",
          status: "error",
          duration: 5000,
        });
      }
    }
  };

  // Update the button to show loading states
  const getButtonText = () => {
    if (isEncrypting) return "Encrypting Signature...";
    if (isUploading) return "Uploading Signature...";
    if (isDecrypting) return "Decrypting Preview...";
    return "Confirm My Signature";
  };

  // Update the preview renderer to handle encrypted files
  const renderSignaturePreview = () => {
    if (!uploadResult) return null;

    return (
      <Box mt={4} p={4} borderWidth={1} borderRadius="md">
        <Text fontWeight="bold" mb={2}>Encrypted Signature Preview:</Text>
        {fileLoading ? (
          <Center p={4}>
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <Image 
              src={`data:image/png;base64,${fileContent?.getFile}`}
              alt="Signature preview" 
              maxH="400px" 
              objectFit="contain"
            />
            <Box mt={2}>
              <Text fontWeight="bold">Encrypted File Hash:</Text>
              <Code display="block" whiteSpace="pre" p={2} mt={2}>
                {uploadResult}
                <Button
                  as="a"
                  href={`/file/${uploadResult}`}
                  target="_blank"
                  size="sm"
                  ml={2}
                >
                  View in new tab
                </Button>
              </Code>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.agreeToTerms) {
      toast({
        title: "Agreement Required",
        description: "Please agree to the terms and conditions",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!formData.firstName || !formData.familyName || !formData.email || !formData.phoneNumber) {
      toast({
        title: "Missing Information",
        description: "Please fill in all required fields including phone number",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!formData.encryptedSignatureIpfsUrl) {
      toast({
        title: "Signature Required",
        description: "Please sign the agreement",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const { data } = await updateClient({
        variables: {
          id: user.id,
          input: {
            fName: formData.firstName,
            lName: formData.familyName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            encryptedSignatureIpfsUrl: formData.encryptedSignatureIpfsUrl,
          }
        }
      });

      toast({
        title: "Agreement Signed",
        description: "Thank you for signing the agreement",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Redirect to the certificate page
      navigate(`/membership-certificate/${data.updateClient.id}`);
    } catch (error) {
      console.error('Submit error:', error);
      toast({
        title: "Error",
        description: "There was an error submitting your agreement. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prev => ({ ...prev, [name]: checked }));
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Box bg="linear-gradient(180deg, #eaf5f2 0%, #f7fbfa 100%)">
        {/* Add gradient background overlay */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          sx={{
            background: `
              radial-gradient(circle at 0% 0%, rgba(51, 87, 134, 0.1) 0%, transparent 50%),
              radial-gradient(circle at 100% 0%, rgba(57, 161, 105, 0.1) 0%, transparent 50%),
              radial-gradient(circle at 0% 100%, rgba(234, 245, 242, 0.2) 0%, transparent 50%),
              radial-gradient(circle at 100% 100%, rgba(51, 87, 134, 0.1) 0%, transparent 50%)
            `,
            filter: 'blur(60px)',
          }}
        />

        <Container maxW="container.lg" py={10} position="relative" zIndex={1}>
          <VStack spacing={8} align="stretch">
            <Box
              bg="white"
              p={8}
              borderRadius="xl"
              boxShadow="0 4px 20px rgba(51, 87, 134, 0.08)"
              border="1px solid"
              borderColor="rgba(51, 87, 134, 0.1)"
              transition="all 0.3s ease"
              _hover={{
                boxShadow: "0 6px 30px rgba(51, 87, 134, 0.12)",
                transform: "translateY(-2px)"
              }}
            >
              {/* Add glowing badge */}
              <Badge
                px={4}
                py={2}
                bg="rgba(51, 87, 134, 0.1)"
                color="#335786"
                borderRadius="full"
                fontSize="sm"
                fontWeight="500"
                position="relative"
                display="block"
                width="fit-content"
                mx="auto"
                mb={6}
                _before={{
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: 'full',
                  background: `
                    linear-gradient(
                      90deg, 
                      rgba(51, 87, 134, 0.4) 0%,
                      rgba(57, 161, 105, 0.4) 100%
                    )
                  `,
                  filter: 'blur(8px)',
                  opacity: 0.7,
                  transform: 'scale(1.05)',
                  zIndex: -1,
                  animation: 'pulse 2s infinite'
                }}
              >
                Member Agreement
              </Badge>

              {/* Enhance existing heading styles */}
              <Heading
                as="h1"
                size="2xl"
                textAlign="center"
                color="#335786"
                mb={6}
                transition="color 0.3s ease"
                _hover={{ color: "#39a169" }}
              >
                Life Essentials Club Member Agreement
              </Heading>

              {/* Style form inputs */}
              <style>{`
                .form-control input,
                .form-control select {
                  border-color: rgba(51, 87, 134, 0.2);
                  transition: all 0.3s ease;
                }
                .form-control input:hover,
                .form-control select:hover {
                  border-color: #39a169;
                }
                .form-control input:focus,
                .form-control select:focus {
                  border-color: #39a169;
                  box-shadow: 0 0 0 1px #39a169;
                }
                .signature-canvas {
                  background: white;
                  border: 2px solid rgba(51, 87, 134, 0.1) !important;
                  border-radius: 8px;
                  transition: all 0.3s ease;
                }
                .signature-canvas:hover {
                  border-color: rgba(57, 161, 105, 0.3) !important;
                }
                @keyframes pulse {
                  0% { transform: scale(1.05); opacity: 0.7; }
                  50% { transform: scale(1.15); opacity: 0.4; }
                  100% { transform: scale(1.05); opacity: 0.7; }
                }
              `}</style>

              <Text mb={6} fontSize="lg" fontStyle="italic" color="gray.700" textAlign="center">
                Purpose: The Life Essentials Club is founded on the belief that as a social species, we thrive
                through cooperation and collaboration within our ecosystem. Our mission is to collectively
                deliver life essentials to all people; fostering a sustainable, supportive, and equitable
                community.
              </Text>

              <Text mb={6}>
                Welcome to the Life Essentials Club! By joining, you're becoming part of a community dedicated to supporting each other and ensuring everyone has access to life's essentials.
              </Text>

              <Box mb={6}>
                <Heading as="h2" size="md" mb={4}>
                  Key Membership Points:
                </Heading>
                <UnorderedList spacing={3}>
                  <ListItem>We support each other to ensure everyone has access to life essentials</ListItem>
                  <ListItem>Members participate in at least one Club activity monthly</ListItem>
                  <ListItem>We share skills, resources, and support within our community</ListItem>
                  <ListItem>We practice environmental stewardship and sustainable living</ListItem>
                  <ListItem>We resolve conflicts through respectful dialogue and mediation</ListItem>
                </UnorderedList>
              </Box>

              <Box mb={6}>
                <Text>
                  For the complete membership rules and details, please read our{" "}
                  <Link
                    href="/governing-rules"
                    color="blue.500"
                    fontWeight="bold"
                    target="_blank"
                    rel="noopener noreferrer"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    Governing Rules
                  </Link>
                  {" "}(opens in new tab).
                </Text>
              </Box>

              {!isAuthenticated ? (
                <Box textAlign="center" py={6}>
                  <Button
                    display="inline-flex"
                    alignItems="center"
                    gap={2}
                    px={6}
                    py={3}
                    minH="3rem"
                    fontSize="md"
                    fontWeight="500"
                    color="#2F855A"
                    bg="transparent"
                    border="2px solid"
                    borderColor="current"
                    borderRadius="md"
                    transition="all 0.3s ease"
                    onClick={onOpen}
                    _hover={{
                      color: "#276749",
                      bg: "#F0FFF4",
                      transform: "translateY(-2px)",
                      boxShadow: "4px 4px #C6F6D5",
                      textDecoration: "none"
                    }}
                    _active={{
                      transform: "translateY(0)",
                      boxShadow: "none"
                    }}
                    _focus={{
                      outline: "4px solid #9AE6B4",
                      outlineOffset: "-1px"
                    }}
                  >
                    Sign Agreement
                    <Box as="svg"
                      h={5}
                      w={5}
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      transition="transform 0.3s ease"
                      _groupHover={{ transform: "translateX(4px)" }}
                    >
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                    </Box>
                  </Button>
                  <Text mt={4} fontSize="sm" color="gray.600">
                    Please login to sign the agreement
                  </Text>
                </Box>
              ) : (
                <Box
                  as="form"
                  onSubmit={handleSubmit}
                  bg="#f8faf9"
                  p={8}
                  borderRadius="lg"
                  boxShadow="0 2px 10px rgba(0,0,0,0.05)"
                  transition="all 0.3s ease"
                  _hover={{
                    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                    transform: "translateY(-2px)"
                  }}
                >
                  <Heading as="h3" size="md" mb={6}>
                    Sign Agreement
                  </Heading>

                  <VStack spacing={4}>
                    <FormControl isRequired>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="Enter your first name"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Family Name</FormLabel>
                      <Input
                        name="familyName"
                        value={formData.familyName}
                        onChange={handleChange}
                        placeholder="Enter your family name"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Email</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        value={formData.email}
                        isReadOnly
                        bg="gray.50"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Phone Number</FormLabel>
                      <PhoneInput
                        international
                        defaultCountry="AU"
                        value={formData.phoneNumber}
                        onChange={(value) => {
                          console.log('Phone input value:', value);
                          setFormData(prev => ({ ...prev, phoneNumber: value || '' }))
                        }}
                        placeholder="Enter phone number"
                        style={phoneInputStyle}
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Date</FormLabel>
                      <Input
                        name="date"
                        type="date"
                        value={new Date().toISOString().split('T')[0]}
                        isReadOnly
                        bg="gray.50"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Your Personal Signature</FormLabel>
                      <Text mb={3} fontSize="sm" color="gray.600">
                        Please sign below to confirm your commitment to our community. This signature is a digital representation of recognition of your sign of nature as a living being (not your legal fiction).
                      </Text>
                      <Box
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="md"
                        p={4}
                        bg="white"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <SignaturePad
                          ref={signaturePadRef}
                          canvasProps={{
                            className: 'signature-canvas',
                            style: {
                              width: '100%',
                              height: '200px',
                              border: '1px solid #E2E8F0',
                              borderRadius: '6px',
                              backgroundColor: '#FFFFFF'
                            }
                          }}
                        />
                        <Box mt={3} display="flex" gap={2} justifyContent="center">
                          <Button 
                            size="sm" 
                            variant="outline" 
                            onClick={() => {
                              clearSignature();
                              setUploadResult(null);
                            }}
                            disabled={isEncrypting || isUploading || isDecrypting}
                            color="#335786"
                            borderColor="#335786"
                            _hover={{
                              bg: "rgba(51, 87, 134, 0.05)",
                              transform: "translateY(-1px)"
                            }}
                            transition="all 0.3s ease"
                          >
                            Start Over
                          </Button>
                          {!uploadResult && (
                            <Button 
                              size="sm" 
                              colorScheme="green" 
                              onClick={saveSignature}
                              isLoading={isEncrypting || isUploading || isDecrypting}
                              loadingText={getButtonText()}
                              disabled={isEncrypting || isUploading || isDecrypting}
                              bg="#39a169"
                              color="white"
                              _hover={{
                                bg: "#335786",
                                transform: "translateY(-1px)",
                                boxShadow: "0 4px 12px rgba(51, 87, 134, 0.2)"
                              }}
                              transition="all 0.3s ease"
                            >
                              {getButtonText()}
                            </Button>
                          )}
                        </Box>
                      </Box>
                      <Text mt={2} fontSize="sm" color="gray.500" fontStyle="italic">
                        By signing above, you're joining hands with our community in creating positive change.
                      </Text>
                    </FormControl>

                    <FormControl isRequired>
                      <Checkbox
                        name="agreeToTerms"
                        isChecked={formData.agreeToTerms}
                        onChange={handleCheckbox}
                        mb={4}
                        colorScheme="green"
                        sx={{
                          '.chakra-checkbox__control': {
                            borderColor: 'rgba(51, 87, 134, 0.3)',
                            _checked: {
                              bg: '#39a169',
                              borderColor: '#39a169',
                            },
                            _hover: {
                              borderColor: '#39a169',
                            }
                          }
                        }}
                      >
                        I agree to the terms and conditions of the Life Essentials Club
                      </Checkbox>
                    </FormControl>

                    <Button
                      type="submit"
                      display="inline-flex"
                      alignItems="center"
                      gap={2}
                      px={6}
                      py={3}
                      minH="3rem"
                      fontSize="md"
                      fontWeight="500"
                      color="#2F855A"
                      bg="transparent"
                      border="2px solid"
                      borderColor="current"
                      borderRadius="md"
                      transition="all 0.3s ease"
                      _hover={{
                        color: "#276749",
                        bg: "#F0FFF4",
                        transform: "translateY(-2px)",
                        boxShadow: "4px 4px #C6F6D5",
                        textDecoration: "none"
                      }}
                      _active={{
                        transform: "translateY(0)",
                        boxShadow: "none"
                      }}
                      _focus={{
                        outline: "4px solid #9AE6B4",
                        outlineOffset: "-1px"
                      }}
                      isDisabled={!formData.agreeToTerms || !uploadResult}
                    >
                      Sign Agreement
                      <Box as="svg"
                        h={5}
                        w={5}
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        transition="transform 0.3s ease"
                        _groupHover={{ transform: "translateX(4px)" }}
                      >
                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                      </Box>
                    </Button>
                  </VStack>
                </Box>
              )}
            </Box>
          </VStack>
        </Container>
        <FooterWithFourColumns />
      </Box>
      
      <LoginModal isOpen={isOpen} onClose={onClose} />
      {renderSignaturePreview()}
    </>
  );
};

export default Agreement; 