import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import { NavbarWithCallToAction } from '../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

interface SessionTypeFormData {
  name: string;
  description?: string;
  format: 'INDIVIDUAL' | 'GROUP';
  duration: number;
  price: number;
  color?: string;
}

const CREATE_SESSION_TYPE = gql`
  mutation CreateSessionType($input: SessionTypeInput!) {
    createSessionType(input: $input) {
      id
      name
      description
      format
      duration
      price
      color
    }
  }
`;

export const CreateSessionType = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [formData, setFormData] = useState<SessionTypeFormData>({
    name: '',
    description: '',
    format: 'INDIVIDUAL',
    duration: 60,
    price: 0,
    color: '#4A7C59',
  });

  const [createSessionType, { loading }] = useMutation(CREATE_SESSION_TYPE);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Log the form data before submission
    console.log('Submitting session type:', {
      ...formData,
      duration: Number(formData.duration),
      price: Number(formData.price),
    });

    try {
      await createSessionType({
        variables: {
          input: {
            ...formData,
            duration: Number(formData.duration),
            price: Number(formData.price),
          }
        },
        refetchQueries: ['GetSessionTypes'],
      });
      
      toast({
        title: 'Session type created',
        status: 'success',
        duration: 3000,
      });
      navigate('/sessions/types');
    } catch (error) {
      console.error('Error creating session type:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'An error occurred',
        status: 'error',
        duration: 5000,
      });
    }
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.md" py={8}>
        <VStack spacing={8} align="stretch">
          <Heading size="lg">Create New Session Type</Heading>
          
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Input
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Format</FormLabel>
                <Select
                  value={formData.format}
                  onChange={(e) => setFormData({ ...formData, format: e.target.value as 'INDIVIDUAL' | 'GROUP' })}
                >
                  <option value="INDIVIDUAL">Individual</option>
                  <option value="GROUP">Group</option>
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Duration (minutes)</FormLabel>
                <NumberInput
                  value={formData.duration}
                  onChange={(_, value) => setFormData({ ...formData, duration: value })}
                  min={15}
                  step={15}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Price ($)</FormLabel>
                <NumberInput
                  value={formData.price}
                  onChange={(_, value) => setFormData({ ...formData, price: value })}
                  min={0}
                  precision={2}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Color</FormLabel>
                <Input
                  type="color"
                  value={formData.color}
                  onChange={(e) => setFormData({ ...formData, color: e.target.value })}
                />
              </FormControl>

              <Button 
                type="submit" 
                colorScheme="blue" 
                w="full" 
                isLoading={loading}
              >
                Create Session Type
              </Button>
            </VStack>
          </form>
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </>
  );
}; 