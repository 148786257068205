import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  Button,
  Progress,
  Stat,
  StatNumber,
  StatLabel,
  Divider,
  useDisclosure,
  Image,
  Icon,
  Badge,
  HStack,
} from '@chakra-ui/react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { gql, useQuery } from '@apollo/client';
import { LoginModal } from '../components/LoginModal';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useAuth } from '../contexts/AuthContext';
import { StarIcon } from '@chakra-ui/icons';
import { SecureMembershipButton } from '../components/SecureMembershipButton';

// Add this query
const GET_MEMBERS_COUNT = gql`
  query GetClients {
    clients {
      id
    }
  }
`;

// Add this query at the top with your other queries
const GET_CLIENT_STATS = gql`
  query GetClientStats {
    clientStats
  }
`;

interface Client {
  id: string;
}

interface GetClientsResponse {
  clients: Client[];
}

// Add interfaces for the component props
interface CategoryCardProps {
  title: string;
  description: string;
  items: string[];
  imageUrl?: string;
}

interface BenefitItemProps {
  title: string;
  description: string;
  icon: string;
}

const CATEGORIES = [
  {
    title: "Healthy Home",
    description: "Products and services to create a safe, clean, and sustainable living environment.",
    items: [
      "Air Quality & Purifiers",
      "Water Quality & Filtration",
      "Eco-Friendly Living",
      "Cleaning Essentials",
      "Home Safety"
    ],
    imageUrl: "https://images.unsplash.com/photo-1484154218962-a197022b5858?auto=format&fit=crop&q=80&w=300"
  },
  {
    title: "Healthy Foods",
    description: "Nutritious and accessible food options for optimal health.",
    items: [
      "Fresh & Organic Produce",
      "Pantry Essentials",
      "Superfoods & Supplements",
      "Meal Kits & Ready-To-Eat Options",
      "Beverages"
    ],
    imageUrl: "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?auto=format&fit=crop&q=80&w=300"
  },
  {
    title: "Personal Wellbeing",
    description: "Products and services to support mental, emotional, and physical health.",
    items: [
      "Fitness & Movement",
      "Mental Health Support",
      "Skincare & Self-Care",
      "Sleep & Relaxation",
      "Work-Life Balance Tools",
      "Digital Wellbeing"
    ],
    imageUrl: "https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?auto=format&fit=crop&q=80&w=300"
  },
  {
    title: "Relationships",
    description: "Resources to nurture healthy interpersonal connections.",
    items: [
      "Couples Enrichment",
      "Parenting Support",
      "Conflict Resolution",
      "Social Wellbeing"
    ],
    imageUrl: "https://images.unsplash.com/photo-1515552726023-7125c8d07fb3?auto=format&fit=crop&q=80&w=300"
  },
  {
    title: "Family Development",
    description: "Tools and resources for building resilient, thriving families.",
    items: [
      "Child Development",
      "Financial Literacy for Families",
      "Multigenerational Support",
      "Life Events & Milestones"
    ],
    imageUrl: "https://images.unsplash.com/photo-1511895426328-dc8714191300?auto=format&fit=crop&q=80&w=300"
  },
  {
    title: "Economic Wellbeing",
    description: "Resources to support financial health and independence.",
    items: [
      "Budgeting & Planning",
      "Income Generation",
      "Investment & Savings",
      "Debt Management"
    ],
    imageUrl: "https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?auto=format&fit=crop&q=80&w=300"
  },
  {
    title: "Lifelong Learning & Skills",
    description: "Educational tools for personal and professional growth.",
    items: [
      "Online Courses & Webinars",
      "Books & Publications",
      "Hobby Development",
      "Tech Literacy"
    ],
    imageUrl: "https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?auto=format&fit=crop&q=80&w=300"
  },
  {
    title: "Sustainability & Community Wellbeing",
    description: "Promoting eco-conscious living and community care.",
    items: [
      "Sustainable Products",
      "Community Care Kits",
      "Donation Matching",
      "Workshops & Events"
    ],
    imageUrl: "https://images.unsplash.com/photo-1542601906990-b4d3fb778b09?auto=format&fit=crop&q=80&w=300"
  },
  {
    title: "Emergency Preparedness",
    description: "Ready resources for unexpected events.",
    items: [
      "Emergency Kits",
      "Natural Disaster Prep",
      "Mental Resilience Tools",
      "Financial Crisis Resources"
    ],
    imageUrl: "https://images.unsplash.com/photo-1495364141860-b0d03eccd065?auto=format&fit=crop&q=80&w=300"
  }
];

const HomePage = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: statsData } = useQuery(GET_CLIENT_STATS);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  // Calculate real numbers using the stats
  const TOTAL_LEGACY_MEMBERSHIPS = 100;
  const CURRENT_MEMBERS = statsData?.clientStats?.[0] || 0; // Using the total from stats
  const REMAINING_SPOTS = TOTAL_LEGACY_MEMBERSHIPS - CURRENT_MEMBERS;

  // Handle successful login callback
  const handleLoginSuccess = () => {
    onClose(); // Close the login modal
    navigate('/agreement'); // Redirect to agreement page
  };

  // Update the button click handler
  const handleSecureMembership = () => {
    if (isAuthenticated) {
      // If already logged in, go directly to agreement page
      navigate('/agreement');
    } else {
      // If not logged in, show login modal
      onOpen();
    }
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Box
        position="relative"
        minH="100vh"
        display="flex"
        flexDirection="column"
      >
        {/* Base gradient */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          sx={{
            background: `
              radial-gradient(circle at 0% 0%, rgba(51, 87, 134, 0.15) 0%, transparent 50%),
              radial-gradient(circle at 100% 0%, rgba(57, 161, 105, 0.15) 0%, transparent 50%),
              radial-gradient(circle at 0% 100%, rgba(234, 245, 242, 0.2) 0%, transparent 50%),
              radial-gradient(circle at 100% 100%, rgba(51, 87, 134, 0.15) 0%, transparent 50%)
            `,
            filter: 'blur(60px)',
          }}
        />

        {/* Animated overlay */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          sx={{
            background: `
              repeating-linear-gradient(
                45deg,
                rgba(51, 87, 134, 0.03) 0%,
                rgba(57, 161, 105, 0.03) 25%,
                rgba(234, 245, 242, 0.03) 50%,
                rgba(51, 87, 134, 0.03) 75%,
                rgba(57, 161, 105, 0.03) 100%
              )
            `,
            backgroundSize: '400% 400%',
            animation: 'gradient 15s ease infinite',
          }}
        />

        {/* Content container */}
        <Box
          position="relative"
          zIndex="1"
          flex="1"
        >
          <Box
            minH="100vh"
            bg="#eaf5f2"
            sx={{
              background: `
                linear-gradient(45deg, rgba(51, 87, 134, 0.05) 0%, transparent 20%),
                linear-gradient(135deg, rgba(57, 161, 105, 0.05) 25%, transparent 45%),
                linear-gradient(225deg, rgba(234, 245, 242, 0.1) 50%, transparent 75%),
                linear-gradient(315deg, rgba(51, 87, 134, 0.05) 75%, transparent 100%)
              `
            }}
          >
            <Container maxW="container.xl" py={20}>
              <VStack spacing={16}>
                {/* Hero Section */}
                <VStack spacing={6} textAlign="center">
                  <Heading 
                    size="2xl" 
                    color="#2c5282"
                    transition="all 0.3s ease"
                    _hover={{ color: "#2b6cb0" }}
                  >
                    Life Essentials Club
                  </Heading>
                  <Text fontSize="xl" maxW="800px" color="gray.700">
                    Join our exclusive community of 100 founding members dedicated to living healthier, 
                    more balanced lives through carefully curated products and services.
                  </Text>
                </VStack>

                {/* Legacy Membership Status */}
                <Box 
                  w="full" 
                  maxW="800px" 
                  p={10} 
                  borderRadius="xl" 
                  bg="white"
                  border="1px solid"
                  borderColor="rgba(51, 87, 134, 0.1)"
                  boxShadow="0 4px 20px rgba(51, 87, 134, 0.08)"
                  transition="all 0.3s ease"
                  position="relative"
                  overflow="hidden"
                  _hover={{
                    boxShadow: "0 8px 30px rgba(51, 87, 134, 0.12)",
                    transform: "translateY(-4px)"
                  }}
                >
                  {/* Decorative elements */}
                  <Box
                    position="absolute"
                    top="-20px"
                    right="-20px"
                    width="200px"
                    height="200px"
                    bg="rgba(234, 245, 242, 0.5)"
                    borderRadius="full"
                    zIndex="0"
                  />
                  
                  <VStack spacing={8} position="relative" zIndex="1">
                    <Badge
                      px={4}
                      py={2}
                      bg="rgba(51, 87, 134, 0.1)"
                      color="#335786"
                      borderRadius="full"
                      fontSize="sm"
                      fontWeight="500"
                      position="relative"
                      _before={{
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: 'full',
                        background: `
                          linear-gradient(
                            90deg, 
                            rgba(51, 87, 134, 0.4) 0%,
                            rgba(57, 161, 105, 0.4) 100%
                          )
                        `,
                        filter: 'blur(8px)',
                        opacity: 0.7,
                        transform: 'scale(1.05)',
                        zIndex: -1,
                        animation: 'pulse 2s infinite'
                      }}
                      sx={{
                        '@keyframes pulse': {
                          '0%': {
                            transform: 'scale(1.05)',
                            opacity: 0.7
                          },
                          '50%': {
                            transform: 'scale(1.15)',
                            opacity: 0.4
                          },
                          '100%': {
                            transform: 'scale(1.05)',
                            opacity: 0.7
                          }
                        }
                      }}
                    >
                      Limited Time Opportunity
                    </Badge>

                    <Heading 
                      size="xl"
                      color="#335786"
                      textAlign="center"
                      transition="all 0.3s ease"
                      _hover={{ color: "#39a169" }}
                    >
                      Legacy Membership Opportunity
                    </Heading>

                    <Text 
                      textAlign="center" 
                      fontSize="lg"
                      color="gray.700"
                      maxW="600px"
                    >
                      Be one of only <Text as="span" fontWeight="bold" color="#39a169">{REMAINING_SPOTS}</Text> remaining founding members to secure lifetime benefits 
                      and help shape the future of Life Essentials Club.
                    </Text>
                    
                    <Box 
                      w="full" 
                      p={6} 
                      bg="rgba(234, 245, 242, 0.5)"
                      borderRadius="lg"
                      border="1px solid"
                      borderColor="rgba(51, 87, 134, 0.1)"
                    >
                      <Stat textAlign="center">
                        <StatLabel fontSize="lg" color="#335786">Available Legacy Memberships</StatLabel>
                        <StatNumber 
                          fontSize="5xl" 
                          color="#39a169"
                          fontWeight="bold"
                        >
                          {REMAINING_SPOTS} <Text as="span" fontSize="2xl" color="gray.500">of {TOTAL_LEGACY_MEMBERSHIPS}</Text>
                        </StatNumber>
                      </Stat>

                      <Box mt={6}>
                        <Progress 
                          value={CURRENT_MEMBERS} 
                          max={TOTAL_LEGACY_MEMBERSHIPS} 
                          size="lg" 
                          borderRadius="full"
                          bg="rgba(51, 87, 134, 0.1)"
                          sx={{
                            '& > div': {
                              background: 'linear-gradient(90deg, #335786 0%, #39a169 100%)',
                            }
                          }}
                        />
                        
                        <Text 
                          fontSize="sm" 
                          color="gray.600" 
                          textAlign="center"
                          mt={2}
                        >
                          <Text as="span" fontWeight="bold" color="#335786">{CURRENT_MEMBERS}</Text> members have already joined
                          {statsData?.clientStats?.[1] > 0 && (
                            <Text as="span" ml={2} color="#39a169">
                              (+{statsData.clientStats[1]} in the last 24h)
                            </Text>
                          )}
                        </Text>
                      </Box>
                    </Box>

                    <SecureMembershipButton size="lg" />
                  </VStack>
                </Box>

                {/* Category Showcase */}
                <Box w="full">
                  <Heading size="xl" mb={8} textAlign="center">Our Product Categories</Heading>
                  <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
                    {CATEGORIES.map((category) => (
                      <CategoryCard
                        key={category.title}
                        title={category.title}
                        description={category.description}
                        items={category.items}
                        imageUrl={category.imageUrl}
                      />
                    ))}
                  </SimpleGrid>

                  <Box textAlign="center" mt={12}>
                    <SecureMembershipButton size="lg" />
                  </Box>
                </Box>

                {/* Member Benefits */}
                <Box 
                  w="full" 
                  bg="white" 
                  p={10} 
                  borderRadius="xl"
                  border="1px solid"
                  borderColor="rgba(51, 87, 134, 0.1)"
                  boxShadow="0 4px 20px rgba(51, 87, 134, 0.08)"
                  transition="all 0.3s ease"
                  position="relative"
                  overflow="hidden"
                  _hover={{
                    boxShadow: "0 8px 30px rgba(51, 87, 134, 0.12)",
                    transform: "translateY(-4px)"
                  }}
                >
                  {/* Decorative background elements */}
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    width="300px"
                    height="300px"
                    bg="rgba(234, 245, 242, 0.5)"
                    borderRadius="full"
                    transform="translate(150px, -150px)"
                    zIndex="0"
                  />
                  <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    width="200px"
                    height="200px"
                    bg="rgba(57, 161, 105, 0.05)"
                    borderRadius="full"
                    transform="translate(-100px, 100px)"
                    zIndex="0"
                  />

                  <VStack spacing={8} position="relative" zIndex="1">
                    <Badge
                      px={4}
                      py={2}
                      bg="rgba(51, 87, 134, 0.1)"
                      color="#335786"
                      borderRadius="full"
                      fontSize="sm"
                      fontWeight="500"
                      position="relative"
                      _before={{
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: 'full',
                        background: `
                          linear-gradient(
                            90deg, 
                            rgba(51, 87, 134, 0.4) 0%,
                            rgba(57, 161, 105, 0.4) 100%
                          )
                        `,
                        filter: 'blur(8px)',
                        opacity: 0.7,
                        transform: 'scale(1.05)',
                        zIndex: -1,
                        animation: 'pulse 2s infinite'
                      }}
                      sx={{
                        '@keyframes pulse': {
                          '0%': {
                            transform: 'scale(1.05)',
                            opacity: 0.7
                          },
                          '50%': {
                            transform: 'scale(1.15)',
                            opacity: 0.4
                          },
                          '100%': {
                            transform: 'scale(1.05)',
                            opacity: 0.7
                          }
                        }
                      }}
                    >
                      Exclusive Benefits
                    </Badge>
                    
                    <Heading 
                      size="xl"
                      color="#335786"
                      textAlign="center"
                      maxW="800px"
                      transition="all 0.3s ease"
                      _hover={{ color: "#39a169" }}
                    >
                      Legacy Member Benefits
                    </Heading>

                    <SimpleGrid 
                      columns={{ base: 1, md: 2 }} 
                      spacing={8}
                      w="full"
                    >
                      <BenefitItem 
                        title="Lifetime Discounts" 
                        description="Enjoy permanent savings on all products and services"
                        icon="💎"
                      />
                      <BenefitItem 
                        title="Early Access" 
                        description="Be first to shop new products and collections"
                        icon="🎯"
                      />
                      <BenefitItem 
                        title="Exclusive Events" 
                        description="Access to member-only workshops and community events"
                        icon="✨"
                      />
                      <BenefitItem 
                        title="Shape Our Future" 
                        description="Direct input on new products and services"
                        icon="🌱"
                      />
                    </SimpleGrid>
                  </VStack>
                </Box>
              </VStack>
            </Container>
          </Box>
        </Box>

        {/* Keep your existing style tag for the gradient animation */}
        <style>{`
          @keyframes gradient {
            0% { background-position: 0% 50% }
            50% { background-position: 100% 50% }
            100% { background-position: 0% 50% }
          }
        `}</style>
      </Box>
      <FooterWithFourColumns />

      {/* Add LoginModal with success callback */}
      <LoginModal 
        isOpen={isOpen} 
        onClose={onClose}
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

// Update the component definitions with types
const CategoryCard = ({ title, description, items, imageUrl }: CategoryCardProps) => (
  <Box 
    position="relative"
    borderRadius="2xl"
    overflow="hidden"
    bg="white"
    transition="all 0.3s"
    _hover={{
      transform: "translateY(-8px)",
      boxShadow: "2xl"
    }}
  >
    {/* Gradient Overlay */}
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bg="linear-gradient(180deg, rgba(51, 87, 134, 0.02) 0%, rgba(57, 161, 105, 0.08) 100%)"
      zIndex={1}
      opacity={0.8}
      transition="opacity 0.3s"
      _groupHover={{ opacity: 0.9 }}
    />

    {/* Decorative Elements */}
    <Box
      position="absolute"
      top="-20px"
      right="-20px"
      width="150px"
      height="150px"
      bg="rgba(234, 245, 242, 0.5)"
      borderRadius="full"
      zIndex={1}
    />
    
    <Box
      position="absolute"
      bottom="-30px"
      left="-30px"
      width="120px"
      height="120px"
      bg="rgba(51, 87, 134, 0.05)"
      borderRadius="full"
      zIndex={1}
    />

    {/* Main Content */}
    <Box
      position="relative"
      zIndex={2}
      p={6}
      borderRadius="2xl"
      border="1px solid"
      borderColor="rgba(51, 87, 134, 0.1)"
      bg="rgba(255, 255, 255, 0.9)"
      backdropFilter="blur(10px)"
      role="group"
    >
      {/* Member Benefits Badge - New Design */}
      <Box
        position="absolute"
        top={4}
        right={4}
        zIndex={10}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {/* Main Badge */}
        <Box
          px={4}
          py={3}
          borderRadius="2xl"
          bg="white"
          border="2px solid"
          borderColor="rgba(57, 161, 105, 0.3)"
          boxShadow="0 4px 20px rgba(51, 87, 134, 0.15)"
          transform="rotate(-3deg)"
          _hover={{
            transform: "rotate(-3deg) translateY(-2px)",
            boxShadow: "0 8px 30px rgba(51, 87, 134, 0.2)",
          }}
          transition="all 0.3s ease"
          position="relative"
          overflow="hidden"
        >
          {/* Gradient Background */}
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="linear-gradient(135deg, rgba(51, 87, 134, 0.05) 0%, rgba(57, 161, 105, 0.1) 100%)"
            opacity={0.8}
            transition="opacity 0.3s"
            _groupHover={{ opacity: 1 }}
          />

          {/* Sparkle Effect */}
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            overflow="hidden"
            opacity={0.5}
          >
            <Box
              position="absolute"
              top="50%"
              left="-100%"
              width="300%"
              height="1px"
              bg="linear-gradient(90deg, transparent, rgba(255,255,255,0.8), transparent)"
              animation="sparkle 4s linear infinite"
              sx={{
                '@keyframes sparkle': {
                  '0%': { transform: 'translateX(-100%) rotate(45deg)' },
                  '100%': { transform: 'translateX(100%) rotate(45deg)' }
                }
              }}
            />
          </Box>

          {/* Content */}
          <VStack spacing={1} position="relative">
            <HStack spacing={2} align="center">
              <Icon 
                as={StarIcon} 
                w={4} 
                h={4} 
                color="#39a169"
                animation="pulse 2s infinite"
                sx={{
                  '@keyframes pulse': {
                    '0%': { transform: 'scale(1)' },
                    '50%': { transform: 'scale(1.2)' },
                    '100%': { transform: 'scale(1)' }
                  }
                }}
              />
              <Text
                fontSize="xs"
                fontWeight="600"
                color="#335786"
                textTransform="uppercase"
                letterSpacing="wider"
              >
                Member Benefits
              </Text>
            </HStack>
            
            <Text
              fontSize="sm"
              fontWeight="bold"
              color="#39a169"
              textAlign="center"
              bgGradient="linear(to-r, #335786, #39a169)"
              bgClip="text"
            >
              Save up to 20%
            </Text>
          </VStack>
        </Box>

        {/* Decorative Line */}
        <Box
          width="2px"
          height="40px"
          bgGradient="linear(to-b, #39a169, transparent)"
          marginTop={2}
          opacity={0.3}
        />
      </Box>

      <VStack align="start" spacing={6}>
        {imageUrl && (
          <Box
            w="full"
            h="200px"
            borderRadius="xl"
            overflow="hidden"
            position="relative"
            boxShadow="lg"
          >
            <Image
              src={imageUrl}
              alt={title}
              objectFit="cover"
              w="full"
              h="full"
              transition="all 0.5s ease"
              _groupHover={{
                transform: 'scale(1.05)'
              }}
            />
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.2) 100%)"
              opacity={0}
              transition="opacity 0.3s"
              _groupHover={{ opacity: 1 }}
            />
          </Box>
        )}

        <VStack align="start" spacing={3} w="full">
          <Heading
            size="md"
            color="#335786"
            _groupHover={{ color: "#39a169" }}
            transition="color 0.3s"
          >
            {title}
          </Heading>
          
          <Text
            fontSize="sm"
            color="gray.600"
            lineHeight="tall"
          >
            {description}
          </Text>

          <Divider borderColor="rgba(51, 87, 134, 0.1)" />

          <VStack align="start" spacing={2} w="full">
            {items.map((item: string) => (
              <HStack key={item} spacing={2}>
                <Box
                  w="2px"
                  h="2px"
                  borderRadius="full"
                  bg="#39a169"
                />
                <Text fontSize="sm" color="gray.700">
                  {item}
                </Text>
              </HStack>
            ))}
          </VStack>

          <Button
            as={RouterLink}
            to="/products"
            variant="outline"
            colorScheme="green"
            size="sm"
            width="full"
            mt={2}
            rightIcon={<ChevronRightIcon />}
            _hover={{
              bg: "green.50",
              transform: "translateX(4px)"
            }}
            transition="all 0.3s"
          >
            Explore {title}
          </Button>
        </VStack>
      </VStack>
    </Box>
  </Box>
);

const BenefitItem = ({ title, description, icon }: BenefitItemProps) => (
  <Box 
    p={6}
    bg="white"
    borderRadius="lg"
    border="1px solid"
    borderColor="rgba(51, 87, 134, 0.1)"
    transition="all 0.3s ease"
    _hover={{
      bg: "rgba(234, 245, 242, 0.5)",
      transform: "translateY(-2px)",
      boxShadow: "0 4px 12px rgba(51, 87, 134, 0.06)"
    }}
  >
    <VStack align="start" spacing={3}>
      <Box
        p={2}
        bg="rgba(57, 161, 105, 0.1)"
        borderRadius="md"
        color="#39a169"
        fontSize="xl"
      >
        {icon}
      </Box>
      <Heading 
        size="md" 
        color="#335786"
        _groupHover={{ color: "#39a169" }}
      >
        {title}
      </Heading>
      <Text 
        fontSize="sm" 
        color="gray.600"
        lineHeight="1.6"
      >
        {description}
      </Text>
    </VStack>
  </Box>
);

export default HomePage;