// src/utils/helpers.ts

import axios from 'axios';

export const getApiUrl = () => {
  const envEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  
  if (envEndpoint) {
    console.log('Using GraphQL endpoint from env:', envEndpoint);
    return envEndpoint;
  }

  const hostname = window.location.hostname;
  const endpoint = hostname === "localhost" 
    ? "http://localhost:4000/graphql"
    : "https://api.lifeessentials.club/graphql";

  console.log('Using default GraphQL endpoint:', endpoint);
  return endpoint;
};


export const getBitcoinPrice = async (): Promise<number> => {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=aud');
    return response.data.bitcoin.aud;
  } catch (error) {
    console.error('Error fetching BTC price:', error);
    return 0; // Return 0 or some default value in case of an error
  }
};

export const calculateBTCAmount = (audAmount: number, btcPrice: number): number => {
  return audAmount / btcPrice;
};