import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Flex,
    Image,
    VStack,
    Button,
    Link as ChakraLink,
    Badge,
    Icon,
} from "@chakra-ui/react";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { FaVoteYea, FaUsers, FaCheck, FaClock } from "react-icons/fa";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const Governance = () => {
    return (
        <Box position="relative" minH="100vh" display="flex" flexDirection="column">
            <NavbarWithCallToAction />
            
            {/* Base gradient background */}
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                sx={{
                    background: `
                        radial-gradient(circle at 0% 0%, rgba(51, 87, 134, 0.15) 0%, transparent 50%),
                        radial-gradient(circle at 100% 0%, rgba(57, 161, 105, 0.15) 0%, transparent 50%),
                        radial-gradient(circle at 0% 100%, rgba(234, 245, 242, 0.2) 0%, transparent 50%),
                        radial-gradient(circle at 100% 100%, rgba(51, 87, 134, 0.15) 0%, transparent 50%)
                    `,
                    filter: 'blur(60px)',
                }}
            />

            {/* Animated overlay */}
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                sx={{
                    background: `
                        repeating-linear-gradient(
                            45deg,
                            rgba(51, 87, 134, 0.03) 0%,
                            rgba(57, 161, 105, 0.03) 25%,
                            rgba(234, 245, 242, 0.03) 50%,
                            rgba(51, 87, 134, 0.03) 75%,
                            rgba(57, 161, 105, 0.03) 100%
                        )
                    `,
                    backgroundSize: '400% 400%',
                    animation: 'gradient 15s ease infinite',
                }}
            />

            {/* Content container */}
            <Box
                position="relative"
                zIndex="1"
                flex="1"
                bg="#eaf5f2"
                sx={{
                    background: `
                        linear-gradient(45deg, rgba(51, 87, 134, 0.05) 0%, transparent 20%),
                        linear-gradient(135deg, rgba(57, 161, 105, 0.05) 25%, transparent 45%),
                        linear-gradient(225deg, rgba(234, 245, 242, 0.1) 50%, transparent 75%),
                        linear-gradient(315deg, rgba(51, 87, 134, 0.05) 75%, transparent 100%)
                    `
                }}
            >
                <Container maxW="6xl" py={20}>
                    <VStack spacing={12} align="stretch">
                        {/* Introduction Section with floating effect */}
                        <Box 
                            p={8} 
                            bg="white"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            position="relative"
                            overflow="hidden"
                            sx={{
                                animation: 'float 6s ease-in-out infinite',
                                '&:hover': {
                                    '& > .decorative-circle': {
                                        transform: 'scale(1.1)',
                                    }
                                }
                            }}
                        >
                            {/* Decorative circle */}
                            <Box
                                className="decorative-circle"
                                position="absolute"
                                top="-20%"
                                right="-10%"
                                width="300px"
                                height="300px"
                                bg="rgba(234, 245, 242, 0.5)"
                                borderRadius="full"
                                transition="transform 0.3s ease"
                                zIndex={0}
                            />
                            
                            <Box position="relative" zIndex={1}>
                                <Heading 
                                    size="lg" 
                                    mb={6}
                                    color="#2F855A"
                                    transition="all 0.3s ease"
                                    _hover={{ color: "#276749" }}
                                >
                                    Community-Led Governance
                                </Heading>
                                <Text color="gray.700" fontSize="lg" mb={4}>
                                    At Life Essentials Club, we believe in the power of community-driven decision making. 
                                    Our governance model ensures that every member has a voice in shaping the future of our club, 
                                    from operational decisions to strategic initiatives that connect our community with 
                                    regional farmers and quality food sources.
                                </Text>
                                <Button
                                    as="a"
                                    href="https://snapshot.box/#/s:lifeessentialsclub.eth/proposal/0x1ccf1b2ae5528ad8f506b7d7f81c7d4d501af4818a7296a9a5f88ee7cc2600ba"
                                    target="_blank"
                                    rightIcon={<ExternalLinkIcon />}
                                    colorScheme="green"
                                    mt={4}
                                    size="md"
                                    bg="#2F855A"
                                    _hover={{ bg: "#276749", transform: "translateY(-2px)" }}
                                    transition="all 0.3s ease"
                                    boxShadow="0 4px 12px rgba(47, 133, 90, 0.2)"
                                >
                                    View Current Proposals
                                </Button>
                            </Box>
                        </Box>

                        {/* How Our Governance Works */}
                        <Box 
                            p={8} 
                            bg="white"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.3s ease"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Heading 
                                size="lg" 
                                mb={6}
                                color="#2F855A"
                                transition="all 0.3s ease"
                                _hover={{ color: "#276749" }}
                            >
                                How Our Governance Works
                            </Heading>

                            <VStack spacing={6} align="stretch">
                                <Flex 
                                    gap={6} 
                                    align="center" 
                                    bg="green.50" 
                                    p={5} 
                                    borderRadius="xl"
                                    borderLeft="4px solid"
                                    borderColor="#2F855A"
                                >
                                    <Icon as={FaClock} boxSize={10} color="#2F855A" />
                                    <Box>
                                        <Heading size="md" mb={2} color="#2F855A">7-Day Pending Period</Heading>
                                        <Text color="gray.700">
                                            When a proposal is created, we have a 7-day review period where members can discuss, 
                                            ask questions, and prepare for voting. This ensures everyone has time to understand 
                                            the implications before casting their vote.
                                        </Text>
                                    </Box>
                                </Flex>

                                <Flex 
                                    gap={6} 
                                    align="center" 
                                    bg="green.50" 
                                    p={5} 
                                    borderRadius="xl"
                                    borderLeft="4px solid"
                                    borderColor="#2F855A"
                                >
                                    <Icon as={FaVoteYea} boxSize={10} color="#2F855A" />
                                    <Box>
                                        <Heading size="md" mb={2} color="#2F855A">7-Day Voting Period</Heading>
                                        <Text color="gray.700">
                                            Following the pending period, members have 7 days to cast their votes. 
                                            Voting power is proportional to the number of LEC tokens held, ensuring 
                                            decisions reflect the stake each member has in our community.
                                        </Text>
                                    </Box>
                                </Flex>

                                <Flex 
                                    gap={6} 
                                    align="center" 
                                    bg="green.50" 
                                    p={5} 
                                    borderRadius="xl"
                                    borderLeft="4px solid"
                                    borderColor="#2F855A"
                                >
                                    <Icon as={FaCheck} boxSize={10} color="#2F855A" />
                                    <Box>
                                        <Heading size="md" mb={2} color="#2F855A">Transparent Execution</Heading>
                                        <Text color="gray.700">
                                            Once a proposal passes, implementation is transparent and accountable. 
                                            Our blockchain-based voting system ensures that all decisions are 
                                            recorded immutably, providing a clear history of our community's evolution.
                                        </Text>
                                    </Box>
                                </Flex>
                            </VStack>
                        </Box>

                        {/* How to Participate */}
                        <Box 
                            p={8} 
                            bg="white"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.3s ease"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Flex direction={{ base: "column", md: "row" }} gap={8} align="center">
                                <Box flex="1">
                                    <Heading 
                                        size="lg" 
                                        mb={4}
                                        color="#2F855A"
                                        transition="all 0.3s ease"
                                        _hover={{ color: "#276749" }}
                                    >
                                        How to Participate
                                    </Heading>
                                    <Text color="gray.700" fontSize="lg" mb={4}>
                                        Participating in Life Essentials Club governance is simple and empowering:
                                    </Text>
                                    <VStack align="start" spacing={4}>
                                        <Flex align="center">
                                            <Badge colorScheme="green" fontSize="md" p={1} mr={2}>1</Badge>
                                            <Text color="gray.700" fontSize="md">
                                                <strong>Become a member</strong> and receive LEC tokens that represent your voting power
                                            </Text>
                                        </Flex>
                                        <Flex align="center">
                                            <Badge colorScheme="green" fontSize="md" p={1} mr={2}>2</Badge>
                                            <Text color="gray.700" fontSize="md">
                                                <strong>Connect your wallet</strong> to our Snapshot space to view and participate in proposals
                                            </Text>
                                        </Flex>
                                        <Flex align="center">
                                            <Badge colorScheme="green" fontSize="md" p={1} mr={2}>3</Badge>
                                            <Text color="gray.700" fontSize="md">
                                                <strong>Cast your vote</strong> during the voting period and help shape our community's future
                                            </Text>
                                        </Flex>
                                        <Flex align="center">
                                            <Badge colorScheme="green" fontSize="md" p={1} mr={2}>4</Badge>
                                            <Text color="gray.700" fontSize="md">
                                                <strong>Submit proposals</strong> as you become more involved in the community
                                            </Text>
                                        </Flex>
                                    </VStack>

                                    <Button
                                        colorScheme="blue"
                                        size="lg"
                                        mt={8}
                                        bg="#335786"
                                        _hover={{ bg: "#264266", transform: "translateY(-2px)" }}
                                        transition="all 0.3s ease"
                                        boxShadow="0 4px 12px rgba(51, 87, 134, 0.2)"
                                        as="a"
                                        href="/agreement"
                                    >
                                        Join Our Community
                                    </Button>
                                </Box>
                                <Box
                                    flex="1"
                                    height="350px"
                                    borderRadius="xl"
                                    overflow="hidden"
                                    position="relative"
                                    bg="gray.100"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Icon as={FaUsers} boxSize={20} color="#2F855A" />
                                </Box>
                            </Flex>
                        </Box>

                        {/* Live Demo Section */}
                        {/* <Box 
                            p={8} 
                            bg="white"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.3s ease"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Heading 
                                size="lg" 
                                mb={6}
                                color="#2F855A"
                                transition="all 0.3s ease"
                                _hover={{ color: "#276749" }}
                            >
                                Governance in Action
                            </Heading>
                            <Text color="gray.700" fontSize="lg" mb={6}>
                                See our governance system in action by viewing our current proposal. This 
                                demonstrates how we make decisions transparently and collectively:
                            </Text>
                            
                            <Box
                                as="iframe"
                                src="https://snapshot.box/#/s:lifeessentialsclub.eth/proposal/0x1ccf1b2ae5528ad8f506b7d7f81c7d4d501af4818a7296a9a5f88ee7cc2600ba"
                                width="100%"
                                height="400px"
                                borderRadius="lg"
                                border="1px solid"
                                borderColor="gray.200"
                                boxShadow="md"
                            />
                        </Box> */}
                    </VStack>
                </Container>
            </Box>

            <FooterWithFourColumns />

            {/* Add the animations */}
            <style>{`
                @keyframes gradient {
                    0% { background-position: 0% 50% }
                    50% { background-position: 100% 50% }
                    100% { background-position: 0% 50% }
                }
                
                @keyframes float {
                    0% { transform: translateY(0px) }
                    50% { transform: translateY(-10px) }
                    100% { transform: translateY(0px) }
                }
                
                @keyframes pulse {
                    0% { transform: scale(1); opacity: 0.8 }
                    50% { transform: scale(1.1); opacity: 0.5 }
                    100% { transform: scale(1); opacity: 0.8 }
                }
            `}</style>
        </Box>
    );
};

export default Governance; 