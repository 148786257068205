import React, { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Card,
  CardBody,
  Text,
  VStack,
  Button,
  Input,
  useToast,
  HStack,
  Icon,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Skeleton,
  Badge,
} from "@chakra-ui/react";
import {
  FiGlobe,
  FiServer,
  FiUsers,
  FiHardDrive,
  FiCode,
  FiMail,
  FiDollarSign,
  FiUserPlus,
  FiLock,
  FiPackage,
  FiList,
  FiShoppingCart,
  FiCalendar,
  FiClock,
} from "react-icons/fi";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { gql, useQuery } from "@apollo/client";
import { motion } from "framer-motion";
import { useAuth } from "../contexts/AuthContext";
import { LoginModal } from "../components/LoginModal";

interface NavItem {
  path: string;
  title: string;
  description: string;
  icon: React.ComponentType;
  comingSoon?: boolean;
}

interface NavCardProps {
  item: NavItem;
}

const navigationGroups = {
  products: {
    title: "Products",
    items: [
      {
        path: "/products/new",
        title: "New Product",
        description: "Create a new product listing",
        icon: FiPackage,
      },
      {
        path: "/products",
        title: "All Products",
        description: "View and manage all products",
        icon: FiList,
      },
    ],
  },
  sessions: {
    title: "Sessions",
    items: [
      {
        path: "/sessions/types",
        title: "Session Types",
        description: "Manage different types of sessions",
        icon: FiList,
      },
      {
        path: "/sessions/types/create",
        title: "Create Session Type",
        description: "Create a new session type",
        icon: FiPackage,
      },
      {
        path: "/sessions/availability",
        title: "Availability",
        description: "Manage your availability calendar",
        icon: FiCalendar,
        comingSoon: true,
      },
      {
        path: "/schedule-session",
        title: "Publish Session Time",
        description: "Schedule and publish session times",
        icon: FiClock,
      },
      {
        path: "/sessions/client-booking-calendar",
        title: "My Bookings",
        description: "View your booked sessions",
        icon: FiCalendar,
      },
    ],
  },
  clients: {
    title: "Client Management",
    items: [
      {
        path: "/clients",
        title: "View All Clients",
        description: "Manage and view all client information",
        icon: FiUsers,
      },
      {
        path: "/newclient",
        title: "New Client",
        description: "Create a new client profile",
        icon: FiUserPlus,
      },
    ],
  },
  primary: {
    title: "Primary Management",
    items: [
      {
        path: "/projects",
        title: "Projects List",
        description: "View all projects",
        icon: FiCode,
      },
      {
        path: "/projects/new",
        title: "New Project",
        description: "Create a new project",
        icon: FiCode,
      },
      {
        path: "/services",
        title: "Services List",
        description: "View all available services",
        icon: FiServer,
      },
      {
        path: "/bills",
        title: "Bills",
        description: "View and manage bills",
        icon: FiDollarSign,
      },
      {
        path: "/bills/new",
        title: "Create New Bill",
        description: "Create a new bill for a service",
        icon: FiDollarSign,
      },
    ],
  },
  services: {
    title: "New Services",
    items: [
      {
        path: "/service/website/new",
        title: "New Website Service",
        description: "Create a new website service",
        icon: FiGlobe,
      },
      {
        path: "/service/hdwallet/new",
        title: "New HD Wallet Service",
        description: "Create a new HD wallet service",
        icon: FiHardDrive,
      },
    ],
  },
  specialPages: {
    title: "Special Pages",
    items: [
      {
        path: "/hardware-wallets",
        title: "Hardware Wallets",
        description: "Hardware wallet information page",
        icon: FiHardDrive,
      },
      {
        path: "/custom-website-development",
        title: "Custom Development",
        description: "Custom website development services",
        icon: FiCode,
      },
      {
        path: "/test/sendemail",
        title: "Email Testing",
        description: "Test email functionality",
        icon: FiMail,
      },
      {
        path: "/test/upload-encrypted",
        title: "Encrypted Upload Test",
        description: "Test encrypted file uploads",
        icon: FiServer,
      },
      {
        path: "/test/upload-unencrypted",
        title: "Unencrypted Upload Test",
        description: "Test unencrypted file uploads",
        icon: FiServer,
      },
      {
        path: "/test/auth",
        title: "Auth Testing",
        description: "Test two-word authentication system",
        icon: FiLock,
      },
      {
        path: "/test/cart",
        title: "Cart Testing",
        description: "Test client cart functionality",
        icon: FiShoppingCart,
      },
      {
        path: "/test/upload-pinata",
        title: "Pinata Upload Test",
        description: "Test file uploads to Pinata",
        icon: FiServer,
      },
    ],
  },
};



const GET_CLIENT_STATS = gql`
  query GetClientStats {
    clientStats
  }
`;

const GET_PRODUCT_STATS = gql`
  query GetProductStats {
    productStats
  }
`;

const NavCard: React.FC<NavCardProps> = ({ item }) => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    if (!item.comingSoon) {
      navigate(item.path);
    }
  };

  return (
    <Card
      as="button"
      onClick={handleClick}
      cursor={item.comingSoon ? "not-allowed" : "pointer"}
      _hover={{
        transform: item.comingSoon ? "none" : "translateY(-2px)",
        boxShadow: item.comingSoon ? "none" : "lg",
      }}
      opacity={item.comingSoon ? 0.6 : 1}
      transition="all 0.2s"
      position="relative"
    >
      <CardBody>
        <VStack align="start" spacing={2}>
          <Icon as={item.icon} boxSize={6} color={item.comingSoon ? "gray.400" : "blue.500"} />
          <Heading size="sm" color={item.comingSoon ? "gray.500" : "inherit"}>
            {item.title}
          </Heading>
          <Text fontSize="sm" color={item.comingSoon ? "gray.500" : "gray.600"}>
            {item.description}
          </Text>
          
          {item.comingSoon && (
            <Badge colorScheme="gray" mt={2}>
              Coming Soon
            </Badge>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

const StatCard: React.FC<{
  label: string;
  mainValue: number;
  subValues: { label: string; value: number; link?: string; color?: string }[];
  helpText: string;
  icon: React.ComponentType;
  animate?: boolean;
  mainLink?: string;
  mainColor?: string;
}> = ({ label, mainValue, subValues, helpText, icon, animate = false, mainLink, mainColor = "blue.500" }) => {
  const total = mainValue + subValues.reduce((sum, curr) => sum + curr.value, 0);

  return (
    <Stat
      as={motion.div as any}
      initial={animate ? { scale: 0.9, opacity: 0 } : undefined}
      animate={animate ? { scale: 1, opacity: 1 } : undefined}
      transition="all 0.2s"
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={'gray.200'}
      rounded={'lg'}
    >
      <StatLabel fontWeight={'medium'} isTruncated>
        {label}
      </StatLabel>
      <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
        <RouterLink 
          to={mainLink || '#'} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ color: `var(--chakra-colors-${mainColor})` }}
        >
          {mainValue}
        </RouterLink>
        {subValues.map((subValue, index) => (
          <Text key={index} as="span" fontSize="sm" ml={2}>
            <Text as="span" color={subValue.color || 'gray.500'}>
              {subValue.label}
            </Text>
            :{' '}
            {subValue.link ? (
              <RouterLink 
                to={subValue.link} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ color: `var(--chakra-colors-${subValue.color || 'gray.500'})` }}
              >
                {subValue.value}
              </RouterLink>
            ) : (
              <Text as="span" color={subValue.color || 'gray.500'}>
                {subValue.value}
              </Text>
            )}
          </Text>
        ))}
      </StatNumber>
      <HStack justify="space-between">
        <StatHelpText>
          <HStack>
            <Icon as={icon} boxSize={4} />
            <Text>{helpText}</Text>
          </HStack>
        </StatHelpText>
        <StatHelpText>
          {/* <Text fontWeight="bold">
            Total: {total}
          </Text> */}
        </StatHelpText>
      </HStack>
    </Stat>
  );
};

const AdminDashboard = () => {
  const { isAuthenticated, user } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { loading: clientLoading, data: clientData } = useQuery(GET_CLIENT_STATS);
  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT_STATS);

  // Show login modal if not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      setIsLoginModalOpen(true);
    }
  }, [isAuthenticated]);

  // If not authenticated, show login modal
  if (!isAuthenticated) {
    return (
      <>
        <NavbarWithCallToAction />
        <Box minH="100vh" bg="gray.50" py={12}>
          <Container maxW="md">
            <VStack spacing={8}>
              <Box bg="white" p={8} rounded="xl" shadow="lg" w="full">
                <VStack spacing={4}>
                  <Heading size="lg">Authentication Required</Heading>
                  <Text>Please log in to access the admin dashboard.</Text>
                </VStack>
              </Box>
            </VStack>
          </Container>
        </Box>
        <LoginModal 
          isOpen={isLoginModalOpen} 
          onClose={() => setIsLoginModalOpen(false)}
        />
        <FooterWithFourColumns />
      </>
    );
  }

  // If authenticated but not admin, show no permission message
  if (!user?.permissions?.includes('ADMIN')) {
    return (
      <>
        <NavbarWithCallToAction />
        <Box minH="100vh" bg="gray.50" py={12}>
          <Container maxW="md">
            <VStack spacing={8}>
              <Box bg="white" p={8} rounded="xl" shadow="lg" w="full">
                <VStack spacing={4}>
                  <Heading size="lg" color="red.500">Access Denied</Heading>
                  <Text>You do not have permission to view this page.</Text>
                  <Text>Admin access is required.</Text>
                </VStack>
              </Box>
            </VStack>
          </Container>
        </Box>
        <FooterWithFourColumns />
      </>
    );
  }

  // Original dashboard content for authenticated admin users
  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={8}>
        {/* Stats Section */}
        <Box mb={8}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
            {clientLoading ? (
              <Skeleton height="200px" />
            ) : (
              <StatCard
                label="Members"
                mainValue={clientData?.clientStats[0] || 0}
                subValues={[
                  { label: "24h", value: clientData?.clientStats[1] || 0, color: "purple.500" },
                  { label: "7 days", value: clientData?.clientStats[2] || 0, color: "green.500" },
                  { label: "30 days", value: clientData?.clientStats[3] || 0, color: "blue.500" }
                ]}
                helpText="Total registered clients"
                icon={FiUsers}
                animate={true}
              />
            )}
            
            {productLoading ? (
              <Skeleton height="200px" />
            ) : (
              <StatCard
                label="Products"
                mainValue={productData?.productStats[0] || 0}
                subValues={[
                  { label: "24h", value: productData?.productStats[1] || 0, color: "purple.500" },
                  { label: "7 days", value: productData?.productStats[2] || 0, color: "green.500" },
                  { label: "30 days", value: productData?.productStats[3] || 0, color: "blue.500" },
                  { label: "Active", value: productData?.productStats[4] || 0, color: "teal.500" },
                  // { label: "Featured", value: productData?.productStats[5] || 0, color: "yellow.500" },
                  // { label: "Best Sellers", value: productData?.productStats[6] || 0, color: "orange.500" }
                ]}
                helpText={`Physical: ${productData?.productStats[7] || 0} | Digital: ${productData?.productStats[8] || 0}`
              }
                icon={FiPackage}
                animate={true}
              />
            )}

            <StatCard
              label="Sales Today"
              mainValue={0}
              subValues={[
                { label: "7 days", value: 0, color: "green.500" },
                { label: "30 days", value: 0, color: "blue.500" }
              ]}
              helpText="Revenue in AUD"
              icon={FiDollarSign}
              animate={true}
              mainColor="green.500"
            />
          </SimpleGrid>
        </Box>

        {/* Navigation Sections */}
        <VStack spacing={8} align="stretch">
          {Object.entries(navigationGroups).map(([key, group]) => (
            <Box key={key}>
              <Heading size="md" mb={4}>
                {group.title}
              </Heading>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                {group.items.map((item) => (
                  <NavCard key={item.path} item={item} />
                ))}
              </SimpleGrid>
              <Divider mt={8} />
            </Box>
          ))}
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </>
  );
};

export default AdminDashboard; 