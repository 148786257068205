import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { LoginModal } from './LoginModal';
import { useDisclosure } from '@chakra-ui/react';

interface AuthButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  onlyTriggerWhenClicked?: boolean;
}

export const AuthButton: React.FC<AuthButtonProps> = ({ 
  children, 
  onClick, 
  onlyTriggerWhenClicked = false 
}) => {
  const { isAuthenticated, loading } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = (e: React.MouseEvent) => {
    if (!isAuthenticated) {
      e.preventDefault();
      e.stopPropagation();
      onOpen();
    } else {
      onClick();
    }
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <div onClick={handleClick}>
        {children}
      </div>
      <LoginModal 
        isOpen={isOpen} 
        onClose={onClose}
        onLoginSuccess={() => {
          onClose();
          onClick(); // Execute the action after successful login
        }}
      />
    </>
  );
}; 