import React, { useEffect } from 'react';
import { Button, useDisclosure, VStack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { LoginModal } from './LoginModal';

interface SecureMembershipButtonProps {
  variant?: string;
  size?: string;
}

export const SecureMembershipButton: React.FC<SecureMembershipButtonProps> = ({ variant, size }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { isAuthenticated, refreshAuth, user } = useAuth();
  const [buttonText, setButtonText] = React.useState('Secure Membership');

  // Refresh auth context every 10 seconds
  useEffect(() => {
    // Initial refresh when component mounts
    refreshAuth();
    
    const intervalId = setInterval(() => {
      refreshAuth();
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshAuth]);

  // Update button text when user data changes
  useEffect(() => {
    if (user?.encryptedSignatureIpfsUrl) {
      console.log('User has signature:', user.encryptedSignatureIpfsUrl);
      setButtonText('View Products');
    } else {
      console.log('No signature found for user:', user);
      setButtonText('Secure Membership');
    }
  }, [user]);

  const handleClick = () => {
    if (!isAuthenticated) {
      onOpen();
      return;
    }

    if (user?.encryptedSignatureIpfsUrl) {
      navigate('/products');
    } else {
      navigate('/agreement');
    }
  };

  const handleLoginSuccess = async () => {
    await refreshAuth();
    onClose();
    navigate('/agreement');
  };

  return (
    <>
      <VStack spacing={2}>
        <Button
          onClick={handleClick}
          size={size || "sm"}
          px={6}
          sx={{
            background: '#335786',
            color: 'white',
            boxShadow: '0 4px 20px rgba(51, 87, 134, 0.2)',
            transition: 'all 0.2s ease-in-out',
            _hover: {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 25px rgba(51, 87, 134, 0.3)',
              background: '#39a169',
            }
          }}
        >
          {buttonText}
        </Button>

        {!isAuthenticated && (
          <Text
            fontSize="xs"
            color="gray.500"
            fontWeight="medium"
            cursor="pointer"
            onClick={onOpen}
            _hover={{
              color: '#39a169',
              textDecoration: 'underline',
            }}
            transition="all 0.2s ease"
            textAlign="center"
            letterSpacing="wide"
          >
            login
          </Text>
        )}
      </VStack>

      <LoginModal 
        isOpen={isOpen} 
        onClose={onClose}
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
}; 