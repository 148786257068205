import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Button,
} from '@chakra-ui/react';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const GET_MEMBERS_COUNT = gql`
  query GetClients {
    clients {
      id
    }
  }
`;

const Welcome = () => {
  const navigate = useNavigate();
  const { data } = useQuery(GET_MEMBERS_COUNT);
  const memberNumber = data?.clients?.length || 0;

  return (
    <Box minHeight="100vh" bg="#eaf5f2">
      <NavbarWithCallToAction />
      <Container maxW="container.md" py={20}>
        <VStack spacing={10} textAlign="center">
          <Box
            p={8}
            borderRadius="xl"
            bg="white"
            boxShadow="0 2px 10px rgba(0,0,0,0.05)"
            transition="all 0.3s ease"
            _hover={{
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              transform: "translateY(-2px)"
            }}
            w="full"
          >
            <VStack spacing={6}>
              <Heading 
                size="2xl"
                color="#2c5282"
                transition="all 0.3s ease"
                _hover={{ color: "#2b6cb0" }}
              >
                Welcome to Life Essentials Club!
              </Heading>
              
              <Text 
                fontSize="xl" 
                color="#2F855A" 
                fontWeight="bold"
                transition="all 0.3s ease"
                _hover={{ color: "#276749" }}
              >
                You are member #{memberNumber}
              </Text>
              
              <Text fontSize="lg" color="gray.700">
                Congratulations on becoming one of our founding members! 
                Your lifetime membership has been secured.
              </Text>

              <Button
                display="inline-flex"
                alignItems="center"
                gap={2}
                px={6}
                py={3}
                minH="3rem"
                fontSize="md"
                fontWeight="500"
                color="#2F855A"
                bg="transparent"
                border="2px solid"
                borderColor="current"
                borderRadius="md"
                transition="all 0.3s ease"
                onClick={() => navigate('/products')}
                _hover={{
                  color: "#276749",
                  bg: "#F0FFF4",
                  transform: "translateY(-2px)",
                  boxShadow: "4px 4px #C6F6D5",
                  textDecoration: "none"
                }}
                _active={{
                  transform: "translateY(0)",
                  boxShadow: "none"
                }}
                _focus={{
                  outline: "4px solid #9AE6B4",
                  outlineOffset: "-1px"
                }}
              >
                Start Exploring Products
                <Box as="svg"
                  h={5}
                  w={5}
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  transition="transform 0.3s ease"
                  _groupHover={{ transform: "translateX(4px)" }}
                >
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                </Box>
              </Button>
            </VStack>
          </Box>
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </Box>
  );
};

export default Welcome; 