import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Flex,
    Image,
    VStack,
} from "@chakra-ui/react";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";

export const Founders = () => {
    return (
        <Box position="relative" minH="100vh" display="flex" flexDirection="column">
            <NavbarWithCallToAction />
            
            {/* Base gradient background */}
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                sx={{
                    background: `
                        radial-gradient(circle at 0% 0%, rgba(51, 87, 134, 0.15) 0%, transparent 50%),
                        radial-gradient(circle at 100% 0%, rgba(57, 161, 105, 0.15) 0%, transparent 50%),
                        radial-gradient(circle at 0% 100%, rgba(234, 245, 242, 0.2) 0%, transparent 50%),
                        radial-gradient(circle at 100% 100%, rgba(51, 87, 134, 0.15) 0%, transparent 50%)
                    `,
                    filter: 'blur(60px)',
                }}
            />

            {/* Animated overlay */}
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                sx={{
                    background: `
                        repeating-linear-gradient(
                            45deg,
                            rgba(51, 87, 134, 0.03) 0%,
                            rgba(57, 161, 105, 0.03) 25%,
                            rgba(234, 245, 242, 0.03) 50%,
                            rgba(51, 87, 134, 0.03) 75%,
                            rgba(57, 161, 105, 0.03) 100%
                        )
                    `,
                    backgroundSize: '400% 400%',
                    animation: 'gradient 15s ease infinite',
                }}
            />

            {/* Content container */}
            <Box
                position="relative"
                zIndex="1"
                flex="1"
                bg="#eaf5f2"
                sx={{
                    background: `
                        linear-gradient(45deg, rgba(51, 87, 134, 0.05) 0%, transparent 20%),
                        linear-gradient(135deg, rgba(57, 161, 105, 0.05) 25%, transparent 45%),
                        linear-gradient(225deg, rgba(234, 245, 242, 0.1) 50%, transparent 75%),
                        linear-gradient(315deg, rgba(51, 87, 134, 0.05) 75%, transparent 100%)
                    `
                }}
            >
                <Container maxW="6xl" py={20}>
                    <VStack spacing={12} align="stretch">
                        {/* Introduction Section with floating effect */}
                        <Box 
                            p={8} 
                            bg="white"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            position="relative"
                            overflow="hidden"
                            sx={{
                                animation: 'float 6s ease-in-out infinite',
                                '&:hover': {
                                    '& > .decorative-circle': {
                                        transform: 'scale(1.1)',
                                    }
                                }
                            }}
                        >
                            {/* Decorative circle */}
                            <Box
                                className="decorative-circle"
                                position="absolute"
                                top="-20%"
                                right="-10%"
                                width="300px"
                                height="300px"
                                bg="rgba(234, 245, 242, 0.5)"
                                borderRadius="full"
                                transition="transform 0.3s ease"
                                zIndex={0}
                            />
                            
                            <Box position="relative" zIndex={1}>
                                <Heading 
                                    size="lg" 
                                    mb={6}
                                    color="#2F855A"
                                    transition="all 0.3s ease"
                                    _hover={{ color: "#276749" }}
                                >
                                    Our Vision for Resilient Communities
                                </Heading>
                                <Text color="gray.700" fontSize="lg" mb={4}>
                                    Life Essentials Club was founded with a clear mission: to build stronger, 
                                    more resilient communities through trusted local supply chains and sustainable practices. 
                                    We believe in connecting people with local producers, craftspeople, and service providers 
                                    who share our commitment to transparency, sustainability, and community wellbeing.
                                </Text>
                            </Box>
                        </Box>

                        {/* Sun's Section - Restructured with quote below photo */}
                        <Box 
                            p={8} 
                            bg="white"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.3s ease"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Flex direction={{ base: "column", md: "row" }} gap={8} align="center">
                                <Box flex="1">
                                    <Heading 
                                        size="lg" 
                                        mb={4}
                                        color="#2F855A"
                                        transition="all 0.3s ease"
                                        _hover={{ color: "#276749" }}
                                    >
                                        Sun Akquilah-Lehoiatua
                                    </Heading>
                                    <Text color="gray.700" fontSize="lg" mb={4}>
                                        For over 16 years, Sun has dedicated himself to unlocking the deeper truths of human existence,
                                        collaborating with scientists, monks, shamans, psychologists, pragmatists, and idealists to create
                                        experiences for lasting transformation.
                                    </Text>
                                    <Text color="gray.700" fontSize="lg" mb={4}>
                                        Sun believes in living life with purpose and authenticity, values he strives to embody every day. He
                                        works to create intimacy in relationships and inspire others to align with their most authentic selves.
                                    </Text>
                                    <Text color="gray.700" fontSize="lg" mb={4}>
                                        Sun offers transformational programs that span self-development, relationships, spiritual insights,
                                        career, and life fulfillment. Implementing ancient wisdom with modern science and utilising
                                        methods refined by more than 6,000 1:1 client sessions and more than 100 workshops these
                                        methods are proven effective. His passion lies in improving how we relate to ourselves, each other,
                                        and our Creator, making him not just a guide but a true companion on the journey to a more
                                        aligned, abundant life.
                                    </Text>
                                </Box>
                                <VStack flex="1" spacing={6}>
                                    <Box
                                        height="350px"
                                        width="100%"
                                        borderRadius="xl"
                                        overflow="hidden"
                                        position="relative"
                                    >
                                        <Image
                                            src="/sunprofile.jpg"
                                            alt="Sun Akquilah-Lehoiatua"
                                            objectFit="contain"
                                            width="100%"
                                            height="100%"
                                            bg="white"
                                        />
                                    </Box>
                                    <Text 
                                        color="#2F855A" 
                                        fontSize="lg" 
                                        fontStyle="italic" 
                                        p={4}
                                        borderLeft="4px solid"
                                        borderColor="#2F855A"
                                        bg="green.50"
                                        borderRadius="md"
                                    >
                                        "I'm here to help you push the boundaries of your authentic self-expression,
                                        being your truest self is the greatest gift you can give to yourself and the world.
                                        Our intimate relationships are often our greatest opportunity for such metamorphosis."
                                        <Text as="span" display="block" mt={2} fontWeight="500">
                                            - Sun
                                        </Text>
                                    </Text>
                                </VStack>
                            </Flex>
                        </Box>

                        {/* Tom's Section - Updated image styling */}
                        <Box 
                            p={8} 
                            bg="white"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.3s ease"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Flex direction={{ base: "column", md: "row" }} gap={8} align="center">
                                <Box
                                    flex="1"
                                    height="350px"
                                    borderRadius="xl"
                                    overflow="hidden"
                                    order={{ base: 2, md: 1 }}
                                    position="relative"
                                >
                                    <Image
                                        src="/tomprofile.jpeg"
                                        alt="Tom Miller"
                                        objectFit="contain"
                                        width="100%"
                                        height="100%"
                                        bg="white"
                                    />
                                </Box>
                                <Box flex="1" order={{ base: 1, md: 2 }}>
                                    <Heading 
                                        size="lg" 
                                        mb={4}
                                        color="#2F855A"
                                        transition="all 0.3s ease"
                                        _hover={{ color: "#276749" }}
                                    >
                                        Tom Miller
                                    </Heading>
                                    <Text color="gray.700" fontSize="lg">
                                        Tom brings his expertise in blockchain technology and supply chain transparency 
                                        to the Life Essentials Club. His work focuses on creating verifiable, trusted 
                                        networks that connect local producers with their communities, ensuring authenticity 
                                        and fair practices throughout the supply chain.
                                    </Text>
                                    <Text color="gray.700" fontSize="lg" mt={4}>
                                        Through his research and practical applications, Tom is dedicated to eliminating 
                                        nefarious activities from supply chains and empowering local communities to build 
                                        resilient, sustainable networks.
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>

                        {/* Mission Statement */}
                        <Box 
                            p={8} 
                            bg="white"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.3s ease"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Heading 
                                size="lg" 
                                mb={4}
                                color="#2F855A"
                                transition="all 0.3s ease"
                                _hover={{ color: "#276749" }}
                            >
                                Our Commitment
                            </Heading>
                            <Text color="gray.700" fontSize="lg">
                                Together, we're building a platform that connects conscious consumers with trusted local 
                                providers, fostering community resilience and sustainable practices. Through the Life 
                                Essentials Club, we're creating a network where transparency, sustainability, and community 
                                wellbeing are not just ideals, but daily practices.
                            </Text>
                        </Box>
                    </VStack>
                </Container>
            </Box>

            <FooterWithFourColumns />

            {/* Add the animations */}
            <style>{`
                @keyframes gradient {
                    0% { background-position: 0% 50% }
                    50% { background-position: 100% 50% }
                    100% { background-position: 0% 50% }
                }
                
                @keyframes float {
                    0% { transform: translateY(0px) }
                    50% { transform: translateY(-10px) }
                    100% { transform: translateY(0px) }
                }
                
                @keyframes pulse {
                    0% { transform: scale(1); opacity: 0.8 }
                    50% { transform: scale(1.1); opacity: 0.5 }
                    100% { transform: scale(1); opacity: 0.8 }
                }
            `}</style>
        </Box>
    );
};

export default Founders; 