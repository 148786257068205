import React, { createContext, useContext, useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { jwtDecode } from 'jwt-decode';

const GET_CLIENT = gql`
  query GetClientByEmail($email: String!) {
    clientByEmail(email: $email) {
      id
      email
      fName
      lName
      phoneNumber
      role
      isVerifiedSeller
      businessName
      businessRegistrationNumber
      shippingAddresses {
        name
        phone
        address {
          street
          city
          state
          postcode
          country
        }
        isDefault
        instructions
      }
      billingAddresses {
        name
        phone
        address {
          street
          city
          state
          postcode
          country
        }
        isDefault
        instructions
      }
      paymentReceivingDetails {
        acceptedMethods
        bankAccount {
          accountName
          bsb
          accountNumber
          bankName
          swiftCode
        }
        cryptoWallets {
          walletAddress
          network
          memo
        }
        stripeConnect {
          stripeAccountId
          accountVerified
          verifiedAt
        }
        paypalEmail
        isVerified
      }
      permissions
      encryptedSignatureIpfsUrl
      createdAt
      updatedAt
      tenantId
    }
  }
`;

interface AuthContextType {
  isAuthenticated: boolean;
  user: any | null; // Replace 'any' with your Client type
  loading: boolean;
  logout: () => void;
  refreshAuth: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState<string | null>(null);

  // Query for client data when we have an email
  const { data: clientData } = useQuery(GET_CLIENT, {
    variables: { email: userEmail },
    skip: !userEmail,
    onCompleted: (data) => {
      console.log('Client data fetched:', data.clientByEmail);
      setUser(data.clientByEmail);
      setLoading(false);
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      try {
        const decoded = jwtDecode<{ email: string }>(token);
        setIsAuthenticated(true);
        setUserEmail(decoded.email);
        console.log('JWT decoded, email:', decoded.email);
      } catch (error) {
        console.error('Invalid token:', error);
        logout();
      }
    } else {
      setLoading(false);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('auth_token');
    setIsAuthenticated(false);
    setUser(null);
    setUserEmail(null);
    console.log('User logged out');
  };

  const refreshAuth = () => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      try {
        const decoded = jwtDecode<{ email: string }>(token);
        setIsAuthenticated(true);
        setUserEmail(decoded.email);
        console.log('Auth refreshed, email:', decoded.email);
      } catch (error) {
        console.error('Invalid token during refresh:', error);
        logout();
      }
    } else {
      setLoading(false);
    }
  };

  const value = {
    isAuthenticated,
    user,
    loading,
    logout,
    refreshAuth,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
