// components/chakra/NavbarWithCallToAction/NavbarWithCallToAction.tsx

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  IconButton,
  Link,
  Stack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Logo } from '../../Logo';
import { MobileDrawer } from './MobileNavbar';
import { ToggleButton } from './ToggleButton';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { SecureMembershipButton } from '../../SecureMembershipButton';

const Links = [

  // { name: 'Business Services', path: '/business-services' },
  // { name: 'Programs', path: '/programs' },
  { name: 'About', path: '/founders' },
  { name: 'Governance', path: '/governance' },
  { name: 'Events', path: '/sessions/client-booking-calendar?week=2025-03-29T14%3A00%3A00.000Z' },
  { name: 'Contact', path: '/founders' },
];

export const NavbarWithCallToAction = () => {
  const isDesktop = useBreakpointValue({ base: false, '3xl': true });
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  const { isAuthenticated, user, logout } = useAuth();

  const handleAuthAction = async () => {
    if (isAuthenticated) {
      await logout();
      navigate('/');
    } else {
      navigate('/login');
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <Box 
      position="sticky" 
      top={0} 
      zIndex="sticky"
      bg="#eaf5f2"
      borderBottom="1px solid"
      borderColor="rgba(51, 87, 134, 0.2)"
      boxShadow="0 2px 10px rgba(51, 87, 134, 0.1)"
    >
      <Container maxW="7xl">
        <Flex
          minH={'60px'}
          py={{ base: 2 }}
          align={'center'}
          justify={'space-between'}
        >
          <Link onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
            <Logo />
          </Link>
          
          <HStack spacing={8} display={{ base: 'none', md: 'flex' }}>
            {Links.map((link) => (
              <Link
                key={link.name}
                px={2}
                py={1}
                rounded={'md'}
                color="#335786"
                _hover={{
                  textDecoration: 'none',
                  color: '#39a169',
                  bg: 'rgba(51, 87, 134, 0.08)',
                }}
                href={link.path}
              >
                {link.name}
              </Link>
            ))}
          </HStack>

          <HStack spacing={4}>
            {isAuthenticated ? (
              <Button
                onClick={handleAuthAction}
                size="sm"
                px={6}
                sx={{
                  background: '#335786',
                  color: 'white',
                  boxShadow: '0 4px 20px rgba(51, 87, 134, 0.2)',
                  transition: 'all 0.2s ease-in-out',
                  _hover: {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 25px rgba(51, 87, 134, 0.3)',
                    background: '#39a169',
                  }
                }}
              >
                Logout
              </Button>
            ) : (
              <SecureMembershipButton />
            )}
            
            <IconButton
              display={{ base: 'flex', md: 'none' }}
              onClick={onToggle}
              icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
              variant={'ghost'}
              color="#335786"
              aria-label={'Toggle Navigation'}
              _hover={{
                color: '#39a169',
                bg: 'rgba(51, 87, 134, 0.08)',
              }}
            />
          </HStack>
        </Flex>

        {/* Mobile menu */}
        <Stack
          display={{ base: isOpen ? 'flex' : 'none', md: 'none' }}
          p={4}
          bg="#eaf5f2"
        >
          {Links.map((link) => (
            <Link
              key={link.name}
              px={2}
              py={1}
              rounded={'md'}
              color="#335786"
              _hover={{
                textDecoration: 'none',
                color: '#39a169',
                bg: 'rgba(51, 87, 134, 0.08)',
              }}
              href={link.path}
            >
              {link.name}
            </Link>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};