import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Text,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

const REQUEST_AUTH = gql`
  mutation RequestAuth($input: AuthInput!) {
    requestAuth(input: $input)
  }
`;

const VERIFY_AUTH = gql`
  mutation VerifyAuth($input: VerifyInput!) {
    verifyAuth(input: $input)
  }
`;

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLoginSuccess?: () => void;
}

export const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [verificationWords, setVerificationWords] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const toast = useToast();

  const [requestAuth, { loading: requestLoading }] = useMutation(REQUEST_AUTH);
  const [verifyAuth, { loading: verifyLoading }] = useMutation(VERIFY_AUTH);

  const handleRequestAuth = async () => {
    try {
      const { data } = await requestAuth({
        variables: {
          input: { email: email.toLowerCase() }
        }
      });

      if (data.requestAuth) {
        setEmailSent(true);
        toast({
          title: "Verification email sent",
          description: "Please check your email for the verification words",
          status: "success",
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to send verification email",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleVerifyAuth = async () => {
    try {
      const { data } = await verifyAuth({
        variables: {
          input: {
            email: email.toLowerCase(),
            words: verificationWords.toLowerCase()
          }
        }
      });

      if (data.verifyAuth) {
        localStorage.setItem('auth_token', data.verifyAuth);
        toast({
          title: "Success!",
          description: "You have been successfully logged in",
          status: "success",
          duration: 3000,
        });
        
        if (onLoginSuccess) {
          onLoginSuccess();
        } else {
          onClose();
          window.location.reload();
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Verification failed",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleClose = () => {
    setEmail('');
    setVerificationWords('');
    setEmailSent(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Login Required</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4}>
            {!emailSent ? (
              <Box w="full">
                <FormControl>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    placeholder="Enter your email"
                  />
                </FormControl>
                <Button
                  display="inline-flex"
                  alignItems="center"
                  gap={2}
                  px={6}
                  py={3}
                  minH="3rem"
                  fontSize="md"
                  fontWeight="500"
                  color="#2F855A"
                  bg="transparent"
                  border="2px solid"
                  borderColor="current"
                  borderRadius="md"
                  transition="all 0.3s ease"
                  onClick={handleRequestAuth}
                  isLoading={requestLoading}
                  w="full"
                  mt={4}
                  _hover={{
                    color: "#276749",
                    bg: "#F0FFF4",
                    transform: "translateY(-2px)",
                    boxShadow: "4px 4px #C6F6D5",
                    textDecoration: "none"
                  }}
                  _active={{
                    transform: "translateY(0)",
                    boxShadow: "none"
                  }}
                  _focus={{
                    outline: "4px solid #9AE6B4",
                    outlineOffset: "-1px"
                  }}
                >
                  Send Verification Email
                  <Box as="svg"
                    h={5}
                    w={5}
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    transition="transform 0.3s ease"
                    _groupHover={{ transform: "translateX(4px)" }}
                  >
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                  </Box>
                </Button>
              </Box>
            ) : (
              <Box w="full">
                <Text mb={4}>
                  Please enter the verification words sent to your email.
                </Text>
                <FormControl>
                  <FormLabel>Verification Words</FormLabel>
                  <Input
                    value={verificationWords}
                    onChange={(e) => setVerificationWords(e.target.value.toLowerCase())}
                    placeholder="Enter verification words"
                  />
                </FormControl>
                <Button
                  display="inline-flex"
                  alignItems="center"
                  gap={2}
                  px={6}
                  py={3}
                  minH="3rem"
                  fontSize="md"
                  fontWeight="500"
                  color="#2F855A"
                  bg="transparent"
                  border="2px solid"
                  borderColor="current"
                  borderRadius="md"
                  transition="all 0.3s ease"
                  onClick={handleVerifyAuth}
                  isLoading={verifyLoading}
                  w="full"
                  mt={4}
                  _hover={{
                    color: "#276749",
                    bg: "#F0FFF4",
                    transform: "translateY(-2px)",
                    boxShadow: "4px 4px #C6F6D5",
                    textDecoration: "none"
                  }}
                  _active={{
                    transform: "translateY(0)",
                    boxShadow: "none"
                  }}
                  _focus={{
                    outline: "4px solid #9AE6B4",
                    outlineOffset: "-1px"
                  }}
                >
                  Verify
                  <Box as="svg"
                    h={5}
                    w={5}
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    transition="transform 0.3s ease"
                    _groupHover={{ transform: "translateX(4px)" }}
                  >
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                  </Box>
                </Button>
              </Box>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}; 