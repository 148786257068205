import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  OrderedList,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Textarea,
  Select,
  Checkbox,
  useDisclosure,
} from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { gql, useMutation } from '@apollo/client';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { LoginModal } from '../components/LoginModal';

// Add the mutation
const CREATE_CLIENT = gql`
  mutation CreateClient($input: ClientInput!) {
    createClient(input: $input) {
      id
      fName
      lName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;

// Add phone input styles
const phoneInputStyle = {
  width: '100%',
  padding: '8px',
  borderRadius: '6px',
  border: '1px solid #E2E8F0',
  fontSize: '16px'
};

const Agreement = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [formData, setFormData] = useState({
    firstName: '',
    familyName: '',
    email: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postcode: '',
    skills: '',
    interests: '',
    contribution: '',
    needs: '',
    date: new Date().toISOString().split('T')[0],
    agreeToTerms: false
  });

  // Add the mutation hook
  const [createClient, { loading: submitLoading }] = useMutation(CREATE_CLIENT);

  // Update authentication check
  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('auth_token');
      const adminPassword = localStorage.getItem('admin_password');
      setIsAuthenticated(!!(token || adminPassword === "123456"));
    };

    checkAuth();
    // Add event listener for storage changes
    window.addEventListener('storage', checkAuth);
    
    return () => {
      window.removeEventListener('storage', checkAuth);
    };
  }, []);

  // Auto-populate email when available
  useEffect(() => {
    if (user?.email) {
      setFormData(prev => ({
        ...prev,
        email: user?.email
      }));
    }
  }, [user?.email]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.agreeToTerms) {
      toast({
        title: "Agreement Required",
        description: "Please agree to the terms and conditions",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!formData.firstName || !formData.familyName || !formData.email || !formData.phoneNumber) {
      toast({
        title: "Missing Information",
        description: "Please fill in all required fields including phone number",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Create client using the mutation
      const { data } = await createClient({
        variables: {
          input: {
            fName: formData.firstName,
            lName: formData.familyName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
          }
        }
      });

      toast({
        title: "Agreement Signed",
        description: "Thank you for signing the agreement",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Redirect to welcome page
      navigate('/welcome');
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error submitting your agreement. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prev => ({ ...prev, [name]: checked }));
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Box bg="linear-gradient(180deg, #eaf5f2 0%, #f7fbfa 100%)">
        <Container maxW="container.lg" py={10}>
          <VStack spacing={8} align="stretch">
            <Box
              bg="white"
              p={8}
              borderRadius="xl"
              boxShadow="0 4px 20px rgba(0,0,0,0.05)"
              transition="all 0.3s ease"
              _hover={{
                boxShadow: "0 6px 30px rgba(0,0,0,0.1)",
                transform: "translateY(-2px)"
              }}
            >
              <Heading
                as="h1"
                size="2xl"
                textAlign="center"
                color="#2c5282"
                mb={6}
                transition="color 0.3s ease"
                _hover={{ color: "#2b6cb0" }}
              >
                Life Essentials Club Member Agreement
              </Heading>

              <Heading
                as="h2"
                size="lg"
                mb={4}
                color="#2c5282"
                transition="all 0.3s ease"
                _hover={{
                  color: "#2b6cb0",
                  transform: "translateX(5px)"
                }}
              >
                Introduction
              </Heading>

              <Box mb={4}>
                <Text>
                  Welcome to the Life Essentials Club Membership. This agreement outlines the principles
                  and responsibilities of membership within our Club Ecosystem (from hereon referred to
                  as "Our Ecosystem"), emphasizing collaboration to ensure that all members have access
                  to life essentials.
                </Text>
              </Box>

              <Box mb={4}>


                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Purpose
                </Heading>
                <Text>
                  The Life Essentials Club is founded on the belief that as a social species, we thrive
                  through cooperation and collaboration within our ecosystem. Our mission is to collectively
                  deliver life essentials to all people; fostering a sustainable, supportive, and equitable
                  community.
                </Text>
              </Box>

              <Box>

                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Membership Principles
                </Heading>

                <Heading as="h3" size="md" mb={3} mt={6}>
                  Equity and Inclusivity
                </Heading>
                <Text mb={4}>
                  All members shall have equal access to the resources and services provided by the Club,
                  regardless of their socio-economic status, with special mechanisms in place to support
                  those in need.
                </Text>

                <Text fontWeight="bold" mb={2}>Examples of Special Mechanisms to Support Those in Need:</Text>
                <UnorderedList spacing={2} mb={6}>
                  <ListItem>Skill-Based Scholarships: Offering education or training opportunities free or at a reduced cost to members who cannot afford them but show interest or need.</ListItem>
                  <ListItem>Community Pantries: Setting up free food stations where members can take what they need without any stigma or formal process: integrated with Good Harvest, Life Essentials members can volunteer and earn club points for helping to operate the community pantries.</ListItem>
                  <ListItem>Sliding Scale Fees: For services or resources, fees adjusted based on income to ensure everyone can participate.</ListItem>
                  <ListItem>Shared Housing or Co-living Spaces: For members facing housing insecurity, providing access to safe, affordable living spaces.</ListItem>
                  <ListItem>Emergency Funds: A fund specifically for unexpected financial crises, accessible to members based on need rather than repayment ability.</ListItem>
                  <ListItem>Mentorship Programs: Pairing up members with skills or experience in areas like finance, health, or law to guide those in need/apprentice those committed.</ListItem>
                </UnorderedList>

                <Heading as="h3" size="md" mb={3}>
                  Cooperation and Collaboration
                </Heading>
                <Text mb={4}>
                  Members commit to actively participate in the collective efforts to share, produce,
                  or distribute life essentials. This includes participating in cooperative projects,
                  sharing skills, and supporting each other's needs.
                </Text>

                <Text fontWeight="bold" mb={2}>Examples of Communicating Offers to the Ecosystem:</Text>
                <UnorderedList spacing={2} mb={6}>
                  <ListItem>"Plumbing Pals" Initiative: A plumber offers to install home water filters for members, perhaps in exchange for other services or simply as a community service.</ListItem>
                  <ListItem>Skill Swap Sessions: Members post offers like "I can teach basic coding in exchange for someone to help me garden" on a community board or app.</ListItem>
                  <ListItem>Time Banking: Members offer their time for tasks like tutoring, childcare, or home repairs, with hours banked and exchanged among participants.</ListItem>
                  <ListItem>Harvest Sharing: Gardeners or farmers share surplus produce, announced via community alerts or at communal gatherings.</ListItem>
                  <ListItem>Repair Cafés: Regular events where tech-savvy, sewing, or mechanical repair enthusiasts offer their skills to fix items.</ListItem>
                </UnorderedList>
              </Box>

              <Box>

                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Sustainability and Environmental Stewardship
                </Heading>
                <Text mb={4}>
                  We pledge to manage resources in a way that respects and preserves the natural environment,
                  promoting practices that support our ecosystem, like reusing, re-purposing, up-cycling,
                  recycling, using regenerative/organic farming.
                </Text>
              </Box>

              <Box>

                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Community Resilience
                </Heading>
                <Text mb={4}>
                  The Club aims to enhance personal resilience and community cohesion by fostering a network
                  where members can rely on each other for support during crises or daily needs.
                </Text>

                <Text fontStyle="italic" mb={4}>
                  (future activity when we have 300 members, introduce wants and needs matchmaking lists to
                  connect with resource custodians)
                </Text>

                <UnorderedList mb={6}>
                  <ListItem>insert a mechanism that identifies crisis types and daily needs</ListItem>
                  <ListItem>make it a member imperative to be a support buddy for someone in their network</ListItem>
                  <ListItem>flag members that do not have 5 support buddies and develop activities to promote pairing up</ListItem>
                </UnorderedList>

                <Heading as="h3" size="md" mb={3}>
                  Mechanism to Identify Crisis Types and Daily Needs
                </Heading>
                <Text mb={4}>
                  Quarterly participant assessment and editing of the below activities and their frequencies:
                </Text>
                <UnorderedList spacing={4} mb={6}>
                  <ListItem>Needs Assessment Surveys: Regularly update your listed needs on your member dashboard*, both urgent (like immediate financial or housing support) and daily (like assistance with chores, transport, or emotional support). *dashboard coming soon.</ListItem>
                  <ListItem>Crisis and Support Dashboard: A digital tool where members can post or view current crises or ongoing needs, with options for anonymous submissions.</ListItem>
                  <ListItem>
                    Member Imperative to be a Support Buddy:
                    <UnorderedList ml={4} mt={2}>
                      <ListItem>Buddy System: Each member is required to be a support buddy to at least one other member where there is mutual consent, fostering direct, personal connections.</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    Flagging Members Without Support Buddies:
                    <UnorderedList ml={4} mt={2}>
                      <ListItem>Social Responsibility: If you know or suspect that a member does not have a support buddy, please speak with your fellow members about how best to connect your peer to a support buddy.</ListItem>
                      <ListItem>Buddy Formation Events: Host activities specifically designed for members to meet and form buddy relationships, like communal dinners, workshops, or adventure days.</ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </Box>

              <Box>

                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Mutualism and Symbiosis
                </Heading>
                <Text mb={4}>
                  Inspired by nature's ecosystems, our interactions are based on mutual benefit, where the
                  success of one member contributes to the well-being of all.
                </Text>

                <Text fontWeight="bold" mb={2}>Ideas to Accomplish/Demonstrate Mutualism and Symbiosis:</Text>
                <UnorderedList spacing={2} mb={6}>
                  <ListItem>Resource Sharing Announcements: Regular community updates where members post/record what they need and what they can offer, fostering a culture of exchange where everyone benefits.</ListItem>
                  <ListItem>Skill and Resource Libraries: A system where members can "loan" out their skills or items to others, ensuring that everyone's contributions are recognized and utilized.</ListItem>
                  <ListItem>Symbiotic Projects: Collaborative projects like community gardens where the produce is shared, or collective art projects where everyone contributes and benefits from the final product.</ListItem>
                  <ListItem>Success Stories Sharing: Regularly highlight stories where one member's success has directly or indirectly benefited others in the community.</ListItem>
                  <ListItem>Feedback Loops: Implement systems where members can give feedback on how they've been helped and how they've helped others.</ListItem>
                </UnorderedList>
              </Box>

              <Box mb={4}>

                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Member Responsibilities
                </Heading>

                <VStack align="stretch" spacing={4}>
                  <Box>
                    <Text fontWeight="bold">Participation:</Text>
                    <Text>Engage in at least one Club activity monthly, which could include workshops, our ecosystem contributions, or community service projects.</Text>
                  </Box>

                  <Box>
                    <Text fontWeight="bold">Resource Contribution:</Text>
                    <Text>Share your time, skills, or resources in ways that contribute to the Club's goals.</Text>
                  </Box>

                  <Box>
                    <Text fontWeight="bold">Respect and Support:</Text>
                    <Text>Treat all members with respect, fostering an environment where everyone feels valued and supported.</Text>
                  </Box>

                  <Box>
                    <Text fontWeight="bold">Regenerative Practices:</Text>
                    <Text>Adopt and promote practices that reduce/repurpose waste, recycle materials, and manage resources in a regenerative manner.</Text>
                  </Box>

                  <Box>
                    <Text fontWeight="bold">Conflict Resolution:</Text>
                    <Text>Commit to resolving disputes through mediation, great questions and dialogue, aiming for solutions that benefit all involved and our ecosystem.</Text>
                  </Box>
                </VStack>
              </Box>

              <Box mb={4}>

                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Rights of Members
                </Heading>

                <VStack align="stretch" spacing={4}>
                  <Box>
                    <Text fontWeight="bold">Access to Essentials:</Text>
                    <Text>You have the right to access the life essentials provided or facilitated by the Club, including but not limited to food, education, well-being services, and shelter support.</Text>
                  </Box>

                  <Box>
                    <Text fontWeight="bold">Decision Making:</Text>
                    <Text>Participate in the governance of the Club through voting processes, including voting on key decisions that affect the community.</Text>
                  </Box>

                  <Box>
                    <Text fontWeight="bold">Privacy and Respect:</Text>
                    <Text>Your personal information will be kept confidential, and your right to privacy and dignity will be respected.</Text>
                  </Box>
                </VStack>
              </Box>

              <Box mb={4}>

                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Termination of Membership
                </Heading>
                <Text>
                  Membership can be terminated by mutual agreement or if a member consistently fails to uphold
                  the responsibilities outlined above. Termination will follow a fair process, including mediation.
                </Text>
              </Box>

              <Box mb={4}>

                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Amendments
                </Heading>
                <Text>
                  This agreement can be amended with the consent of the majority of members through a formal
                  vote at a community meeting where all members have been sent an invitation to vote via SMS
                  to their nominated mobile phone number.
                </Text>
              </Box>

              <Box mb={4}>

                <Heading
                  as="h2"
                  size="lg"
                  mb={4}
                  color="#2c5282"
                  transition="all 0.3s ease"
                  _hover={{
                    color: "#2b6cb0",
                    transform: "translateX(5px)"
                  }}
                >
                  Acknowledgment
                </Heading>
                <Text mb={6}>
                  By providing your sign of nature below, you acknowledge that you are part of the natural
                  world as a living man or woman and have read, understood, and agree to abide by the
                  principles and responsibilities outlined in this Life Essentials Club Private Member
                  Agreement. You understand that this Club operates under the ethos of collaboration,
                  mutual support, and ecological stewardship as part of the broader mission of Mother
                  Nature Ministry.
                </Text>

                <Text fontWeight="bold" mb={4}>
                  Sign of Nature:
                </Text>
              </Box>

              {!isAuthenticated ? (
                <Box textAlign="center" py={6}>
                  <Button
                    display="inline-flex"
                    alignItems="center"
                    gap={2}
                    px={6}
                    py={3}
                    minH="3rem"
                    fontSize="md"
                    fontWeight="500"
                    color="#2F855A"
                    bg="transparent"
                    border="2px solid"
                    borderColor="current"
                    borderRadius="md"
                    transition="all 0.3s ease"
                    onClick={onOpen}
                    _hover={{
                      color: "#276749",
                      bg: "#F0FFF4",
                      transform: "translateY(-2px)",
                      boxShadow: "4px 4px #C6F6D5",
                      textDecoration: "none"
                    }}
                    _active={{
                      transform: "translateY(0)",
                      boxShadow: "none"
                    }}
                    _focus={{
                      outline: "4px solid #9AE6B4",
                      outlineOffset: "-1px"
                    }}
                  >
                    Sign Agreement
                    <Box as="svg"
                      h={5}
                      w={5}
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      transition="transform 0.3s ease"
                      _groupHover={{ transform: "translateX(4px)" }}
                    >
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                    </Box>
                  </Button>
                  <Text mt={4} fontSize="sm" color="gray.600">
                    Please login to sign the agreement
                  </Text>
                </Box>
              ) : (
                <Box
                  as="form"
                  onSubmit={handleSubmit}
                  bg="#f8faf9"
                  p={8}
                  borderRadius="lg"
                  boxShadow="0 2px 10px rgba(0,0,0,0.05)"
                  transition="all 0.3s ease"
                  _hover={{
                    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                    transform: "translateY(-2px)"
                  }}
                >
                  <Heading as="h3" size="md" mb={6}>
                    Sign Agreement
                  </Heading>

                  <VStack spacing={4}>
                    <FormControl isRequired>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="Enter your first name"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Family Name</FormLabel>
                      <Input
                        name="familyName"
                        value={formData.familyName}
                        onChange={handleChange}
                        placeholder="Enter your family name"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Email</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        value={formData.email}
                        isReadOnly
                        bg="gray.50"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Phone Number</FormLabel>
                      <PhoneInput
                        international
                        defaultCountry="AU"
                        value={formData.phoneNumber}
                        onChange={(value) => {
                          console.log('Phone input value:', value);
                          setFormData(prev => ({ ...prev, phoneNumber: value || '' }))
                        }}
                        placeholder="Enter phone number"
                        style={phoneInputStyle}
                      />
                    </FormControl>

                    {/* <FormControl>
                      <FormLabel>Address</FormLabel>
                      <Textarea
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        placeholder="Enter your address"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>City</FormLabel>
                      <Input
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        placeholder="Enter your city"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>State</FormLabel>
                      <Input
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        placeholder="Enter your state"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Country</FormLabel>
                      <Input
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        placeholder="Enter your country"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Postcode</FormLabel>
                      <Input
                        name="postcode"
                        value={formData.postcode}
                        onChange={handleChange}
                        placeholder="Enter your postcode"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Skills</FormLabel>
                      <Textarea
                        name="skills"
                        value={formData.skills}
                        onChange={handleChange}
                        placeholder="What skills can you share with the community?"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Interests</FormLabel>
                      <Textarea
                        name="interests"
                        value={formData.interests}
                        onChange={handleChange}
                        placeholder="What are your interests?"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>How would you like to contribute?</FormLabel>
                      <Textarea
                        name="contribution"
                        value={formData.contribution}
                        onChange={handleChange}
                        placeholder="How would you like to contribute to the community?"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>What support do you need?</FormLabel>
                      <Textarea
                        name="needs"
                        value={formData.needs}
                        onChange={handleChange}
                        placeholder="What support do you need from the community?"
                      />
                    </FormControl> */}

                    <FormControl isRequired>
                      <FormLabel>Date</FormLabel>
                      <Input
                        name="date"
                        type="date"
                        value={new Date().toISOString().split('T')[0]}
                        isReadOnly
                        bg="gray.50"
                      />
                    </FormControl>

                    <Box width="100%" mt={6} pt={6} borderTop="1px" borderColor="gray.200">
                      <Heading as="h3" size="md" mb={4}>
                        For Mother Nature Ministry:
                      </Heading>
                      <FormControl>
                        <FormLabel>Representative Name</FormLabel>
                        <Input
                          name="representativeName"
                          placeholder="Representative Name"
                          isReadOnly
                          value="Mother Nature Ministry Representative"
                          bg="gray.50"
                        />
                      </FormControl>
                    </Box>

                    <FormControl isRequired>
                      <Checkbox
                        name="agreeToTerms"
                        isChecked={formData.agreeToTerms}
                        onChange={handleCheckbox}
                      >
                        I agree to the terms and conditions
                      </Checkbox>
                    </FormControl>

                    <Button
                      type="submit"
                      display="inline-flex"
                      alignItems="center"
                      gap={2}
                      px={6}
                      py={3}
                      minH="3rem"
                      fontSize="md"
                      fontWeight="500"
                      color="#2F855A"
                      bg="transparent"
                      border="2px solid"
                      borderColor="current"
                      borderRadius="md"
                      transition="all 0.3s ease"
                      _hover={{
                        color: "#276749",
                        bg: "#F0FFF4",
                        transform: "translateY(-2px)",
                        boxShadow: "4px 4px #C6F6D5",
                        textDecoration: "none"
                      }}
                      _active={{
                        transform: "translateY(0)",
                        boxShadow: "none"
                      }}
                      _focus={{
                        outline: "4px solid #9AE6B4",
                        outlineOffset: "-1px"
                      }}
                    >
                      Sign Agreement
                      <Box as="svg"
                        h={5}
                        w={5}
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        transition="transform 0.3s ease"
                        _groupHover={{ transform: "translateX(4px)" }}
                      >
                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                      </Box>
                    </Button>

                    <Box mt={6} pt={6} borderTop="1px" borderColor="gray.200">
                      <Text fontSize="sm" color="gray.600" fontStyle="italic" textAlign="center">
                        This document facilitates member collaboration within our Club Ecosystem to
                        collectively deliver our life essentials, ensuring that we function as a harmonious,
                        self-sufficient community inspired by the natural world's interconnected systems.
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              )}
            </Box>
          </VStack>
        </Container>
        <FooterWithFourColumns />
      </Box>
      
      <LoginModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Agreement; 