import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { NavbarWithCallToAction } from '../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const GET_SESSION_TYPE = gql`
  query GetSessionTypeById($id: ID!) {
    sessionTypeById(id: $id) {
      id
      name
      description
      format
      duration
      price
      color
    }
  }
`;

const UPDATE_SESSION_TYPE = gql`
  mutation UpdateSessionType($id: ID!, $input: SessionTypeInput!) {
    updateSessionType(id: $id, input: $input) {
      id
      name
      description
      format
      duration
      price
      color
    }
  }
`;

interface SessionTypeFormData {
  name: string;
  description?: string;
  format: 'INDIVIDUAL' | 'GROUP';
  duration: number;
  price: number;
  color?: string;
}

export const EditSessionType = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [formData, setFormData] = useState<SessionTypeFormData | null>(null);

  const { data: queryData, loading: queryLoading } = useQuery(GET_SESSION_TYPE, {
    variables: { id },
    onCompleted: (data) => {
      console.log('Received session type data:', data.sessionTypeById);
      if (data.sessionTypeById) {
        setFormData(data.sessionTypeById);
      }
    },
  });

  const [updateSessionType, { loading: updateLoading }] = useMutation(UPDATE_SESSION_TYPE);

  if (queryLoading || !formData) {
    return <Box p={8}>Loading...</Box>;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    console.log('Submitting updated session type:', {
      id,
      input: {
        name: formData.name,
        description: formData.description,
        format: formData.format,
        duration: Number(formData.duration),
        price: Number(formData.price),
        color: formData.color
      }
    });

    try {
      const { data } = await updateSessionType({
        variables: {
          id,
          input: {
            name: formData.name,
            description: formData.description,
            format: formData.format,
            duration: Number(formData.duration),
            price: Number(formData.price),
            color: formData.color
          }
        },
        refetchQueries: ['GetSessionTypes']
      });

      console.log('Updated session:', data.updateSessionType);
      
      toast({
        title: 'Session type updated',
        status: 'success',
        duration: 3000,
      });
      navigate('/sessions/types');
    } catch (error) {
      console.error('Error updating session type:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'An error occurred',
        status: 'error',
        duration: 5000,
      });
    }
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.md" py={8}>
        <VStack spacing={8} align="stretch">
          <Heading size="lg">Edit Session Type</Heading>
          
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Input
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Format</FormLabel>
                <Select
                  value={formData.format}
                  onChange={(e) => setFormData({ ...formData, format: e.target.value as 'INDIVIDUAL' | 'GROUP' })}
                >
                  <option value="INDIVIDUAL">Individual</option>
                  <option value="GROUP">Group</option>
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Duration (minutes)</FormLabel>
                <NumberInput
                  value={formData.duration}
                  onChange={(_, value) => setFormData({ ...formData, duration: value })}
                  min={15}
                  step={15}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Price ($)</FormLabel>
                <NumberInput
                  value={formData.price}
                  onChange={(_, value) => setFormData({ ...formData, price: value })}
                  min={0}
                  precision={2}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Color</FormLabel>
                <Input
                  type="color"
                  value={formData.color}
                  onChange={(e) => setFormData({ ...formData, color: e.target.value })}
                />
              </FormControl>

              <Button 
                type="submit" 
                colorScheme="blue" 
                w="full" 
                isLoading={updateLoading}
              >
                Update Session Type
              </Button>
            </VStack>
          </form>
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </>
  );
}; 