import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Image,
  Divider,
  useToast,
  Center,
  Spinner,
  Code,
  UnorderedList,
  ListItem,
  Link,
  Badge,
} from '@chakra-ui/react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const GET_CLIENT_AGREEMENT = gql`
  query Query($clientId: ID!) {
    client(id: $clientId) {
      id
      lName
      fName
      encryptedSignatureIpfsUrl
      email
      createdAt
    }
  }
`;

const GET_FILE = gql`
  query GetFile($hash: String!) {
    getFile(hash: $hash)
  }
`;

const SignedAgreement = () => {
  const { id } = useParams();
  const toast = useToast();
  const [signatureUrl, setSignatureUrl] = useState<string | null>(null);
  
  const { loading, error, data } = useQuery(GET_CLIENT_AGREEMENT, {
    variables: { clientId: id },
  });

  const [getFile] = useLazyQuery(GET_FILE);

  useEffect(() => {
    console.log('Query Response:', { loading, error, data });
    if (error) {
      console.error('GraphQL Error:', error);
      toast({
        title: "Error loading certificate",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, data, loading, toast]);

  // Load and decrypt signature when available
  useEffect(() => {
    const loadSignature = async () => {
      if (data?.client?.encryptedSignatureIpfsUrl) {
        try {
          const result = await getFile({
            variables: { hash: data.client.encryptedSignatureIpfsUrl }
          });

          if (result.data?.getFile) {
            // Convert base64 to blob
            const binaryString = window.atob(result.data.getFile);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes]);
            const url = URL.createObjectURL(blob);
            setSignatureUrl(url);
          }
        } catch (error) {
          console.error('Signature download error:', error);
          toast({
            title: "Error loading signature",
            description: error instanceof Error ? error.message : "Unknown error occurred",
            status: "error",
            duration: 5000,
          });
        }
      }
    };

    loadSignature();
  }, [data?.client?.encryptedSignatureIpfsUrl, getFile, toast]);

  // Cleanup blob URL when component unmounts
  useEffect(() => {
    return () => {
      if (signatureUrl) {
        URL.revokeObjectURL(signatureUrl);
      }
    };
  }, [signatureUrl]);

  if (loading) return (
    <Center h="100vh">
      <Spinner size="xl" />
    </Center>
  );

  if (error) return null;

  const client = data?.client;
  const agreementDate = new Date(client?.createdAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <>
      <NavbarWithCallToAction />
      <Box bg="linear-gradient(180deg, #eaf5f2 0%, #f7fbfa 100%)" minH="100vh">
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          sx={{
            background: `
              radial-gradient(circle at 0% 0%, rgba(51, 87, 134, 0.1) 0%, transparent 50%),
              radial-gradient(circle at 100% 0%, rgba(57, 161, 105, 0.1) 0%, transparent 50%),
              radial-gradient(circle at 0% 100%, rgba(234, 245, 242, 0.2) 0%, transparent 50%),
              radial-gradient(circle at 100% 100%, rgba(51, 87, 134, 0.1) 0%, transparent 50%)
            `,
            filter: 'blur(60px)',
          }}
        />

        <Container maxW="container.md" py={10} position="relative" zIndex={1}>
          <VStack spacing={8} align="stretch">
            <Box
              bg="white"
              p={12}
              borderRadius="xl"
              boxShadow="0 4px 20px rgba(51, 87, 134, 0.08)"
              border="1px solid"
              borderColor="rgba(51, 87, 134, 0.1)"
              position="relative"
              transition="all 0.3s ease"
              _hover={{
                boxShadow: "0 6px 30px rgba(51, 87, 134, 0.12)",
                transform: "translateY(-2px)"
              }}
            >
              <Box
                position="absolute"
                top="-20px"
                right="-20px"
                width="200px"
                height="200px"
                bg="rgba(234, 245, 242, 0.5)"
                borderRadius="full"
                zIndex={0}
              />

              <Center mb={6} position="relative">
                <Image 
                  src="/certificate_image.png"
                  alt="Life Essentials Club Certificate"
                  w="300px"
                  transition="transform 0.3s ease"
                  // _hover={{ transform: "scale(1.05)" }}
                  // borderRadius="xl"
                  // boxShadow="0 4px 20px rgba(51, 87, 134, 0.15)"
                />
              </Center>

              <VStack spacing={6} align="center" position="relative">
                <Badge
                  px={4}
                  py={2}
                  bg="rgba(51, 87, 134, 0.1)"
                  color="#335786"
                  borderRadius="full"
                  fontSize="sm"
                  fontWeight="500"
                  position="relative"
                  _before={{
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 'full',
                    background: `
                      linear-gradient(
                        90deg, 
                        rgba(51, 87, 134, 0.4) 0%,
                        rgba(57, 161, 105, 0.4) 100%
                      )
                    `,
                    filter: 'blur(8px)',
                    opacity: 0.7,
                    transform: 'scale(1.05)',
                    zIndex: -1,
                    animation: 'pulse 2s infinite'
                  }}
                >
                  Official Certificate
                </Badge>

                <Heading
                  as="h1"
                  size="xl"
                  textAlign="center"
                  color="#335786"
                  fontFamily="serif"
                  transition="color 0.3s ease"
                  _hover={{ color: "#39a169" }}
                >
                  Certificate of Membership
                </Heading>

                <Text fontSize="lg" fontStyle="italic" textAlign="center" color="#335786">
                  This certifies that
                </Text>

                <Heading 
                  as="h2" 
                  size="lg" 
                  color="#39a169"
                  transition="all 0.3s ease"
                  _hover={{ 
                    transform: "translateY(-2px)",
                    textShadow: "0 2px 4px rgba(57, 161, 105, 0.2)"
                  }}
                >
                  {client?.fName} {client?.lName}
                </Heading>

                <Text fontSize="lg" textAlign="center" maxW="600px" mb={6}>
                  has joined the Life Essentials Club as a member in good standing,
                  committing to our shared values of cooperation, sustainability, and
                  mutual support within our ecosystem.
                </Text>

                <Box mb={6} textAlign="left" w="100%">
                  <Heading as="h3" size="sm" mb={4}>
                    Key Membership Points:
                  </Heading>
                  <UnorderedList spacing={3}>
                    <ListItem>We support each other to ensure everyone has access to life essentials</ListItem>
                    <ListItem>Members participate in at least one Club activity monthly</ListItem>
                    <ListItem>We share skills, resources, and support within our community</ListItem>
                    <ListItem>We practice environmental stewardship and sustainable living</ListItem>
                    <ListItem>We resolve conflicts through respectful dialogue and mediation</ListItem>
                  </UnorderedList>
                </Box>

                <Text fontSize="sm" color="gray.600" mb={6}>
                  For the complete membership rules and details, please refer to our{" "}
                  <Link
                    href="/governing-rules"
                    color="blue.500"
                    fontWeight="bold"
                    target="_blank"
                    rel="noopener noreferrer"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    Governing Rules
                  </Link>
                </Text>

                <Divider my={4} borderColor="gray.300" />

                <Text fontWeight="bold">
                  Member Since: {agreementDate}
                </Text>

                {signatureUrl ? (
                  <Box 
                    mt={4} 
                    p={6} 
                    borderWidth="1px" 
                    borderColor="rgba(51, 87, 134, 0.1)"
                    borderRadius="xl"
                    boxShadow="0 4px 20px rgba(51, 87, 134, 0.08)"
                    bg="white"
                    width="100%"
                    transition="all 0.3s ease"
                    _hover={{
                      boxShadow: "0 6px 30px rgba(51, 87, 134, 0.12)",
                      transform: "translateY(-2px)"
                    }}
                  >
                    <Text fontSize="sm" mb={3} fontWeight="medium" color="#335786">
                      Member Signature:
                    </Text>
                    <Box 
                      p={4} 
                      bg="rgba(234, 245, 242, 0.5)"
                      borderWidth="1px" 
                      borderColor="rgba(51, 87, 134, 0.1)"
                      borderRadius="lg"
                    >
                      <Image 
                        src={signatureUrl}
                        alt="Member Signature"
                        maxH="100px"
                        mx="auto"
                      />
                    </Box>
                    <Box mt={3} fontSize="xs" color="gray.600">
                      <Text>
                        <Text as="span" fontWeight="medium" color="#335786">
                          Signature Hash:
                        </Text>{' '}
                        <Code fontSize="xs" bg="rgba(234, 245, 242, 0.5)">
                          {client?.encryptedSignatureIpfsUrl}
                        </Code>
                      </Text>
                      <Text mt={1}>
                        <Text as="span" fontWeight="medium" color="#335786">
                          Signed Date:
                        </Text>{' '}
                        {agreementDate}
                      </Text>
                    </Box>
                  </Box>
                ) : client?.encryptedSignatureIpfsUrl ? (
                  <Box 
                    mt={4} 
                    p={6} 
                    borderWidth="1px" 
                    borderColor="rgba(51, 87, 134, 0.1)"
                    borderRadius="xl"
                    boxShadow="0 4px 20px rgba(51, 87, 134, 0.08)"
                    bg="white"
                    width="100%"
                  >
                    <VStack spacing={4} align="center">
                      <Spinner
                        thickness="3px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="#39a169"
                        size="md"
                      />
                      <Badge
                        px={3}
                        py={1}
                        bg="rgba(51, 87, 134, 0.1)"
                        color="#335786"
                        borderRadius="full"
                        fontSize="sm"
                        fontWeight="500"
                        position="relative"
                        _before={{
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          borderRadius: 'full',
                          background: `
                            linear-gradient(
                              90deg, 
                              rgba(51, 87, 134, 0.4) 0%,
                              rgba(57, 161, 105, 0.4) 100%
                            )
                          `,
                          filter: 'blur(8px)',
                          opacity: 0.7,
                          transform: 'scale(1.05)',
                          zIndex: -1,
                          animation: 'pulse 2s infinite'
                        }}
                      >
                        Decrypting Signature
                      </Badge>
                      <Text fontSize="sm" color="gray.600" textAlign="center">
                        Please wait while we securely decrypt your signature...
                      </Text>
                    </VStack>
                  </Box>
                ) : null}

                <Text fontSize="sm" color="gray.600" mt={8} textAlign="center">
                  Certificate ID: {client?.id}
                </Text>

                <style>{`
                  @keyframes pulse {
                    0% { transform: scale(1.05); opacity: 0.7; }
                    50% { transform: scale(1.15); opacity: 0.4; }
                    100% { transform: scale(1.05); opacity: 0.7; }
                  }
                `}</style>
              </VStack>
            </Box>
          </VStack>
        </Container>
        <FooterWithFourColumns />
      </Box>
    </>
  );
};

export default SignedAgreement; 