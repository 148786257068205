import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import FullCalendar from '@fullcalendar/react';
import { EventInput, DateSelectArg, EventContentArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';

import { NavbarWithCallToAction } from '../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';

const GET_PRACTITIONER_AVAILABILITY = gql`
  query GetAvailabilityByPractitionerId($practitionerId: ID!, $startDate: String!, $endDate: String!) {
    getAvailabilityByPractitionerId(
      practitionerId: $practitionerId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      startTime
      endTime
      isAvailable
      status
    }
  }
`;

const CREATE_AVAILABILITY = gql`
  mutation CreateAvailability($input: AvailabilityInput!) {
    createAvailability(input: $input) {
      id
      startTime
      endTime
      status
    }
  }
`;

const DELETE_AVAILABILITY = gql`
  mutation DeleteAvailability($id: ID!) {
    deleteAvailability(id: $id)
  }
`;

export const AvailabilityPage = () => {
  const PRACTITIONER_ID = "6760ec476be9cc253e78285c";
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: isDeleteModalOpen, 
    onOpen: onDeleteModalOpen, 
    onClose: onDeleteModalClose 
  } = useDisclosure();
  const [selectedSlot, setSelectedSlot] = useState<{ start: Date; end: Date } | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<{ id: string; title: string } | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [createAvailability] = useMutation(CREATE_AVAILABILITY);
  const [deleteAvailability] = useMutation(DELETE_AVAILABILITY);

  const { data, loading, error, refetch } = useQuery(GET_PRACTITIONER_AVAILABILITY, {
    variables: {
      practitionerId: PRACTITIONER_ID,
      startDate: "2025-03-01T00:00:00.000Z",
      endDate: "2025-03-31T23:59:59.999Z"
    }
  });

  const handleDatesSet = (dateInfo: any) => {
    // Get the start of the week being displayed
    const weekStart = dateInfo.start;
    
    // Update URL with the week's start date
    setSearchParams({ week: weekStart.toISOString() });
    
    console.log('Calendar dates changed:', {
      weekStart: weekStart.toISOString(),
      weekEnd: dateInfo.end.toISOString(),
      displayRange: `${weekStart.toLocaleDateString()} - ${dateInfo.end.toLocaleDateString()}`
    });
  };

  // Parse the week parameter from URL or use current week
  const initialDate = React.useMemo(() => {
    const weekParam = searchParams.get('week');
    if (weekParam) {
      return new Date(weekParam);
    }

    // Get current date and set to start of week
    const today = new Date();
    const currentWeekStart = new Date(today);
    currentWeekStart.setHours(0, 0, 0, 0);
    currentWeekStart.setDate(today.getDate() - today.getDay()); // Set to Sunday

    // Update URL with current week
    setSearchParams({ week: currentWeekStart.toISOString() });

    console.log('Setting initial date to current week:', {
      today: today.toISOString(),
      weekStart: currentWeekStart.toISOString(),
      displayWeek: currentWeekStart.toLocaleDateString()
    });

    return currentWeekStart;
  }, [searchParams, setSearchParams]);

  const handleSelect = (selectInfo: DateSelectArg) => {
    setSelectedSlot({
      start: selectInfo.start,
      end: selectInfo.end
    });
    onOpen();
  };

  const handleSaveAvailability = async () => {
    if (!selectedSlot) return;

    try {
      await createAvailability({
        variables: {
          input: {
            practitioner: PRACTITIONER_ID,
            startTime: selectedSlot.start.toISOString(),
            endTime: selectedSlot.end.toISOString(),
            isAvailable: true,
            status: "DRAFT"
          }
        }
      });

      await refetch();
      onClose();
      
      toast({
        title: "Availability created",
        description: "The time slot has been added to your calendar",
        status: "success",
        duration: 3000
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to create availability",
        status: "error",
        duration: 5000
      });
    }
  };

  const handleEventClick = (clickInfo: any) => {
    // Only allow deletion of fetched availability events (not hardcoded ones)
    if (clickInfo.event.id && !['a', 'b'].includes(clickInfo.event.id)) {
      setSelectedEvent({
        id: clickInfo.event.id,
        title: clickInfo.event.title
      });
      onDeleteModalOpen();
    }
  };

  const handleDeleteAvailability = async () => {
    console.log('Deleting availability:', selectedEvent);
    if (!selectedEvent) return;

    try {
      const result = await deleteAvailability({
        variables: {
          id: selectedEvent.id
        }
      });

      if (result.data.deleteAvailability) {
        await refetch();
        onDeleteModalClose();
        
        toast({
          title: "Availability deleted",
          description: "The time slot has been removed from your calendar",
          status: "success",
          duration: 3000
        });
      } else {
        throw new Error("Failed to delete availability");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to delete availability",
        status: "error",
        duration: 5000
      });
    }
  };

  // Combine hardcoded event with fetched events
  const events: EventInput[] = React.useMemo(() => {

    const fetchedEvents = data?.getAvailabilityByPractitionerId?.map((slot: any) => ({
      id: slot.id,
      start: slot.startTime,
      end: slot.endTime,
      title: `Available (${slot.status})`,
      backgroundColor: '#FFA726',
      editable: false,
      display: 'block',
      allDay: false
    })) || [];


    const hardcodedEvents: EventInput[] = [
      {
        id: 'a',
        title: 'Available (DRAFT)',
        start: '2025-03-16T09:00:00', // 3 PM
        end: '2025-03-16T16:00:00',   // 4 PM
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50',
        textColor: '#ffffff',
        display: 'background'
      },
      {
        id: 'b', 
        title: 'Test Event 2',
        start: '2025-03-11T15:00:00', // 3 PM next day
        end: '2025-03-11T16:00:00',   // 4 PM next day
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50',
        textColor: '#ffffff'
      }
    ];

    console.log('Calendar events:', {
      hardcoded: hardcodedEvents,
      fetched: fetchedEvents,
      total: [...fetchedEvents, hardcodedEvents]
    });


    return [...fetchedEvents, ...hardcodedEvents];
  }, [data]);

  console.log('Calendar configuration:', {
    // events,
    plugins: [timeGridPlugin, interactionPlugin, dayGridPlugin],
    initialView: 'timeGridWeek',
    initialDate: initialDate?.toISOString()
  });

  const handleDateClick = (arg: any) => {
    alert('Date clicked: ' + arg.dateStr);
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={4} align="stretch">
          <Box bg="blue.50" p={4} borderRadius="md">
            <Text fontWeight="bold">
              Viewing Practitioner ID: {PRACTITIONER_ID}
            </Text>
          </Box>

          <Heading size="lg">
            Manage Your Availability {loading ? '(Loading...)' : ''}
          </Heading>
        
          <Box p={4} borderWidth="1px" borderRadius="lg" height="700px">
            <FullCalendar
              plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
              initialView="timeGridWeek"
              initialDate={initialDate}
              datesSet={handleDatesSet}
              events={events}
            
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }}
              slotMinTime="06:00:00"
              slotMaxTime="22:00:00"
              allDaySlot={false}
              height="100%"
              nowIndicator={true}
              eventContent={renderEventContent}
              dateClick={handleDateClick}
              selectable={true}
              select={handleSelect}
              eventClick={handleEventClick}
            />
          </Box>
        </VStack>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Availability</ModalHeader>
          <ModalBody>
            <Text>
              Create availability for:
              <br />
              {selectedSlot && (
                <>
                  From: {selectedSlot.start.toLocaleString()}
                  <br />
                  To: {selectedSlot.end.toLocaleString()}
                </>
              )}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveAvailability}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Availability</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to delete this availability slot?
              <br />
              {selectedEvent && (
                <>
                  {selectedEvent.title}
                </>
              )}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteAvailability}>
              Delete
            </Button>
            <Button variant="ghost" onClick={onDeleteModalClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <FooterWithFourColumns />
    </>
  );
}; 